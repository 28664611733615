import * as Sentry from '@sentry/react';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import App from 'pages/App';
import { AppProviders } from 'providers/app-providers';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';

dayjs.extend(relativeTime);

polyfillCountryFlagEmojis();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  beforeSend(event) {
    if (
      (event.exception?.values?.[0]?.type === 'Error' && event.tags?.['type'] !== 'UnHandledError') ||
      event.exception?.values?.[0]?.type === 'TypeError' ||
      event.exception?.values?.[0]?.type === 'AxiosError'
    ) {
      return null;
    }

    return event;
  },
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/admin\.nurilounge\.com\/api/],
  enabled: process.env.NODE_ENV === 'production',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  integrations: [Sentry.replayIntegration()],
  defaultIntegrations: false,
});

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AppProviders>
    <App />
  </AppProviders>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
