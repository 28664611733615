import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getUserApi } from 'api/account/User';

import { addCommaToNumber } from 'utils/common/addCommaToNumber';
import { formatUserFollowerCount } from 'utils/common/formatUserFollowerCount';

import Icon from 'components/Icons/Icon';
import MenuIcon from 'components/Icons/MenuIcon';
import { Spinner } from 'components/common/loading/LoadingSpinner';
import StyledText from 'components/common/text/StyledText';

import { selectAccount } from 'features/redux/selectors/accounts';

import { color } from 'styles/assets';

interface Props {
  type: 'jelly' | 'credit';
}

const PointLabel = ({ type }: Props) => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const { user, access_token } = account.userInfo;
  const { point, credit } = user;

  const count = {
    jelly: point,
    credit: credit,
  };

  const [loading, setLoading] = useState(false);

  const refreshUserInfo = async () => {
    await setLoading(true);
    await getUserApi(access_token, dispatch);
    await setLoading(false);
  };

  return (
    <LabelContainer>
      {loading && (
        <LoadingWrapper data-testid="loading-spinner">
          <Spinner side="15px" />
        </LoadingWrapper>
      )}
      <CurrentJellyWrapper>
        <Icon type={type === 'jelly' ? 'jelly_colored' : 'credit'} />
        <MenuIcon type="multiply" />
        <StyledText
          fontSize="sm"
          fontWeight="extraBold"
          text={count[type] > 9999 ? formatUserFollowerCount(count[type]) : addCommaToNumber(count[type])}
        />
      </CurrentJellyWrapper>
      <RefreshButton onClick={refreshUserInfo}>
        <Icon type="reset" width={17} height={17} />
      </RefreshButton>
    </LabelContainer>
  );
};

export default PointLabel;

const LabelContainer = styled.div`
  width: 157px;
  height: 38px;
  border-radius: 52px;
  border: 1px solid ${color.violet39};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const CurrentJellyWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RefreshButton = styled.button`
  width: 26px;
  aspect-ratio: 1;
  margin-right: 8px;
  border-radius: 100%;
  background-color: ${color.violetf4};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${color.black};
  opacity: 0.5;
  z-index: 1;
`;
