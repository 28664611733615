import { Fragment } from 'react';

import { Grid } from '@mui/material';

import Block from 'components/layout/common/Block';
import { Column } from 'components/layout/common/InfoCard';
import BulkMessageCampaignInfo from 'components/pages/chat/bulkMessage/info/BulkMessageCampaignInfo';
import BulkMessageInfo from 'components/pages/chat/bulkMessage/info/BulkMessageInfo';

interface Props {
  bulkMessage: any;
  editable?: boolean;
}

const BulkMessageDetailNotSentCard = ({ bulkMessage, editable }: Props) => {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Block>
          <Column gap="30px">
            <BulkMessageCampaignInfo bulkMessage={bulkMessage} />
          </Column>
        </Block>
      </Grid>
      <Grid item xs={12}>
        <Block>
          <Column gap="30px">
            <BulkMessageInfo bulkMessage={bulkMessage} editable={editable} />
          </Column>
        </Block>
      </Grid>
    </Fragment>
  );
};

export default BulkMessageDetailNotSentCard;
