import styled from 'styled-components';

import { userType } from 'utils/account/accessLevel';
import { canOnlyApplyVerifiedUser } from 'utils/campaign/access';
import { getAuthorName } from 'utils/campaign/getAuthorName';
import { getCampaignStatus } from 'utils/common/getCampaignStatus';

import Avatar from 'components/common/Avatar';
import PaidBadge from 'components/common/badge/PaidBadge';
import PointBadge from 'components/common/badge/PointBadge';
import UGCBadge from 'components/common/badge/UGCBadge';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import VerifiedOnlyBadge from 'components/pages/campaign/VerifiedOnlyBadge';

import { Campaign } from 'types/campaign/internal';

interface Props {
  detailData: Campaign;
  campaign?: 'quest' | 'benefit';
}

const SummarySection = ({ detailData, campaign }: Props) => {
  const { title, user } = detailData;
  const authorName = getAuthorName(user);
  const verified = canOnlyApplyVerifiedUser(detailData.access_user_groups.split(','));
  const isClosed = getCampaignStatus(detailData) !== 'Active';

  return (
    <CampaignSummarySection>
      <ProfileContainer>
        <InfoWrapper>
          <Avatar avatarSize="s" src={user.profile} badgeType={userType(user as any)} />
          <StyledText text={authorName} fontSize="sm" fontWeight="bold" />
        </InfoWrapper>
        {verified && <VerifiedOnlyBadge isClosed={isClosed} />}
      </ProfileContainer>
      <Gap side={10} />
      <StyledText text={title} fontWeight="regular" />
      <Gap side={20} />
      <CampaignPoint detailData={detailData} campaign={campaign} />
    </CampaignSummarySection>
  );
};

const CampaignPoint = ({ detailData, campaign }: Props) => {
  const tax = campaign === 'quest' ? 0 : Math.ceil(detailData.point * 0.1);
  const { is_paid, is_ugc } = detailData;
  const isCampaign = campaign === 'quest';
  const badgeText = isCampaign ? `+${detailData.point}` : `-${detailData.point + tax}`;
  const currentStyle = getCampaignStatus(detailData) === 'Closed' ? 'disabled' : 'primary';

  return (
    <PointWrapper closed={getCampaignStatus(detailData) === 'Closed'}>
      <PointBadge badgeText={badgeText} styleType={currentStyle} />
      {is_paid && <PaidBadge styleType={currentStyle} />}
      {is_ugc && <UGCBadge styleType={currentStyle} />}
    </PointWrapper>
  );
};

export default SummarySection;

const CampaignSummarySection = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 16px;
  padding-right: 16px;
`;

const ProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const PointWrapper = styled.div<{ closed: boolean }>`
  display: flex;
  position: relative;
  column-gap: 6px;
  justify-content: flex-end;
`;
