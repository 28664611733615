import { useOverlay } from '@nurihaus/use-overlay';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Channel } from 'talkplus-sdk';

import { useChat } from 'hooks/chat/queries';

import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';
import { Column, Row } from 'components/layout/common/InfoCard';
import EditGroupChannelModal from 'components/pages/chat/groupChat/talkplus/create/EditModal';
import PinButtons from 'components/pages/chat/groupChat/talkplus/pinned/PinBtns';
import PinMsgViewerModal from 'components/pages/chat/groupChat/talkplus/pinned/PinMsgViewerModal';

import { selectAccount } from 'features/redux/selectors/accounts';

import { color } from 'styles/assets';

interface Props {
  channelId: string;
  pinnedMsg: string;
}

const PinnedArea = ({ channelId, pinnedMsg }: Props) => {
  const { useGetChannel, useGetNotice } = useChat();
  const { data } = useGetChannel({ channelId });
  const { data: notice } = useGetNotice({ channelId });
  const channel = data?.channel;
  const account = useSelector(selectAccount);
  const editChannelOverlay = useOverlay();
  const notiViewerOverlay = useOverlay();

  const IS_OP = channel?.ownerId === account.userInfo.user.id.toString();

  const handleEditChannel = () => {
    editChannelOverlay.open(({ close }) => <EditGroupChannelModal closeModal={close} channel={channel as Channel} />);
  };

  const handleNotiViewer = () => {
    notiViewerOverlay.open(({ close }) => <PinMsgViewerModal channelId={channelId} closeModal={close} />);
  };

  return (
    <Container>
      <Column gap="20px">
        <Row gap="20px">
          <StyledText fontSize="lg" fontWeight="bold" text={channel?.name} />
          {IS_OP && (
            <button onClick={handleEditChannel}>
              <StyledText
                fontSize="sm"
                fontWeight="semiBold"
                text="Edit"
                textDecoration="underline"
                fontColor="grey71"
              />
            </button>
          )}
        </Row>
        <PinButtons channel={channel as Channel} pinnedMsg={pinnedMsg} />
        {notice?.text && (
          <PinnedMsgWrapper>
            <Circle>
              <Icon type="tag" width={20} height={20} fill="white" />
            </Circle>
            <TextWrapper data-testid="pin-msg" onClick={handleNotiViewer}>
              <StyledText fontSize="sm" fontWeight="medium" text={notice?.text} numberOfLines={2} />
            </TextWrapper>
          </PinnedMsgWrapper>
        )}
      </Column>
    </Container>
  );
};

export default PinnedArea;

const Container = styled.div`
  width: 100%;
  padding: 20px 14px;
  position: relative;
  z-index: 1;
`;

const PinnedMsgWrapper = styled.div`
  width: calc(100% - 28px);
  padding: 19px 10px;
  border: 1px solid ${color.greyd4};
  background-color: ${color.greyf4};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 100%;
`;

const Circle = styled.div`
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.violet39};
  margin-right: 10px;
`;

const TextWrapper = styled.button`
  width: calc(100% - 62px);
  margin-right: 13px;
`;
