import { useParams } from 'react-router-dom';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { useGetBulkMessage } from 'hooks/chat/bulkMessage/useBulkMessage';

import ChatBulkMessageEditContainer from 'containers/chat/bulkMessage/EditContainer';

import PageLoading from 'components/common/loading/PageLoading';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import BulkMessageDetailHeaderEditButton from 'components/pages/chat/bulkMessage/headerButtons/BulkMessageDetailHeaderEditButtons';

import { MessagingStatus } from 'types/bulkMessge';

const ChatBulkMessageEditPage = () => {
  const { id } = useParams();

  const { data, isLoading, isError, error } = useGetBulkMessage(Number(id));

  if (isLoading) return <PageLoading />;

  if (isError || !data) return <div>{errToErrMsg(error?.response)}</div>;

  const { status } = data;
  const isEditable = status === MessagingStatus.SCHEDULED;
  if (!isEditable) return <div>This message is not editable</div>;

  return (
    <DashboardLayout headerRight={<BulkMessageDetailHeaderEditButton bulkMessage={data} />}>
      <ChatBulkMessageEditContainer data={data} />
    </DashboardLayout>
  );
};

export default ChatBulkMessageEditPage;
