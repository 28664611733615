import { useState } from 'react';

import { AxiosError } from 'axios';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import {
  bulkAccept,
  completeCampaign,
  getCampaignSummaryApi,
  getCarriers,
  getCompleteStateListCSV,
  getCreateCategoryList,
  getEarnDetailApi,
  getEarnState,
  getSpendDetailApi,
  getSpendState,
  getStateList,
  getStateListCSV,
  postBulkShippingInfo,
  postShippingInfo,
} from 'api/lounge';

import { selectAccount } from 'features/redux/selectors/accounts';
import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

import { ApplyHistory, Campaign, CampaignSummary } from 'types/campaign/internal';
import {
  GetCompleteStateListCSVPayload,
  GetStateListCSVPayload,
  GetStateListPayload,
  PostBulkShippingInfoPayload,
  PostBulkShippingInfoResponse,
  PostShippingInfoPayload,
  PostShippingInfoResponse,
} from 'types/campaign/remote';

export const useCampaignDetailData = ({
  campaign,
  detailId,
}: {
  campaign?: 'benefit' | 'quest' | string;
  detailId: number;
}) => {
  const queryClient: any = useQueryClient();
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const getDataFn = () => {
    if (campaign === 'quest') {
      return getEarnDetailApi(access_token, detailId);
    } else {
      return getSpendDetailApi(access_token, detailId);
    }
  };

  return useQuery<any, AxiosError>(['detailData', { campaign, detailId }], getDataFn, {
    initialData: () => {
      const detailData: Campaign = queryClient
        ?.getQueryData(['campaignList', { campaign }])
        ?.results?.find((data: Campaign) => data?.id === detailId);
      if (detailData) return detailData;
      else return undefined;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
  });
};

export const useCampaignSummaryData = (campaign: 'quest' | 'benefit', id: number) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const getDataFn = () => {
    return getCampaignSummaryApi(access_token, { campaign, id });
  };

  return useQuery<CampaignSummary, AxiosError>(['summaryData', { campaign, id }], getDataFn, {
    keepPreviousData: false,
  });
};

export const useCampaignComplete = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (e: AxiosError) => void;
}) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  return useMutation(
    ({ model, id }: { model: 'event' | 'quest' | 'market'; id: number }) => completeCampaign(model, id, access_token),
    {
      onSuccess,
      onError,
    }
  );
};

export const useHistoryData = ({
  campaign,
  id,
  page,
  pageSize,
  options,
}: {
  campaign: 'benefit' | 'quest';
  id: number;
  page?: number;
  pageSize?: number;
  options?: GetStateListPayload['options'];
}) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const convertCampaign = campaign === 'benefit' ? 'event' : 'quest';

  const getDataFn = () =>
    getStateList({
      access_token,
      type: convertCampaign,
      id,
      page,
      page_size: pageSize,
      options,
    });

  return useQuery(
    [
      'apply-history',
      {
        campaign,
        id,
        page,
        pageSize,
        options,
      },
    ],
    getDataFn,
    {
      keepPreviousData: false,
      cacheTime: 0,
    }
  );
};

// export const useAllHistoryData = ({
//   campaign,
//   id,
//   options,
//   onFinished,
//   setLoadingProgress,
// }: {
//   campaign: 'benefit' | 'quest';
//   id: number;
//   options?: GetStateListPayload['options'];
//   onFinished?: () => void;
//   setLoadingProgress?: ({ current, total }: { current: number; total: number }) => void;
// }) => {
//   const account = useSelector(selectAccount);
//   const access_token = account.userInfo.access_token;
//   const convertCampaign = campaign === 'benefit' ? 'event' : 'quest';

//   const getDataFn = ({ pageParam = 1 }) =>
//     getStateList({
//       access_token,
//       type: convertCampaign,
//       id,
//       page: pageParam,
//       page_size: 20,
//       options,
//     });

//   const { data, fetchNextPage, isLoading, isFetched, isFetching } = useInfiniteQuery(
//     [
//       'stateHistoryData',
//       {
//         campaign,
//         id,
//       },
//     ],
//     getDataFn,
//     {
//       getNextPageParam: (lastPage, pages) => {
//         if (lastPage?.next) return pages?.length + 1;
//         else return false;
//       },
//       onSuccess: (data) => {
//         if (data.pages[data.pages.length - 1].next) {
//           fetchNextPage();
//           if (setLoadingProgress) {
//             setLoadingProgress({
//               current: data.pages.flatMap((page) => page.results).length,
//               total: data.pages?.[0]?.count || 0,
//             });
//           }
//         } else {
//           onFinished?.();
//         }
//       },
//       keepPreviousData: false,
//       cacheTime: 0,
//     }
//   );

//   return { data, isLoading, isFetched, isFetching };
// };

export const useInfiniteHistoryData = ({
  campaign,
  id,
  pageSize = 20,
  options,
  enabled = true,
}: {
  campaign: 'benefit' | 'quest';
  id: number;
  page?: number;
  pageSize?: number;
  options?: GetStateListPayload['options'];
  enabled?: boolean;
}) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const convertCampaign = campaign === 'benefit' ? 'event' : 'quest';

  const getDataFn = async ({ pageParam = 1 }): Promise<any> => {
    const res = await getStateList({
      access_token,
      type: convertCampaign,
      id,
      page: pageParam,
      page_size: pageSize,
      options,
    });

    return res;
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } = useInfiniteQuery<
    { count: number; next: string | null; prev: string | null; results: ApplyHistory[] },
    AxiosError
  >(['stateHistoryData', { campaign, id, pageSize, options }], getDataFn, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.next) return pages?.length + 1;
    },
    keepPreviousData: false,
    cacheTime: 0,
    enabled: enabled,
  });

  return { data, isLoading, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess };
};

export const useGetCampaignHistoryDetail = ({
  campaign,
  stateId,
  contentId,
  enabled,
}: {
  campaign: 'quest' | 'benefit';
  stateId: number;
  contentId: number;
  enabled?: boolean;
}) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const getDataFn = () => {
    if (campaign === 'quest') {
      return getEarnState(access_token, contentId, stateId);
    } else {
      return getSpendState(access_token, contentId, stateId);
    }
  };

  return useQuery(['quest-history-detail', stateId, contentId], getDataFn, {
    retry: true,
    enabled,
  });
};

export const useGetCategoryData = () => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  return useQuery<{ count: number; results: { id: number; name: string; slug: string }[] }, AxiosError>(
    ['create-category'],
    () => getCreateCategoryList(access_token),
    { staleTime: 30 * 60 * 1000, cacheTime: 30 * 60 * 1000, keepPreviousData: true }
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useBulkAccept = (options: { onSuccess?: (data: any) => void; onError?: (error: any) => void }) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);

  const mutation = useMutation(
    async (data: { ids: number[] }) => {
      setLoadingMessage('In progress...');
      const response = await bulkAccept(access_token, data);

      return response;
    },
    {
      onSuccess: (data) => {
        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      },
      onError: (error) => {
        if (options?.onError) {
          options.onError(error);
        }
      },
      onSettled: () => {
        setTimeout(() => setLoadingMessage(undefined), 2000);
      },
    }
  );

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    loadingMessage,
  };
};

export const useGetCarriers = () => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  return useQuery(['carriers'], () => getCarriers(access_token));
};

export const usePostShippingInfo = () => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation<PostShippingInfoResponse, AxiosError, PostShippingInfoPayload, void>({
    mutationFn: (shippingInfo: PostShippingInfoPayload) => postShippingInfo(access_token, shippingInfo),
    onSuccess() {
      queryClient.invalidateQueries(['quest-history-detail']);
      dispatch(
        SetSnackbar({
          snackbarOpen: true,
          snackbarMessage: 'Successfully saved!',
          severity: 'success',
        })
      );
    },
  });
};

export const usePostBulkShippingInfo = () => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  return useMutation<PostBulkShippingInfoResponse, AxiosError, PostBulkShippingInfoPayload[], unknown>(
    (bulkShippingInfo: PostBulkShippingInfoPayload[]) => postBulkShippingInfo(access_token, bulkShippingInfo)
  );
};

export const useGetStateListCSV = (stateListCSVParams: GetStateListCSVPayload, onComplete?: () => void) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const [enabledQuery, setEnableQuery] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const { isLoading, isSuccess } = useQuery({
    queryKey: ['stateListCSV', stateListCSVParams],
    queryFn: () => getStateListCSV(access_token, stateListCSVParams),
    enabled: enabledQuery,
    onSuccess(data) {
      setIsDownloading(true);
      const BOM = '\uFEFF';
      const csvContent = BOM + data;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';

      xhr.onload = function () {
        const downloadUrl = window.URL.createObjectURL(xhr.response);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${stateListCSVParams.content_type_id === 74 ? 'quest' : 'benefit'}_${
          stateListCSVParams.object_id
        }_user`;

        a.onclick = () => {
          setTimeout(() => {
            window.URL.revokeObjectURL(downloadUrl);
            window.URL.revokeObjectURL(url);
            setIsDownloading(false);
            onComplete?.();
          }, 100);
        };

        a.click();
      };

      xhr.send();
    },
    onSettled() {
      setEnableQuery(false);
    },
  });

  const download = async () => {
    setEnableQuery(true);
  };

  return {
    isLoading: isLoading || isDownloading,
    download,
    isSuccess,
  };
};

export const useGetCompleteStateListCSV = (stateListCSVParams: GetCompleteStateListCSVPayload) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const [enabledQuery, setEnableQuery] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const { isLoading } = useQuery({
    queryKey: ['completeStateListCSV', stateListCSVParams],
    queryFn: () => getCompleteStateListCSV(access_token, stateListCSVParams),
    enabled: enabledQuery,
    onSuccess(data) {
      setIsDownloading(true);
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;

      a.download = `${stateListCSVParams.content_type_id === 74 ? 'quest' : 'benefit'}_${
        stateListCSVParams.object_id
      }_complete`;
      a.click();
      window.URL.revokeObjectURL(url);

      setTimeout(() => {
        setIsDownloading(false); // 다운로드 완료 후 로딩 상태 해제
      }, 500);
    },
    onSettled() {
      setEnableQuery(false);
    },
  });

  const download = () => {
    setEnableQuery(true);
  };

  return {
    isLoading: isLoading || isDownloading,
    download,
  };
};
