import axios, { AxiosResponse } from 'axios';

import { instance } from 'api/core';

interface IRequestOptions {
  method?: 'get' | 'post' | 'patch' | 'delete' | 'put';
  url?: string;
  data?: any;
  'Content-Type'?: 'application/json' | 'multipart/form-data' | 'application/x-www-form-urlencoded' | 'text/csv';
}

export const requestWithAuth = async <T = any>({ ...options }: IRequestOptions, token: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;

  try {
    const response: AxiosResponse<T> = await instance(options);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    }

    throw error;
  }
};

export const requestWithoutAuth = async <T = any>({ ...options }: IRequestOptions) => {
  delete instance.defaults.headers.common.Authorization;

  try {
    const response: AxiosResponse<T> = await instance(options);

    return response.data as T;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    }

    throw error;
  }
};
