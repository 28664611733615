import { useLocation, useNavigate } from 'react-router-dom';

export const useRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const createQueryString = (query: { [key: string]: string }, prevQueryString?: string) => {
    const queryString = Object.entries(query)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    return prevQueryString ? `${prevQueryString}&${queryString}` : `?${queryString}`;
  };

  const getModifiedQueryString = (prevQueryString: string, newQuery: { [key: string]: string | null }) => {
    const query = prevQueryString
      .slice(1)
      .split('&')
      .reduce((acc, cur) => {
        const [key, value] = cur.split('=');

        if (key in newQuery) {
          if (typeof newQuery[key] === 'string') {
            return { ...acc, [key]: newQuery[key] };
          } else {
            return { ...acc };
          }
        }

        return { ...acc, [key]: value };
      }, Object.create(null));

    return createQueryString(query);
  };

  return {
    push(path: string, query?: { [key: string]: string }) {
      const queryString = query ? createQueryString(query) : '';

      navigate(`${path}${queryString ? `${queryString}` : ''}`);
    },

    replace(path: string, query?: { [key: string]: string }) {
      const queryString = query ? createQueryString(query) : '';

      navigate(`${path}${queryString ? `?${queryString}` : ''}`, {
        replace: true,
      });
    },

    updateQS(newQuery: { [key: string]: string }) {
      const currentPath = location.pathname;
      const currentQueryString = location.search;
      const queryString = createQueryString(newQuery, currentQueryString);

      navigate(`${currentPath}${queryString}`);
    },

    replaceQS(newQuery: { [key: string]: string }) {
      const currentPath = location.pathname;
      const currentQueryString = location.search;
      const queryString = getModifiedQueryString(currentQueryString, newQuery);

      navigate(`${currentPath}${queryString}`, { replace: true });
    },

    removeQS(queryKeys: string[]) {
      const newQuery = queryKeys.reduce((acc, cur) => {
        return { ...acc, [cur]: null };
      }, Object.create(null));
      const currentPath = location.pathname;
      const currentQueryString = location.search;
      const queryString = getModifiedQueryString(currentQueryString, newQuery);

      navigate(`${currentPath}${queryString}`);
    },
  };
};
