import { ChannelCategoryEnum } from 'constants/chatConstants';
import styled from 'styled-components';

import { chatCategoryConverter } from 'utils/chat/chatCategoryConverter';

import { useChat } from 'hooks/chat/queries';

import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

import { color } from 'styles/assets';

interface Props {
  channelId: string;
}

const AcceptMsg = ({ channelId }: Props) => {
  const { useGetChannel } = useChat();

  const { data } = useGetChannel({ channelId });

  return (
    <Container>
      <Upper>
        <Icon type="jelly_colored" />
        <Gap side={5} />
        <StyledText
          fontSize="base"
          fontWeight="bold"
          text="The Campaign has Started!"
          fontColor="white"
          textAlign="center"
        />
        <Gap side={8} />
        <Icon type="jelly_colored" />
      </Upper>
      <Lower>
        <StyledText
          fontSize="base"
          fontWeight="medium"
          text={`Let’s be polite, friendly, and respectful 🫶🏻`}
          textAlign="center"
        />
        <Gap side={10} />
        <StyledText
          fontSize="base"
          fontWeight="medium"
          text={`*If you cannot continue this ${chatCategoryConverter(
            data?.channel.category === ChannelCategoryEnum.QUEST ? 'earn' : 'spend'
          )} for any reason, please contact Nurilounge for help.`}
          textAlign="center"
        />
      </Lower>
    </Container>
  );
};

export default AcceptMsg;

const Container = styled.div`
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${color.greye4};
  margin-bottom: 20px;
`;

const Upper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: ${color.violet39};
`;

const Lower = styled.div`
  padding: 20px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
