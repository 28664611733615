import { AxiosError, AxiosRequestConfig, Method } from 'axios';

export const convertError = (
  error: Error,
  componentStack: string
): ApiError | NetworkError | TimeoutError | RuntimeError => {
  if (error instanceof AxiosError) {
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      return new TimeoutError(error, componentStack);
    }

    return new ApiError(error, componentStack);
  }

  if (!window.navigator.onLine) {
    return new NetworkError(error);
  }

  return new RuntimeError(error, componentStack);
};

export class ApiError extends Error {
  private apiError: AxiosError;
  private componentStack: string;

  constructor(error: AxiosError, componentStack: string) {
    super(error.message);
    this.name = 'ApiError';
    this.cause = error.response?.status.toString();
    this.apiError = error;
    this.componentStack = componentStack;
  }

  getErrorName(): 'ApiError' {
    return 'ApiError';
  }

  getStatusCode(): number | undefined {
    return this.apiError.response?.status;
  }

  getResponseData(): unknown {
    return this.apiError.response?.data;
  }

  getRequestData(): AxiosRequestConfig {
    return this.apiError.config;
  }

  getComponentStack(): string {
    return this.componentStack;
  }

  getMethod(): Method | undefined {
    return this.apiError.config.method as Method;
  }
}

interface NetworkInformation {
  effectiveType: string;
  type: string;
  downlinkMax: number;
  downlink: number;
  rtt: number;
  saveData: boolean;
}

declare global {
  interface Navigator {
    connection?: NetworkInformation;
  }
}

export class NetworkError extends Error {
  constructor(error: Error) {
    super(error.message, { cause: error });
    this.name = 'NetworkError';
  }

  getNetworkType(): string | null {
    return navigator.connection?.effectiveType || null;
  }
}

export class TimeoutError extends Error {
  private componentStack: string;
  private retryCount: number;

  constructor(error: AxiosError, componentStack: string) {
    super(error.message, { cause: error });
    this.name = 'TimeoutError';
    this.componentStack = componentStack;
    this.retryCount = 0;
  }

  getErrorName(): 'TimeoutError' {
    return 'TimeoutError';
  }

  getComponentStack(): string {
    return this.componentStack;
  }

  getTimeoutDuration(): number | undefined {
    return (this.cause as AxiosError)?.config?.timeout;
  }

  isRetryable(): boolean {
    const maxRetries = 3;
    const isOnline = window.navigator.onLine;

    return this.retryCount < maxRetries && isOnline;
  }

  getTimeoutEndPoint(): string | undefined {
    return (this.cause as AxiosError)?.config?.url;
  }

  incrementRetryCount(): void {
    this.retryCount++;
  }
}

export class RuntimeError extends Error {
  private runtimeError: Error;
  private errorType: string;
  private stackTrace: string | undefined;
  private componentStack: string;
  constructor(error: Error, componentStack: string) {
    const message = error instanceof Error ? error.message : error;
    super(message);

    this.name = 'RuntimeError';
    this.runtimeError = error instanceof Error ? error : new Error(error);
    this.errorType = this.getErrorType();
    this.stackTrace = this.runtimeError.stack;
    this.componentStack = componentStack;
  }

  getErrorType(): 'TypeError' | 'ReferenceError' | 'SyntaxError' | 'RangeError' | 'Unknown Runtime Error' {
    if (this.runtimeError instanceof TypeError) return 'TypeError';

    if (this.runtimeError instanceof ReferenceError) return 'ReferenceError';

    if (this.runtimeError instanceof SyntaxError) return 'SyntaxError';

    if (this.runtimeError instanceof RangeError) return 'RangeError';

    return 'Unknown Runtime Error';
  }

  getErrorName(): 'RuntimeError' {
    return 'RuntimeError';
  }

  getComponentStack(): string {
    return this.componentStack;
  }

  getErrorDetails(): {
    type: string;
    message: string;
    stack?: string;
    cause?: unknown;
  } {
    return {
      type: this.errorType,
      message: this.message,
      stack: this.stackTrace,
      cause: this.runtimeError.cause,
    };
  }

  isTypeError(): boolean {
    return this.errorType === 'TypeError';
  }

  isReferenceError(): boolean {
    return this.errorType === 'ReferenceError';
  }

  getStack(): string | undefined {
    return this.stackTrace;
  }

  isChunkError(): boolean {
    return this.message.includes('Loading chunk');
  }
}
