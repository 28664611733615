import { Fragment } from 'react';

import styled from 'styled-components';

import { formatDateAndTimeWithAMPM } from 'utils/common/dateFormat';

import { bulkMessageTargetOptions } from 'hooks/chat/bulkMessage/useBulkMessageModal';

import Table from 'components/common/table/Table';
import StyledText from 'components/common/text/StyledText';

import { Messaging, MessagingUserStatus } from 'types/bulkMessge';

interface Props {
  rowData: any;
  bulkMessage: Messaging;
}

const BulkMessageDetailTableRow = ({ rowData, bulkMessage }: Props) => {
  const { nickname, username, email, index } = rowData;
  const { content_object, id: MessagingId, user_status, schedule } = bulkMessage;
  const { title } = content_object;
  const isFail = !rowData?.is_success;
  const haveRetry = false;

  const options = bulkMessageTargetOptions();
  const option = options.find((option) => option.value === MessagingUserStatus[user_status]);

  const handleClick = () => {};

  return (
    <Fragment>
      <Table.Cell flex={1} minWidth="70px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="bold" text={index.toString()} numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={2} minWidth="170px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={username} numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={2} minWidth="170px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={nickname} numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={2} minWidth="250px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={email} numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={2} minWidth="120px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={MessagingId.toString()} />
      </Table.Cell>
      <Table.Cell flex={4} minWidth="440px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={title} numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={3} minWidth="230px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={option?.label} numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={3} minWidth="270px" handleClick={handleClick}>
        {isFail ? (
          <SendStatus>
            <StyledText fontSize="base" fontWeight="medium" text={'Fail'} fontColor="reddf" />
            {haveRetry && (
              <StyledText
                fontSize="base"
                fontWeight="medium"
                text={`(Retry at ${formatDateAndTimeWithAMPM(new Date().toISOString())})`}
              />
            )}
          </SendStatus>
        ) : (
          <StyledText fontSize="base" fontWeight="medium" text={'Success'} fontColor="green" />
        )}
      </Table.Cell>
      <Table.Cell flex={2} minWidth="170px" handleClick={handleClick}>
        <StyledText fontSize="base" fontWeight="medium" text={formatDateAndTimeWithAMPM(schedule)} numberOfLines={1} />
      </Table.Cell>
    </Fragment>
  );
};

export default BulkMessageDetailTableRow;

const SendStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
