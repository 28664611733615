import { requestWithAuth } from 'utils/common/axios-utils';

export const updateChatId = async (access_token: string, data: { status: number }, connectChatAndArticleId: number) => {
  return await requestWithAuth<{ status: number }>(
    {
      method: 'patch',
      url: `/lounge/chat/${connectChatAndArticleId}/`,
      data,
    },
    access_token
  );
};

export const acceptApply = async (
  access_token: string,
  articleId: number,
  stateId: number,
  category: 'jelly' | 'spend' | 'earn'
) => {
  return await requestWithAuth(
    {
      method: 'patch',
      url: `/lounge/${category === 'spend' ? 'spend' : category === 'earn' ? 'point' : 'exchange'}/join/${articleId}/`,
      data: {
        pk: stateId,
        action: 'accept',
      },
    },
    access_token
  );
};

export const dropApply = async (
  access_token: string,
  articleId: number,
  stateId: number,
  category: 'jelly' | 'spend' | 'earn'
) => {
  return await requestWithAuth(
    {
      method: 'patch',
      url: `/lounge/${category === 'spend' ? 'spend' : category === 'earn' ? 'point' : 'exchange'}/join/${articleId}/`,
      data: {
        pk: stateId,
        action: 'drop',
      },
    },
    access_token
  );
};

export const refundDueToDeclining = async (
  access_token: string,
  articleID: number,
  stateID: number,
  category: 'jelly' | 'spend' | 'earn'
) => {
  return await requestWithAuth(
    {
      method: 'patch',
      url: `/lounge/${category === 'spend' ? 'spend' : category === 'earn' ? 'point' : 'exchange'}/join/${articleID}/`,
      data: {
        pk: stateID,
        action: 'decline',
      },
    },
    access_token
  );
};

export const completeExchangeApi = async (
  access_token: string,
  id: number,
  stateId: number,
  dispatch: any,
  category: 'jelly' | 'spend' | 'earn'
) => {
  return await requestWithAuth(
    {
      method: 'patch',
      url: `/lounge/${category === 'spend' ? 'spend' : category === 'earn' ? 'point' : 'exchange'}/join/${id}/`,
      data: {
        pk: stateId,
        action: 'complete',
      },
    },
    access_token
  );
};

export const chatListId = async (
  access_token: string,
  page: number,
  model: 'jelly' | 'benefit' | 'quest' | undefined,
  id: number,
  page_size: number
) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/lounge/chat/for/${
        model === 'benefit' ? 'event' : model === 'quest' ? 'quest' : 'market'
      }/${id}/?page_size=${page_size || 8}&page=${page || 1}`,
    },
    access_token
  );
};
