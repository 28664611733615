import { FontSizeTypes, FontWeightTypes } from 'styles/assets';

interface IButtonSizeThemeDetails {
  fontSize: FontSizeTypes;
  fontWeight: FontWeightTypes;
  buttonHeight: string;
}
type TButtonSize = 'xLarge' | 'large' | 'regular' | 'medium' | 'small' | 'xsmall';

const buttonSizeTheme: Record<TButtonSize, IButtonSizeThemeDetails> = {
  xLarge: {
    fontSize: 'lg',
    fontWeight: 'bold',
    buttonHeight: '63px',
  },
  large: {
    fontSize: 'base',
    fontWeight: 'bold',
    buttonHeight: '54px',
  },
  regular: {
    fontSize: 'base',
    fontWeight: 'bold',
    buttonHeight: '50px',
  },
  medium: {
    fontSize: 'sm',
    fontWeight: 'semiBold',
    buttonHeight: '48px',
  },
  small: {
    fontSize: 'xs',
    fontWeight: 'semiBold',
    buttonHeight: '40px',
  },
  xsmall: {
    fontSize: 'sm',
    fontWeight: 'medium',
    buttonHeight: '30px',
  },
};

export type ButtonSizeTypes = keyof typeof buttonSizeTheme;
export { buttonSizeTheme };
