import ChatBulkMessageListContainer from 'containers/chat/bulkMessage/ListContainer';

import DashboardLayout from 'components/layout/dashboard/DashboardLayout';

const ChatBulkMessageListPage = () => {
  return (
    <DashboardLayout>
      <ChatBulkMessageListContainer />
    </DashboardLayout>
  );
};

export default ChatBulkMessageListPage;
