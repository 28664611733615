import { useOverlay } from '@nurihaus/use-overlay';
import styled from 'styled-components';
import { Channel } from 'talkplus-sdk';

import NormalButton from 'components/common/button/NormalButton';
import { Row } from 'components/layout/common/InfoCard';
import CampaignLinkPinMsgModal from 'components/pages/chat/groupChat/talkplus/pinned/CampaignLinkPinMsgModal';
import NewPinMsgModal from 'components/pages/chat/groupChat/talkplus/pinned/NewPinMsgModal';

interface Props {
  channel: Channel;
  pinnedMsg: string;
}

const PinButtons = ({ channel }: Props) => {
  const notiEditOverlay = useOverlay();
  const deepLinkOverlay = useOverlay();

  const handleCreateNotiBtn = () => {
    notiEditOverlay.open(({ close }) => <NewPinMsgModal channel={channel} closeModal={close} />);
  };

  const handleDeepLinkModal = () => {
    deepLinkOverlay.open(({ isOpen, close }) => (
      <CampaignLinkPinMsgModal opened={isOpen} close={close} channel={channel} />
    ));
  };

  return (
    <PinButtonsContainer>
      <Row gap="10px">
        <NormalButton styleType="ghost" text="+ Notification" sizeType="medium" onPress={handleCreateNotiBtn} />
        <NormalButton styleType="ghost" text="+ Campaign" sizeType="medium" onPress={handleDeepLinkModal} />
      </Row>
    </PinButtonsContainer>
  );
};

export default PinButtons;

const PinButtonsContainer = styled.div``;
