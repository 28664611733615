import React from 'react';

import { useOverlay } from '@nurihaus/use-overlay';
import { ChannelCategoryEnum } from 'constants/chatConstants';
import dayjs from 'dayjs';
import { useCampaignListContext } from 'providers/pages/chat/CampaignListContext';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { firstLetterUpperCase } from 'utils/common/firstLetterUpperCase';

import { useRealtimeUnreadCount } from 'hooks/chat/useRealtimeUnreadCount';

import Icon from 'components/Icons/Icon';
import BadgeWithTooltip from 'components/common/badge/BadgeWithTooltip';
import StyledText from 'components/common/text/StyledText';
import CampaignDetailModal from 'components/pages/chat/list/CampaignDetailModal';

import { selectAddress } from 'features/redux/selectors/address';

import { color } from 'styles/assets';

import { Campaign } from 'types/campaign/internal';

import UnreadCountBadge from '../UnreadCountBadge';

interface Props {
  campaign: Campaign;
  onClick: () => void;
  isSelected: boolean;
}

const CampaignListItem = ({ campaign, onClick, isSelected }: Props) => {
  const campaignViewOverlay = useOverlay();
  const { country } = useSelector(selectAddress);
  const { expanded, tab } = useCampaignListContext();
  const category = tab === 'quest' ? ChannelCategoryEnum.QUEST : ChannelCategoryEnum.BENEFIT;
  const subcategory = campaign.id.toString();
  const { unreadCount } = useRealtimeUnreadCount({ category, subcategory });
  const { id, image, title, created, point, reward_price } = campaign;
  const isUGC = campaign?.is_ugc;
  const isPaid = campaign?.is_paid;

  const openDetailModal = () => {
    campaignViewOverlay.open(({ isOpen, close }) => (
      <CampaignDetailModal type={tab} id={id} opened={isOpen} close={close} />
    ));
  };

  const idText = expanded ? `${firstLetterUpperCase(tab)} #${id}` : `${firstLetterUpperCase(tab)}\n#${id}`;

  const handleContainerClick = (e: React.MouseEvent) => {
    if ((e.target as Element).closest('button')) {
      return;
    }

    onClick();
  };

  const tooltipJellyContent = () => (
    <TooltipContentWrapper>
      <Icon type="jelly_colored" width={24} height={24} />
      <StyledText
        text={`${tab === 'quest' ? '+' : '-'} ${point.toLocaleString()}`}
        fontColor="violet39"
        fontSize="sm"
        fontWeight="bold"
        whiteSpace="nowrap"
      />
    </TooltipContentWrapper>
  );

  const TooltipPaidContent = () => (
    <TooltipContentWrapper>
      <Icon type="paid" width={24} height={24} />
      <StyledText
        text={`${reward_price?.toLocaleString()} ${country.find((c) => c.id === Number(campaign.branch))?.currency}`}
        fontColor="violet39"
        fontSize="sm"
        fontWeight="bold"
        whiteSpace="nowrap"
      />
    </TooltipContentWrapper>
  );

  return (
    <Container $expanded={expanded} onClick={handleContainerClick} $isSelected={isSelected}>
      <IdWrapper $expanded={expanded}>
        <StyledText fontSize="sm" fontWeight="semiBold" text={idText} />
        <UnreadCountBadgeWrapper $expanded={expanded}>
          <UnreadCountBadge count={unreadCount || 0} />
        </UnreadCountBadgeWrapper>
      </IdWrapper>
      <CampaignInfo>
        <Thumbnail src={image} alt="campaign thumbnail" />
        <CampaignDetail $expanded={expanded}>
          <TitleWrapper $expanded={expanded}>
            <StyledText fontSize="sm" fontWeight="medium" text={title} numberOfLines={2} wordBreak="break-word" />
          </TitleWrapper>
          <AdditionalInfo $expanded={expanded}>
            <ConditionsContainer>
              <BadgeWithTooltip type="jelly_colored" tooltip={tooltipJellyContent()} />
              {isPaid && <BadgeWithTooltip type="paid" tooltip={<TooltipPaidContent />} />}
              {isUGC && (
                <BadgeWithTooltip
                  type="ugc"
                  tooltip={
                    <TooltipContentWrapper>
                      <Icon type="ugc" width={24} height={24} />
                      <StyledText text="UGC" fontColor="violet39" fontSize="sm" fontWeight="bold" />
                    </TooltipContentWrapper>
                  }
                />
              )}
            </ConditionsContainer>
            <StyledText
              fontSize="sm"
              fontWeight="medium"
              text={dayjs(created).format('YYYY.MM.DD')}
              fontColor="greya1"
            />
          </AdditionalInfo>
        </CampaignDetail>
      </CampaignInfo>
      <LinkButton $expanded={expanded} onClick={openDetailModal}>
        <Icon type="external_link" width={28} height={28} fill="violet39" />
      </LinkButton>
    </Container>
  );
};

CampaignListItem.displayName = 'CampaignListItem';

export default CampaignListItem;

const Container = styled.div<{ $expanded: boolean; $isSelected: boolean }>`
  width: 100%;
  height: ${({ $expanded }) => ($expanded ? '148px' : '98px')};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${color.greye4};
  position: relative;
  background-color: ${({ $isSelected }) => ($isSelected ? color.greyfa : color.white)};
  transition: all 0.3s ease-in-out;

  :hover {
    background-color: ${color.greyfa};
  }
`;

const LinkButton = styled.button<{ $expanded: boolean }>`
  position: absolute;
  top: 22px;
  right: 20px;
  opacity: ${({ $expanded }) => ($expanded ? 1 : 0)};
  visibility: ${({ $expanded }) => ($expanded ? 'visible' : 'hidden')};
  transition: all 0.3s ease-in-out;
`;

const CampaignInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Thumbnail = styled.img`
  width: 78px;
  height: 78px;
  border-radius: 4px;
  object-fit: cover;
`;

const CampaignDetail = styled.div<{ $expanded: boolean }>`
  width: calc(100% - 78px);
  padding-left: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  opacity: ${({ $expanded }) => ($expanded ? 1 : 0)};
  visibility: ${({ $expanded }) => ($expanded ? 'visible' : 'hidden')};
  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div<{ $expanded: boolean }>`
  width: 100%;
  padding-right: 48px;
  opacity: ${({ $expanded }) => ($expanded ? 1 : 0)};
  max-height: ${({ $expanded }) => ($expanded ? '100%' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

const AdditionalInfo = styled.div<{ $expanded: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  opacity: ${({ $expanded }) => ($expanded ? 1 : 0)};
  max-height: ${({ $expanded }) => ($expanded ? '100%' : '0')};
  transition: all 0.3s ease-in-out;
`;

const ConditionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TooltipContentWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin: -10px -5px;
`;

const IdWrapper = styled.div<{ $expanded: boolean }>`
  height: ${({ $expanded }) => ($expanded ? '20px' : '36px')};
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const UnreadCountBadgeWrapper = styled.div<{ $expanded: boolean }>`
  opacity: ${({ $expanded }) => ($expanded ? 1 : 0)};
  visibility: ${({ $expanded }) => ($expanded ? 'visible' : 'hidden')};
  transition: all 0.3s ease-in-out;
`;
