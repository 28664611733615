import { UserProfileDetails } from './account/internal';
import { Campaign } from './campaign/internal';

export enum MessagingStatus {
  PENDING = 1,
  SENT = 2,
  SCHEDULED = 3,
  CANCELLED = 4,
}

export enum MessagingSendType {
  SENT = 1,
  SCHEDULED = 2,
}

export enum MessagingUserStatus {
  ACCEPTED = 2,
  SUBMITTED = 11,
  NOT_SUBMITTED = 10,
}

export interface Messaging {
  id: number;
  message: string;
  object_id: number;
  content_type: number;
  content_object: Campaign;
  send_type: MessagingSendType;
  schedule: string;
  status: MessagingStatus;
  user_status: MessagingUserStatus;
  task_id: string;
  user: UserProfileDetails;
  created: string;
  success: number;
  fail: number;
  total: number;
  success_users: UserProfileDetails[];
  fail_users: UserProfileDetails[];
}

export interface PostMessagingPayload {
  message: string;
  object_id: number;
  content_type: number;
  user_status?: MessagingUserStatus;
  send_type?: MessagingSendType;
  schedule?: string;
  status?: MessagingStatus;
}
