import { ReactNode } from 'react';

import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import Tooltip from 'components/common/Tooltip';

import { color } from 'styles/assets';

interface Props {
  type: 'paid' | 'jelly_colored' | 'ugc';
  tooltip: ReactNode;
}

const BadgeWithTooltip = ({ type, tooltip }: Props) => {
  return (
    <BadgeContainer>
      <Tooltip
        width="auto"
        tooltipDirection="bottom"
        backgroundColor="white"
        borderColor="greyd4"
        hoverElement={<Icon type={type} width={24} height={24} />}
      >
        {tooltip}
      </Tooltip>
    </BadgeContainer>
  );
};

export default BadgeWithTooltip;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 1px solid ${color.greyd4};
`;
