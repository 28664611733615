import { useOverlay } from '@nurihaus/use-overlay';
import { t } from 'i18next';
import styled from 'styled-components';

import { useChat } from 'hooks/chat/queries';

import Avatar from 'components/common/Avatar';
import NormalButton from 'components/common/button/NormalButton';
import StyledText from 'components/common/text/StyledText';
import ConfirmModal from 'components/pages/chat/room/talkPlus/ConfirmModal';

import { color } from 'styles/assets';

interface Props {
  channelId: string;
  focusChannel: (channelId: string | null) => void;
}

const ChatUserInfo = ({ channelId, focusChannel }: Props) => {
  const { useGetChannel, useGetChannelMembers } = useChat();
  const { data: membersData } = useGetChannelMembers({ channelId });
  const { data: channelData } = useGetChannel({ channelId });
  const otherMember = membersData?.members.find((member) => member.id !== channelData?.channel.ownerId);
  const confirmModal = useOverlay();

  const handleConfirmModal = () => {
    confirmModal.open(({ isOpen, close }) => (
      <ConfirmModal isOpen={isOpen} close={close} channelId={channelId} focusChannel={focusChannel} />
    ));
  };

  return (
    <Container>
      {otherMember && (
        <UserInfoContainer>
          <Avatar avatarSize="m" src={otherMember.profileImageUrl} />
          <UserInfo>
            <StyledText text={otherMember.username} fontSize="lg" fontWeight="bold" />
            <StyledText text={'ID : ' + otherMember.data?.['nuriUsername']} fontSize="sm" fontWeight="regular" />
          </UserInfo>
        </UserInfoContainer>
      )}
      <BannerPosition>
        <CloseBannerContainer>
          <StyledText fontSize="xs" fontWeight="medium" text={t('chat.private-close-banner-text')} />
          <NormalButton sizeType="medium" styleType="ghost" text="Close" onPress={handleConfirmModal} width="120px" />
        </CloseBannerContainer>
      </BannerPosition>
    </Container>
  );
};

export default ChatUserInfo;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${color.greye4};
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 98px;
  padding: 0 20px;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const BannerPosition = styled.div`
  position: absolute;
  width: 100%;
  top: 104px;
  left: 50%;
  transform: translateX(-50%);
`;

const CloseBannerContainer = styled.div`
  width: calc(100% - 32px);
  height: 70px;
  margin: 0 16px;
  border: 1px solid ${color.greyd4};
  background-color: ${color.greyfa};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
  padding: 0 14px;
`;
