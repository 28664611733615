import { userType } from 'utils/account/accessLevel';

import { UserDetails } from 'types/account/internal';
import { Campaign } from 'types/campaign/internal';

export const canEditCampaign = (user: UserDetails, detailData: Campaign) => {
  if (userType(user) === 'super' || userType(user) === 'partner') return true;
  else return user?.id === detailData?.user?.id;
};

export const canSendBulkMessage = (user: UserDetails) => {
  if (userType(user) === 'super') return true;

  if (userType(user) === 'partner') return true;
  else return false;
};

export const canEditBranch = (user: UserDetails) => {
  if (userType(user) === 'super') return true;
  else return false;
};

export const canEditAuthor = (user: UserDetails) => {
  if (userType(user) === 'super') return true;
  else return false;
};

export const canEditPaidAndVerified = (user: UserDetails) => {
  if (userType(user) === 'super') return true;

  return false;
};

export const canAccessPaidText = (user: UserDetails) => {
  if (userType(user) === 'partner') return false;
  else return true;
};

export const canDropUsers = (user: UserDetails) => {
  if (userType(user) === 'super' || userType(user) === 'partner') return true;
  else return false;
};

export const canOnlyApplyVerifiedUser = (access_user_groups: string[]) => {
  return !access_user_groups.includes('3');
};
