import CryptoJS from 'crypto-js';
import storage from 'redux-persist/lib/storage';

const privateKey = process.env.REACT_APP_CRYPTO_SECRET_KEY || '';

const encrypto = <T = any>(data: T) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), privateKey).toString();
};

const decrypto = <T = any>(data: T) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, privateKey);

    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch {
    // console.log('Decryption error :', error);
    return null;
  }
};

const customStorage = {
  getItem: async (key: string) => {
    const data = await storage.getItem(key);
    if (!data) return data;

    return decrypto(data);
  },
  setItem: async <T = any>(key: string, value: T) => {
    return await storage.setItem(key, encrypto(value));
  },
  removeItem: async (key: string) => {
    return await storage.removeItem(key);
  },
};

export default customStorage;
