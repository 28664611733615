import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { editBulkMessaging, getBulkMessagingDetail, getBulkMessagingList, postBulkMessaging } from 'api/bullkMessage';

import { BulkMessageModalDataType } from 'hooks/chat/bulkMessage/useBulkMessageModal';

import { selectAccount } from 'features/redux/selectors/accounts';

import {
  Messaging,
  MessagingSendType,
  MessagingStatus,
  MessagingUserStatus,
  PostMessagingPayload,
} from 'types/bulkMessge';
import { PaginationType } from 'types/common';

interface PostProps {
  campaign: 'quest' | 'benefit';
  id: number;
  onSuccess?: () => void;
  onError?: (err: any) => void;
}

interface GetListProps {
  page: number;
  page_size: number;
}

interface EditProps {
  onSuccess?: (res: Messaging) => void;
  onError?: (err: any) => void;
  id: number;
}

interface EditPayload extends BulkMessageModalDataType {
  object_id: number;
  content_type: number;
}

const usePostBulkMessage = ({ campaign, id, onSuccess, onError }: PostProps) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const api = async (props: BulkMessageModalDataType) => {
    const { target, sendTime, content, schedule } = props;
    const payload: PostMessagingPayload = {
      content_type: campaign === 'benefit' ? 85 : 74,
      object_id: Number(id),
      message: content,
      send_type: sendTime === 'send-immediately' ? MessagingSendType.SENT : MessagingSendType.SCHEDULED,
      schedule: sendTime === 'send-immediately' ? new Date().toISOString() : schedule?.toISOString() || undefined,
      user_status: MessagingUserStatus[target],
    };

    return postBulkMessaging({ access_token, data: payload });
  };

  return useMutation(async (props: BulkMessageModalDataType) => api(props), {
    onSuccess,
    onError,
  });
};

const useEditBulkMessage = ({ id, onSuccess, onError }: EditProps) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const api = async (props: EditPayload) => {
    const { target, sendTime, content, schedule, object_id, content_type } = props;
    const payload: PostMessagingPayload = {
      message: content,
      send_type: sendTime === 'send-immediately' ? MessagingSendType.SENT : MessagingSendType.SCHEDULED,
      schedule: sendTime === 'send-immediately' ? new Date().toISOString() : schedule?.toISOString() || undefined,
      object_id,
      content_type,
      user_status: MessagingUserStatus[target],
    };

    return editBulkMessaging({
      access_token,
      id,
      data: payload,
    });
  };

  return useMutation(async (props: any) => api(props), {
    onSuccess,
    onError,
  });
};

export const useCancelBulkMessage = ({ id, onSuccess, onError }: EditProps) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const api = async (props: Partial<EditPayload>) => {
    const { content, object_id, content_type } = props;

    return editBulkMessaging({
      access_token,
      id,
      data: {
        status: MessagingStatus.CANCELLED,
        object_id,
        content_type,
        message: content,
      },
    });
  };

  return useMutation(api, {
    onSuccess,
    onError,
  });
};

const useGetBulkMessageList = ({ page, page_size }: GetListProps) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const api = async (page: number) => {
    return getBulkMessagingList({ access_token, page, page_size });
  };

  return useQuery<PaginationType<Messaging>, AxiosError>(['bulkMessageList', page], () => api(page), {
    keepPreviousData: true,
  });
};

const useGetBulkMessage = (id: number) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const api = async (id: number) => {
    return getBulkMessagingDetail({ access_token, id });
  };

  return useQuery<Messaging, AxiosError>(['bulkMessage', id], () => api(id));
};

export { usePostBulkMessage, useEditBulkMessage, useGetBulkMessageList, useGetBulkMessage };
