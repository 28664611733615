import { createSlice } from '@reduxjs/toolkit';

import { AccountState } from 'types/account/internal';

const initialState: AccountState = {
  userInfo: {
    refresh_token: '',
    access_token: '',
    access_token_expiration: 0,
    user: {
      id: 0,
      username: '',
      email: '',
      email_valid: null,
      phone: '',
      birthday: '',
      profile: '',
      message: '',
      newsletter: false,
      get_profile: '',
      level: '',
      account_type: 0,
      point: 0,
      credit: 0,
      is_staff: false,
      has_social: false,
      slug: '',
      get_today_jelly: false,
      nickname: '',
      social_main: '',
      social_main_id: '',
      follower: 0,
      nlp: '0',
      complete_tutorial: false,
      has_transaction: false,
      is_blocked: false,
      referer: '',
      phone_code: '',
    },
  },
  social: {
    id: 0,
    instagram: '',
    instagram_count: 0,
    youtube: '',
    youtube_count: 0,
    tiktok: '',
    tiktok_count: 0,
    twitch: '',
    twitch_count: 0,
    others: '',
    main: '',
    description: '',
    created: '',
    level: 1,
    commission: 0,
    commission_ratio: 0,
    is_official: false,
    verified_channels: [],
    nationality: 1,
  },
  isLogin: false,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    Login: (state, action) => {
      return {
        ...state,
        userInfo: {
          user: action.payload.user,
          refresh_token: action.payload.refresh_token,
          access_token: action.payload.access_token,
          access_token_expiration: Date.now() + 1000 * 60 * 60 * 6,
        },
        isLogin: true,
      };
    },
    SignOut: () => {
      return initialState;
    },
    IssueToken: (state, action) => {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          refresh_token: action.payload.refresh,
          access_token: action.payload.access,
          access_token_expiration: Date.now() + 1000 * 60 * 60 * 6,
        },
      };
    },
    GetSocial: (state, action) => {
      return {
        ...state,
        social: action.payload,
      };
    },
    UpdateUser: (state, action) => {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: { ...action.payload },
        },
      };
    },
  },
});

export const { GetSocial, Login, SignOut, IssueToken, UpdateUser } = accountSlice.actions;

export default accountSlice.reducer;
