export const errToErrMsg = (err: { data: string; status: number } | any) => {
  const DEFAULT_MESSAGE = 'An error occurred.';
  if (!err) return DEFAULT_MESSAGE;

  let msg = '';
  if (err?.status === 500) {
    return 'server error';
  }

  if (err === undefined || (typeof err?.data === 'string' && err?.data?.length > 100)) {
    return DEFAULT_MESSAGE;
  }

  if (typeof err?.data === 'object') {
    const keys = Object.keys(err.data);
    if (Array.isArray(err.data)) {
      if (typeof err.data[0] === 'string') {
        if ((err.data[0] as string).includes('enough point') && (err.data[0] as string).split('"').length > 2) {
          msg = (err.data[0] as string).split('"')[1].replaceAll(/\\n/g, ' ');
        } else if ((err.data[0] as string).length <= 100) {
          msg = err.data[0];
        }
      }
    } else if (Array.isArray(err?.data?.[keys[0]])) {
      msg = `${keys[0] === 'non_field_errors' ? '' : keys[0] + ' : '}${err.data[keys[0]][0]}`;
    } else {
      msg = `${keys[0] === 'non_field_errors' ? '' : keys[0] + ' : '}${err.data[keys[0]]}`;
    }
  } else if (typeof err?.data === 'string') {
    if (err.data.length <= 100) {
      msg = err.data;
    }
  }

  return msg || DEFAULT_MESSAGE;
};
