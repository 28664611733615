import { ReactNode } from 'react';

import styled from 'styled-components';

interface Props {
  children: ReactNode;
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  width?: string;
}

const BlockToCenter = ({ children, justifyContent = 'center', width = '560px' }: Props) => {
  return (
    <Container justifyContent={justifyContent}>
      <BlockWrapper width={width}>{children}</BlockWrapper>
    </Container>
  );
};

export default BlockToCenter;

const Container = styled.div<{ justifyContent: 'flex-start' | 'center' | 'flex-end' }>`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.justifyContent};
`;

const BlockWrapper = styled.div<{ width: string }>`
  width: ${(props) => props.width};
`;
