import { Fragment } from 'react';

import { t } from 'i18next';
import styled from 'styled-components';

import {
  bulkMessageSendTimeOptions,
  bulkMessageTargetOptions,
  useBulkMessageModal,
} from 'hooks/chat/bulkMessage/useBulkMessageModal';

import RadioButton from 'components/common/input/RadioButton';
import StyledText from 'components/common/text/StyledText';
import { Column } from 'components/layout/common/InfoCard';
import Calendar from 'components/pages/chat/bulkMessage/Calendar';

import { color } from 'styles/assets';

import { MessagingSendType, MessagingUserStatus } from 'types/bulkMessge';

interface Props {
  bulkMessage: any;
  editable?: boolean;
}

const BulkMessageInfo = ({ bulkMessage, editable }: Props) => {
  const {
    message: initialMessage,
    send_type: initialSendType,
    schedule: initialSchedule,
    user_status: initialUserStatus,
  } = bulkMessage;
  const targetOption = bulkMessageTargetOptions();
  const sendTimeOption = bulkMessageSendTimeOptions();
  const {
    state: { target, content, sendTime, schedule },
    handlers: { handleInput },
  } = useBulkMessageModal({
    initialData: {
      target:
        initialUserStatus in MessagingUserStatus
          ? MessagingUserStatus[initialUserStatus]
          : (MessagingUserStatus[2] as any),
      content: initialMessage,
      sendTime: initialSendType === MessagingSendType.SENT ? 'send-immediately' : 'send-schedule',
      schedule: new Date(initialSchedule),
    },
  });

  const disabled = !editable;

  return (
    <Fragment>
      <Column gap="6px">
        <StyledText fontSize="base" fontWeight="bold" text={t('chat.bulkMessage-detail-sendTo')} />
        <RadioButton
          name="target"
          value={target}
          onChange={(e) => handleInput('target', e.target.value)}
          options={targetOption}
          gap="40px"
          disabled={disabled}
        />
      </Column>
      <Column gap="6px">
        <StyledText fontSize="base" fontWeight="bold" text={t('chat.bulkMessage-detail-text')} />
        <ContentInput
          placeholder={t('campaign.bulkMessage-modal-content-placeholder')}
          value={content}
          onChange={(e) => handleInput('content', e.target.value)}
          name="content"
          disabled={disabled}
        />
      </Column>
      <Column gap="6px">
        <StyledText fontSize="base" fontWeight="bold" text={t('chat.bulkMessage-detail-sendTime')} />
        <TimeOption>
          <RadioButton
            name="sendTime"
            disabled={disabled}
            value={sendTime}
            onChange={(e) => handleInput('sendTime', e.target.value)}
            options={sendTimeOption}
            gap="70px"
          />
          <PickerWrapper>
            <Calendar
              disabled={disabled || sendTime !== 'send-schedule'}
              dateOnChange={(e) => handleInput('schedule', e)}
              type="date"
              value={schedule}
              label={t('campaign.bulkMessage-modal-time-picker-label')}
              name="schedule-time"
            />
          </PickerWrapper>
        </TimeOption>
      </Column>
    </Fragment>
  );
};

export default BulkMessageInfo;

const TimeOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PickerWrapper = styled.div`
  width: 300px;
  margin-left: 270px;
`;

const ContentInput = styled.textarea`
  width: 100%;
  height: 260px;
  border: 1px solid ${color.greyd4};
  border-radius: 4px;
  resize: none;
  padding: 14px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  &:disabled {
    border: none;
    background-color: ${color.greyfa};
    color: ${color.black};
  }
`;
