import { useState } from 'react';

import { ChannelCategoryEnum } from 'constants/chatConstants';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getKeyByValue } from 'utils/common/getKeyByValue';

import { useCampaignDetailData } from 'hooks/campaign/queries/detail';
import { useChat } from 'hooks/chat/queries';

import Icon from 'components/Icons/Icon';
import MenuIcon from 'components/Icons/MenuIcon';
import Avatar from 'components/common/Avatar';
import StyledText from 'components/common/text/StyledText';
import { Row } from 'components/layout/common/InfoCard';

import { color } from 'styles/assets';

import { GetChannelReturn } from 'types/chat/remote';

interface Props {
  channelId: string;
}

interface Props2 extends Props {
  data: GetChannelReturn;
}

const CampaignInfo = ({ channelId }: Props) => {
  const { useGetChannel } = useChat();
  const { data, isLoading } = useGetChannel({ channelId });

  if (isLoading || !data?.channel) return <></>;

  return <CampaignInfoBlock channelId={channelId} data={data} />;
};

const CampaignInfoBlock = ({ data }: Props2) => {
  const { data: campaignData } = useCampaignDetailData({
    campaign: data?.channel?.category
      ? getKeyByValue(ChannelCategoryEnum, data.channel.category as unknown as ChannelCategoryEnum).toLowerCase()
      : '',
    detailId: Number(data?.channel?.subcategory || 0),
  });
  const [shouldDisplayBadges, setShouldDisplayBadges] = useState(false);

  const channel = data.channel;
  const campaign = channel.category;
  const campaignId = channel.subcategory;
  const campaignType = campaign
    ? getKeyByValue(ChannelCategoryEnum, campaign as unknown as ChannelCategoryEnum).toLowerCase()
    : '';

  const toggleBadgeBox = () => setShouldDisplayBadges((prev) => !prev);

  return (
    <CampaignInfoHeader>
      <InfoBox>
        <Link to={`/detail/${campaignType}/${campaignId}`} target="_blank" style={{ width: '100%' }}>
          <Row justifyContent="flex-start">
            {campaignData?.image ? <CampaignThumbnail src={campaignData?.image} /> : <Avatar />}
            <TitleWrapper>
              <StyledText fontSize="sm" text={campaignData?.title} numberOfLines={2} />
            </TitleWrapper>
          </Row>
        </Link>
        <IconWrapper onClick={toggleBadgeBox}>
          <MenuIcon type={shouldDisplayBadges ? 'chevron_top' : 'chevron_bottom'} />
        </IconWrapper>
      </InfoBox>
      {shouldDisplayBadges && (
        <BadgeBox>
          <BadgeTag>
            <Icon type="jelly_colored" />
            <StyledText
              fontSize="sm"
              fontWeight="bold"
              text={`${campaign === '1' ? '+' : '-'}${campaignData?.point}`}
            />
          </BadgeTag>
          {campaignData.is_paid && (
            <BadgeTag>
              <Icon type="paid" />
              <StyledText fontSize="sm" fontWeight="bold" text="Paid" fontColor="yellowff" />
            </BadgeTag>
          )}
          {campaignData.is_ugc && (
            <BadgeTag>
              <Icon type="ugc" />
              <StyledText fontSize="sm" fontWeight="bold" text="UGC" fontColor="violet39" />
            </BadgeTag>
          )}
        </BadgeBox>
      )}
    </CampaignInfoHeader>
  );
};

export default CampaignInfo;

const CampaignInfoHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${color.greye4};
  padding: 20px 16px;
`;

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CampaignThumbnail = styled.img`
  width: 58px;
  height: 58px;
  border-radius: 4px;
  overflow: hidden;
  object-fit: cover;
  margin-right: 10px;
`;

const TitleWrapper = styled.div`
  width: calc(100% - (58px + 10px + 88px + 12px));
`;

const BadgeTag = styled.div<{ isChatEnd?: boolean }>`
  visibility: ${({ isChatEnd }) => (isChatEnd ? 'hidden' : 'visible')};
  width: 88px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 52px;
  border: 1px solid ${color.greyd4};
`;

const BadgeBox = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  margin-top: 14px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 58px;
`;
