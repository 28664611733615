export const chatCategoryConverter = (category: 'jelly' | 'spend' | 'earn' | string) => {
  switch (category) {
    case 'jelly':
      return 'Jelly Boost';
    case 'spend':
      return 'Benefit Campaign';
    case 'earn':
      return 'Quest Campaign';
    default:
      return 'Campaign';
  }
};
