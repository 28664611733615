export const tabsConstants = {
  campaign: [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Expired', value: 'expired' },
    { label: 'Draft', value: 'draft' },
    { label: 'Complete', value: 'complete' },
  ],
  applicant: [
    { label: 'All', value: 'all' },
    { label: 'Waiting', value: 'Waiting' },
    { label: 'Accepted', value: 'Accepted' },
    { label: 'Declined', value: 'Declined' },
    { label: 'Completed', value: 'Completed' },
  ],
  report: [
    { label: 'All', value: 'all' },
    { label: 'On-going', value: 'on-going' },
    { label: 'Closed', value: 'closed' },
  ],
  banner: [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Deactivated', value: 'deactivated' },
  ],
  chat: [{ label: 'Campaign', value: 'campaign' }],
  user: [
    { label: 'All', value: 'all' },
    { label: 'Member', value: 'member' },
    { label: 'Creator', value: 'creator' },
    { label: 'Verified', value: 'verified' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Partner', value: 'partner' },
    {
      label: 'Brand',
      value: 'brand',
    },
  ],

  partner: [{ label: 'All', value: 'business' }],
  analytics: [
    { label: 'User', value: 'user' },
    { label: 'Campaign', value: 'campaign' },
  ],
};

export type Tab = { label: string; value: string };
export type TabsConstantsType = keyof typeof tabsConstants;
