import { requestWithAuth } from 'utils/common/axios-utils';

import { Messaging, PostMessagingPayload } from 'types/bulkMessge';
import { PaginationType } from 'types/common';

export const postBulkMessaging = async ({
  access_token,
  data,
}: {
  access_token: string;
  data: PostMessagingPayload;
}) => {
  return requestWithAuth<Messaging>(
    {
      method: 'post',
      url: '/messaging/',
      data,
    },
    access_token
  );
};

export const getBulkMessagingList = async ({
  access_token,
  page,
  page_size,
}: {
  access_token: string;
  page: number;
  page_size: number;
}) => {
  return requestWithAuth<PaginationType<Messaging>>(
    {
      method: 'get',
      url: `/messaging/?page=${page}&page_size=${page_size}`,
    },
    access_token
  );
};

export const getBulkMessagingDetail = async ({ access_token, id }: { access_token: string; id: number }) => {
  return requestWithAuth<Messaging>(
    {
      method: 'get',
      url: `/messaging/${id}/`,
    },
    access_token
  );
};

export const editBulkMessaging = async ({
  access_token,
  id,
  data,
}: {
  access_token: string;
  id: number;
  data: Partial<PostMessagingPayload>;
}) => {
  return requestWithAuth<Messaging>(
    {
      method: 'put',
      url: `/messaging/${id}/`,
      data,
    },
    access_token
  );
};
