import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';

import { color } from 'styles/assets';

interface Props {
  count?: number;
}

const UnreadCountBadge = ({ count }: Props) => {
  if (!count) return null;

  return (
    <UnreadBadge>
      <StyledText text={count > 99 ? '99+' : String(count)} fontSize="xs" fontWeight="bold" fontColor="white" />
    </UnreadBadge>
  );
};

export default UnreadCountBadge;

const UnreadBadge = styled.div`
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  border-radius: 40px;
  background-color: ${color.reddf};
  display: flex;
  justify-content: center;
  align-items: center;
`;
