import { useContext, useEffect, useState } from 'react';

import { useQueryClient } from 'react-query';

import { chatKeys, useChat } from 'hooks/chat/queries';

import { AppContext } from 'features/redux/context';

export const useRealtimeUnreadCount = ({ category, subcategory }: { category?: string; subcategory?: string }) => {
  const { talkPlus } = useContext(AppContext);
  const queryClient = useQueryClient();
  const { useGetUnreadCount } = useChat();
  const { data } = useGetUnreadCount({ category, subcategory });
  const [unreadCount, setUnreadcount] = useState(data?.count);

  useEffect(() => {
    setUnreadcount(data?.count);
  }, [data]);

  useEffect(() => {
    const handleUnreadCount = (e: any) => {
      if (category) {
        queryClient.invalidateQueries({
          queryKey: chatKeys.unreads({ category: e.channel.category, subcategory: e.channel.subcategory }),
          exact: true,
          refetchActive: true,
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: chatKeys.unreads({}),
          exact: true,
          refetchActive: true,
        });
      }
    };

    talkPlus.on('event', handleUnreadCount);

    return () => {
      talkPlus.off('event', handleUnreadCount);
    };
  }, []);

  return { unreadCount };
};
