import { t } from 'i18next';
import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

import { color } from 'styles/assets';

interface Props {
  isClosed: boolean;
}

const VerifiedOnlyBadge = ({ isClosed }: Props) => {
  return (
    <Container data-testid="verified-badge-container" isClosed={isClosed}>
      <Icon type="check" width={16} height={16} fill="white" />
      <Gap side={2} />
      <StyledText text={t('campaign.detail-badge-verified')} fontSize="xs" fontColor="white" />
    </Container>
  );
};

export default VerifiedOnlyBadge;

const Container = styled.div<{ isClosed: boolean }>`
  height: 26px;
  padding: 4px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isClosed }) => (isClosed ? color.greyd4 : color.violet39)};
  border-radius: 24px;
`;
