import { ComponentPropsWithoutRef, MouseEvent, ReactNode, isValidElement } from 'react';

import { CSSProperties } from 'styled-components';

import MenuIcon from 'components/Icons/MenuIcon';
import ButtonShape from 'components/common/button/ButtonShape';
import ComponentLoading from 'components/common/loading/ComponentLoading';
import StyledText from 'components/common/text/StyledText';
import { Row } from 'components/layout/common/InfoCard';

import { color } from 'styles/assets';
import { buttonHoverStyleTheme } from 'styles/assets/elements/buttonTheme/ButtonHoverStyleTheme';
import { ButtonSizeTypes, buttonSizeTheme } from 'styles/assets/elements/buttonTheme/ButtonSizeTheme';
import { ButtonStyleTypes, buttonStyleTheme } from 'styles/assets/elements/buttonTheme/ButtonStyleTheme';

interface Props extends Omit<ComponentPropsWithoutRef<'button'>, 'type' | 'onClick'> {
  sizeType?: ButtonSizeTypes;
  styleType?: ButtonStyleTypes;
  children?: ReactNode;
  text?: string | ReactNode;
  onPress?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  width?: string;
  borderRadius?: string;
  icon?: 'right' | 'left' | false;
  type?: string;
  iconPosition?: 'left' | 'left-center' | 'right-center' | 'right';
  customIcon?: ReactNode;
  isLoading?: boolean;
  style?: CSSProperties;
}

const NormalButton = ({
  sizeType = 'xLarge',
  styleType = 'primary',
  children,
  text,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPress = (e: MouseEvent<HTMLButtonElement>) => {},
  disabled = false,
  width,
  borderRadius,
  icon = false,
  type = 'button',
  iconPosition = 'left-center',
  customIcon,
  isLoading = false,
  style,
  ...rest
}: Props) => {
  const currentStyle = disabled ? buttonStyleTheme.disabled : buttonStyleTheme[styleType];
  const hoverStyle = disabled ? buttonStyleTheme.disabled : buttonHoverStyleTheme[styleType];
  const iconColor = currentStyle.fontColor;
  const isTextString = text && typeof text === 'string';
  const isTextReactNode = text && typeof text !== 'string' && isValidElement(text);

  return (
    <ButtonShape
      type={type}
      onPress={onPress}
      disabled={disabled}
      backgroundColor={color[currentStyle.backgroundColor]}
      height={buttonSizeTheme[sizeType].buttonHeight}
      border={buttonStyleTheme[disabled ? 'disabled' : styleType].border}
      width={width}
      borderRadius={borderRadius}
      hover={hoverStyle}
      style={style}
      {...rest}
    >
      {isLoading ? (
        <ComponentLoading indicatorColor="white" size={20} justifyContent="center" />
      ) : (
        <>
          {icon === 'left' && <MenuIcon type="chevron_left" fill={iconColor} />}
          {customIcon && iconPosition === 'left' && customIcon}
          {children}
          <Row justifyContent="center" gap="6px" alignItems="center">
            {customIcon && iconPosition === 'left-center' && customIcon}
            {isTextString && (
              <StyledText
                text={text}
                fontSize={buttonSizeTheme[sizeType].fontSize}
                fontWeight={buttonSizeTheme[sizeType].fontWeight}
                fontColor={buttonStyleTheme[disabled ? 'disabled' : styleType].fontColor}
                textAlign="center"
              />
            )}
            {isTextReactNode && text}
            {customIcon && iconPosition === 'right-center' && customIcon}
          </Row>

          {customIcon && iconPosition === 'right' && customIcon}
          {icon === 'right' && <MenuIcon type="chevron_right" fill={iconColor} />}
        </>
      )}
    </ButtonShape>
  );
};

export default NormalButton;
