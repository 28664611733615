import { ColorTypes } from 'styles/assets';
import { TButtonStyle } from 'styles/assets/elements/buttonTheme/ButtonStyleTheme';

interface IButtonHoverStyleThemeDetails {
  backgroundColor: ColorTypes;
  fontColor: ColorTypes;
  border?: {
    borderWidth: string;
    borderStyle: string;
    borderColor: ColorTypes;
  };
}

const buttonHoverStyleTheme: Record<TButtonStyle, IButtonHoverStyleThemeDetails | undefined> = {
  primary: {
    backgroundColor: 'violet4c',
    border: undefined,
    fontColor: 'white',
  },
  secondary: undefined,
  ghost: {
    backgroundColor: 'white',
    fontColor: 'violet4c',
    border: {
      borderWidth: '1.5px',
      borderStyle: 'solid',
      borderColor: 'violet4c',
    },
  },
  disabled: undefined,
  sns: undefined,
  underlined: undefined,
  warning: undefined,
  drop: undefined,
};

export { buttonHoverStyleTheme };
