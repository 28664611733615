import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';

import { ColorTypes, FontWeightTypes, color } from 'styles/assets';

interface Props {
  dueDate: string;
  state?: 'expired' | 'scheduled';
}

const DDayLabel = ({ dueDate, state }: Props) => {
  const calculateLeftDay = (dueDate: string) => {
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const targetDate = new Date(new Date(dueDate).setHours(0, 0, 0, 0));
    const dayDifference = targetDate.getTime() - currentDate.getTime();

    return Math.ceil(dayDifference / (1000 * 3600 * 24));
  };

  const expired = state === 'expired';
  const scheduled = state === 'scheduled';

  const leftDay = calculateLeftDay(dueDate);
  const closeToDueDate = leftDay < 4;
  const styleType = closeToDueDate ? 'closeToDueDate' : scheduled ? 'scheduled' : expired ? 'expired' : 'normal';

  const getText = () => {
    return scheduled ? 'Scheduled' : expired ? 'Closed' : `D-${leftDay > 99 ? '99+' : leftDay}`;
  };

  return (
    <Container back={theme[styleType].backgroundColor} bor={theme[styleType].border} p={theme[styleType].padding}>
      <StyledText
        text={getText()}
        fontSize="xs"
        fontWeight={theme[styleType].fontFamily}
        fontColor={theme[styleType].fontColor}
      />
    </Container>
  );
};

export default DDayLabel;

const Container = styled.div<{ back: ColorTypes; bor?: ColorTypes; p: string }>`
  padding: ${({ p }) => p};
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ back }) => color[back]};
  border-radius: 30px;
  border-width: ${({ bor }) => (bor ? '1px' : '0px')};
  border-color: ${({ bor }) => bor || 'none'};
`;

const theme: {
  [key: string]: {
    padding: string;
    border: undefined | ColorTypes;
    backgroundColor: ColorTypes;
    fontColor: ColorTypes;
    fontFamily: FontWeightTypes;
  };
} = {
  expired: {
    padding: '0px 18px',
    border: undefined,
    backgroundColor: 'greyf4',
    fontColor: 'black',
    fontFamily: 'semiBold',
  },
  scheduled: {
    padding: '0px 11px',
    border: 'violet4c',
    backgroundColor: 'white',
    fontColor: 'violet4c',
    fontFamily: 'extraBold',
  },
  closeToDueDate: {
    padding: '0px 18px',
    border: undefined,
    backgroundColor: 'violet4c',
    fontColor: 'white',
    fontFamily: 'extraBold',
  },
  normal: {
    padding: '0px 18px',
    border: undefined,
    backgroundColor: 'greyf4',
    fontColor: 'violet4c',
    fontFamily: 'extraBold',
  },
};
