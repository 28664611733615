import styled from 'styled-components';
import { Message } from 'talkplus-sdk';

import StyledText from 'components/common/text/StyledText';

interface Props {
  message: Message | undefined;
}
const JoinMessage = ({ message }: Props) => {
  return (
    <Wrapper>
      <StyledText fontSize="sm" fontWeight="semiBold" text={message?.text} textAlign="center" fontColor="grey71" />
    </Wrapper>
  );
};

export default JoinMessage;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
