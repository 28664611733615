import React from 'react';

import * as Sentry from '@sentry/react';

import Fallback from 'components/common/errorBoundary/Fallback';
import {
  ApiError,
  NetworkError,
  RuntimeError,
  TimeoutError,
  convertError,
} from 'components/common/errorBoundary/errors';

import { AccountState } from 'types/account/internal';

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  fallbackStyle?: React.CSSProperties;
  resetKeys?: string[];
  onReset?: () => void;
  onError?: <T extends Error>(error?: T) => void;
  level?: 'fatal' | 'error';
  ignoreError?: boolean;
  user?: AccountState;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  static extractComponentStack = (componentStack: string): string => {
    return componentStack
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => {
        return (
          line.startsWith('at ') &&
          !line.match(/^at (div|span|p|button|input|form)$/i) &&
          !/^at http:\/\/|^at https:\/\//.test(line)
        );
      })
      .map((line) => {
        const match = line.match(/at ([A-Za-z0-9_]+)\s*[\(]?http/);

        return match ? match[1] : line.replace('at ', '').split(' (')[0].trim();
      })
      .filter(
        (line) =>
          ![
            'Provider',
            'ThemeProvider',
            'Router',
            'Suspense',
            'PersistGate',
            'QueryClientProvider',
            'AppProviders',
            'RenderedRoute',
            'Routes',
            'BrowserRouter',
            'OverlayProvider',
            'Grid',
            'P',
            'DashboardLayout',
          ].includes(line)
      )
      .join(' > ');
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!this.state.hasError) return;

    const hasResetKeysChanged = this.props.resetKeys?.some((key, index) => prevProps.resetKeys?.[index] !== key);

    if (hasResetKeysChanged) {
      this.resetError();
    }
  }

  // * 이거 미니까 아래에서 타입에러나네요 @Nave 이건 네이브님이..
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ hasError: true });
  }

  resetError() {
    this.props.onReset?.();
    this.setState({ hasError: false });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }
  }
}

export class BaseErrorBoundary extends ErrorBoundary {
  constructor(props: Props) {
    super(props);
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Sentry.setUser({
      id: this.props.user?.userInfo.user.id,
      username: this.props.user?.userInfo.user.username,
    });

    const convertedError = convertError(error, errorInfo.componentStack);
    throw convertedError;
  }
}

export class ApiErrorBoundary extends ErrorBoundary {
  private apiError: ApiError | null = null;
  private errorMessage = '데이터 요청에 실패했습니다.';

  private static getErrorMessageByStatusCode(statusCode: number): string {
    if (statusCode >= 500) {
      return '서버에 문제가 발생했습니다. 잠시 후 다시 시도해주세요.';
    }

    const errorMessages: Record<number, string> = {
      400: '잘못된 요청입니다.',
      403: '접근 권한이 없습니다.',
      404: '요청하신 데이터를 찾을 수 없습니다.',
    };

    return errorMessages[statusCode] ?? '데이터 요청에 실패했습니다.';
  }

  constructor(props: Props) {
    super(props);
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (!(error instanceof ApiError)) throw error;

    this.errorMessage = ApiErrorBoundary.getErrorMessageByStatusCode(Number(error.getStatusCode()) ?? 500);
    this.apiError = error;

    if (this.apiError.getStatusCode() === 401) {
      window.location.href = '/login';

      return;
    }

    Sentry.withScope((scope) => {
      const apiError = error as ApiError;

      scope.setLevel(this.props.level ?? 'error');

      scope.setFingerprint([
        'api-error',
        apiError.getRequestData()?.url ?? 'unknown-url',
        String(apiError.getStatusCode()),
      ]);

      scope.setContext('api-error', {
        error_type: 'api error',
        status_code: apiError.getStatusCode(),
        response_data: apiError.getResponseData(),
        request_data: apiError.getRequestData(),
        component_stack: apiError.getComponentStack(),
        info: errorInfo,
      });

      Sentry.captureException(apiError);
    });

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Fallback
          error={{
            message: this.errorMessage,
            info: this.apiError,
          }}
          style={this.props.fallbackStyle}
          onError={() => {
            this.resetError();
            this.props.onError?.(this.apiError as ApiError);
          }}
        />
      );
    }

    return this.props.children;
  }
}

export class NetworkErrorBoundary extends ErrorBoundary {
  private networkError: NetworkError | null = null;

  constructor(props: Props) {
    super(props);
  }

  componentDidCatch(error: Error): void {
    if (!(error instanceof NetworkError)) throw error;

    this.networkError = error;
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Fallback
          error={{ message: '네트워크 상태가 좋지 않습니다.', info: this.networkError }}
          onError={() => window.location.reload()}
        />
      );
    }

    return this.props.children;
  }
}

export class TimeoutErrorBoundary extends ErrorBoundary {
  private timeoutError: TimeoutError | null = null;
  constructor(props: Props) {
    super(props);
  }

  componentDidCatch(error: Error): void {
    if (!(error instanceof TimeoutError)) throw error;

    Sentry.withScope((scope) => {
      const timeoutError = error;

      scope.setLevel(this.props.level ?? 'error');

      scope.setFingerprint(['timeout-error', timeoutError.getTimeoutEndPoint() ?? 'unknown-endpoint']);

      scope.setContext('timeout-error', {
        error_type: 'timeout_error',
        timeout_duration: timeoutError.getTimeoutDuration(),
        timeout_end_point: timeoutError.getTimeoutEndPoint(),
        component_stack: timeoutError.getComponentStack(),
      });
      Sentry.captureException(error);
    });

    this.timeoutError = error;
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Fallback
          error={{ message: '요청이 너무 오래 걸립니다.', info: this.timeoutError }}
          style={this.props.fallbackStyle}
        />
      );
    }

    return this.props.children;
  }
}

export class RuntimeErrorBoundary extends ErrorBoundary {
  private runtimeError: RuntimeError | null = null;
  constructor(props: Props) {
    super(props);
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (!(error instanceof RuntimeError)) throw error;

    this.runtimeError = error;

    //sentry에 보고하지 않고 핸들링할 에러.
    if (this.runtimeError.isChunkError()) {
      window.location.reload();

      return;
    }

    Sentry.withScope((scope) => {
      const runtimeError = error;

      scope.setLevel(this.props.level ?? 'error');

      /** @todo 컴포넌트 스택 말고 도메인별로 에러 분류 */
      scope.setFingerprint(['runtime-error', runtimeError.getComponentStack().split('<')[0]]);

      scope.setContext('runtime-error', {
        error_type: 'runtime_error',
        cause: runtimeError.getErrorType(),
        component_stack: runtimeError.getComponentStack(),
        info: errorInfo,
      });

      if (runtimeError.getErrorType() === 'Unknown Runtime Error') {
        scope.setContext('custom-error', {
          error: runtimeError,
        });
      }

      Sentry.captureException(error);
    });

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Fallback
          error={{ message: '예상치 못한 오류가 발생했습니다.', info: this.runtimeError }}
          style={(this, this.props.fallbackStyle)}
          onError={() => {
            this.resetError();
            this.props.onError?.(this.runtimeError as RuntimeError);
          }}
        />
      );
    }

    return this.props.children;
  }
}
