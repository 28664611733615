import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';

import { FontSizeTypes, FontWeightTypes, color } from 'styles/assets';

interface Props {
  id: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  children?: React.ReactNode;
  bottomChildren?: React.ReactNode;
  fontSize?: FontSizeTypes;
  fontWeight?: FontWeightTypes;
  disabled?: boolean;
  name?: string;
}
const Checkbox = ({
  id,
  checked,
  onChange,
  label,
  children,
  bottomChildren,
  fontSize = 'base',
  fontWeight = 'regular',
  disabled = false,
  name,
}: Props) => {
  return (
    <CheckboxContainer>
      <CheckboxWrapper>
        <CheckBox checked={checked} htmlFor={id} disabled={disabled}>
          <HiddenCheckbox
            data-testid={`checkbox-${id}`}
            id={id}
            name={name}
            type="checkbox"
            onChange={onChange}
            checked={checked}
            disabled={disabled}
          />
          <Icon type="check" width={18} height={18} fill="white" />
        </CheckBox>

        {label && (
          <Label htmlFor={id} data-testid={`label-${id}`}>
            <StyledText text={label} fontSize={fontSize} fontWeight={fontWeight} />
          </Label>
        )}
        {children}
      </CheckboxWrapper>
      {bottomChildren}
    </CheckboxContainer>
  );
};

export default Checkbox;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBox = styled.label<{ checked: boolean; disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 2px solid ${({ disabled }) => (disabled ? color.greye4 : color.violet39)};
  background-color: ${({ disabled, checked }) =>
    disabled ? color.greye4 : checked ? `${color.violet39}` : `${color.white}`};
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const HiddenCheckbox = styled.input`
  display: none;
`;

const Label = styled.label`
  padding-left: 10px;
  cursor: pointer;
`;
