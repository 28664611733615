import { useState } from 'react';

import styled from 'styled-components';

import BulkMessageDetailTable from 'components/pages/chat/bulkMessage/sentCard/BulkMessageDetailTable';

import { Messaging } from 'types/bulkMessge';

interface Props {
  bulkMessage: Messaging;
}

const BulkMessageDetailSentCardMessageDetailData = ({ bulkMessage }: Props) => {
  const [page, setPage] = useState(1);
  const page_size = 10;
  const { success_users, fail_users } = bulkMessage;
  const totalUsers = [
    ...fail_users.map((user: any) => ({ ...user, is_success: false })),
    ...success_users.map((user: any) => ({ ...user, is_success: true })),
  ];
  const totalUsersWithIndex = totalUsers.map((user, index) => ({ ...user, index: index + 1 }));
  const currentPageUsers = totalUsersWithIndex.slice((page - 1) * page_size, page * page_size);
  const havePrevious = page > 1 || null;
  const haveNext = page * page_size < totalUsersWithIndex.length || null;
  const dataFormat = {
    count: totalUsersWithIndex.length,
    previous: havePrevious ? 'true' : null,
    next: haveNext ? 'true' : null,
    results: currentPageUsers,
  };

  return (
    <Container>
      <BulkMessageDetailTable
        page={page}
        page_size={page_size}
        paginationHandler={(e, page) => setPage(page)}
        listData={dataFormat}
        bulkMessage={bulkMessage}
      />
    </Container>
  );
};

export default BulkMessageDetailSentCardMessageDetailData;

const Container = styled.div``;
