import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { useGetBulkMessageList } from 'hooks/chat/bulkMessage/useBulkMessage';
import { useQueryParams } from 'hooks/common/useQueryParams';

import BulkMessageTable from 'containers/chat/bulkMessage/detail/BulkMessageTable';

import PageLoading from 'components/common/loading/PageLoading';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

const ChatBulkMessageListContainer = () => {
  const navigate = useNavigate();
  const query = useQueryParams();
  const page = Number(query.get('page')) || 1;
  const page_size = 10;

  const { data, isLoading, isFetching, isError, error } = useGetBulkMessageList({
    page,
    page_size,
  });

  const paginationHandler = (page: number) => navigate(`/chat/bulk-message?page=${page}`);

  if (isLoading) return <PageLoading />;

  if (isError || !data) return <div>{errToErrMsg(error?.response)}</div>;

  return (
    <Container>
      {isFetching && <PageLoading />}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledText fontSize="xl3" fontWeight="bold" text={t('chat.bulkMessage-list-title')} />
        </Grid>
        <Gap side={40} />
        <Grid item xs={12}>
          <BulkMessageTable
            page_size={page_size}
            page={page}
            paginationHandler={(e, page) => paginationHandler(page)}
            listData={data}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChatBulkMessageListContainer;

const Container = styled.div`
  padding: 24px;
`;
