import dayjs from 'dayjs';
import { getLanguage } from 'translate/i18next/i18next';

export const formatDateTimeByLocale = (date: string) => {
  if (getLanguage() === 'kr') return dayjs(date).format('YYYY년 MM월 DD일 HH:mm');

  return dayjs(date).format('YYYY-MM-DD HH:mm');
};

export const formatDateToDDMMYYYY = (date: string | undefined) => {
  if (!date) {
    return '';
  }

  const [year, month, day] = date.split('-');
  const monthLetters = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  const monthIndex = parseInt(month, 10) - 1;
  const monthLetter = monthLetters[monthIndex];
  const dayFormatted = day.split('T')[0];

  return `${dayFormatted}.${monthLetter}.${year}`;
};

export const formatDateWithDash = (date: string | undefined) => {
  if (!date) {
    return '';
  }

  const [year, month, day] = date.split('-');

  const monthIndex = parseInt(month, 10) < 10 ? '0' + parseInt(month, 10) : month;
  const dayFormatted = Number(day.split('T')[0]) < 10 ? '0' + Number(day.split('T')[0]) : day.split('T')[0];

  return `${year}-${monthIndex}-${dayFormatted}`;
};

export const formatDateWithDot = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}.${month}.${day}`;
};

export const formatDateAndTime = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0'); // 12-hour format, padding with '0'

  return `${year}.${month}.${day} ${formattedHours}:${minutes} ${ampm}`;
};

export const formatDateAndTimeWithAMPM = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}.${month}.${day} ${hours}:${minutes}`;
};

export const formatShortDateWithTime = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');

  return `${year}.${month}.${day} ${formattedHours}:${minutes} ${ampm}`;
};

export const formatShortDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}.${month}.${day}`;
};
