import { Fragment, RefObject, useState } from 'react';

import { Grid } from '@mui/material';
import { useDebounce, useIntersection } from '@nurihaus/react';
import { t } from 'i18next';
import { CampaignListProvider, useCampaignListContext } from 'providers/pages/chat/CampaignListContext';
import styled from 'styled-components';

import { useInfiniteCampaignListData } from 'hooks/campaign/queries/list';
import { useRealtimeUnreadCount } from 'hooks/chat/useRealtimeUnreadCount';

import MenuIcon from 'components/Icons/MenuIcon';
import ComponentLoading from 'components/common/loading/ComponentLoading';
import StyledText from 'components/common/text/StyledText';
import Block from 'components/layout/common/Block';
import UnreadCountBadge from 'components/pages/chat/UnreadCountBadge';
import CampaignListItem from 'components/pages/chat/list/CampaignListItem';

import { color } from 'styles/assets';

import { Campaign } from 'types/campaign/internal';

interface Props {
  selectCampaign: (campaignType: 'quest' | 'benefit', campaignData: Campaign) => void;
  selectedCampaign: {
    campaignType: 'quest' | 'benefit';
    campaignData: Campaign;
  } | null;
}

const TITLE_HEIGHT = 64;
const TAB_HEIGHT = 64;
const TAB_WIDTH = 150;
const SEARCH_HEIGHT = 90;

const ANIMATION_DURATION = '0.3s';

const ChatStaffCampaignList = ({ selectCampaign, selectedCampaign }: Props) => {
  return (
    <CampaignListProvider>
      <ChatStaffCampaignListContent selectCampaign={selectCampaign} selectedCampaign={selectedCampaign} />
    </CampaignListProvider>
  );
};

export default ChatStaffCampaignList;

const ToolBar = ({
  searchInput,
  handleSearchInput,
}: {
  searchInput: string;
  handleSearchInput: (searchInput: string) => void;
}) => {
  const { expanded, toggleExpanded, tab, handleTab } = useCampaignListContext();
  const { unreadCount } = useRealtimeUnreadCount({ category: '1,2' });

  const tabItems = [
    { id: 'quest', text: t('chat.campaignList-tab-quest') },
    { id: 'benefit', text: t('chat.campaignList-tab-benefit') },
  ] as const;

  return (
    <Fragment>
      <TitleSection $expanded={expanded}>
        <TitleWrapper>
          <StyledText text={t('chat.campaignList-title')} fontSize="lg" fontWeight="semiBold" />
          <UnreadCountBadge count={unreadCount} />
        </TitleWrapper>
        <FoldButton $expanded={expanded} onClick={toggleExpanded}>
          <MenuIcon type={expanded ? 'chevron_left' : 'chevron_right'} width={18} height={18} fill="violet39" />
        </FoldButton>
      </TitleSection>
      <Tabs $expanded={expanded}>
        {tabItems.map((item) => (
          <Tab key={item.id} onClick={() => handleTab(item.id)}>
            <StyledText
              text={item.text}
              fontSize="lg"
              fontWeight={tab === item.id ? 'bold' : 'medium'}
              fontColor={tab === item.id ? 'violet39' : 'grey71'}
            />
          </Tab>
        ))}
        <TabIndicator $activeTab={tab} />
      </Tabs>
      <SearchWrapper $expanded={expanded}>
        <SearchInput
          placeholder={t('chat.campaignList-search-placeholder')}
          value={searchInput}
          onChange={(e) => handleSearchInput(e.target.value)}
        />
      </SearchWrapper>
    </Fragment>
  );
};

ToolBar.displayName = 'ToolBar';

const ChatStaffCampaignListContent = ({ selectCampaign, selectedCampaign }: Props) => {
  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchInput = useDebounce(searchInput, 500);

  const { expanded, tab } = useCampaignListContext();

  const { data, fetchNextPage, hasNextPage, isCampaignListLoading, isFetching } = useInfiniteCampaignListData(
    tab === 'quest' ? 'earn' : 'spend',
    { query: debouncedSearchInput }
  );

  const campaignList = data?.pages?.flatMap((page) => page?.results) || [];

  const { intersectionTarget } = useIntersection(fetchNextPage, {}, hasNextPage ?? false);

  const handleCampaignClick = (campaignData: Campaign) => {
    selectCampaign(tab, campaignData);
  };

  const handleSearchInput = (searchInput: string) => {
    setSearchInput(searchInput);
  };

  return (
    <StyledGrid item $expanded={expanded}>
      <Block height="630px" padding="0">
        <Container>
          <ToolBar searchInput={searchInput} handleSearchInput={handleSearchInput} />
          <CampaignListSection $expanded={expanded} id="chat-campaign-list">
            {campaignList.map((campaign) => (
              <CampaignListItem
                key={campaign?.id}
                campaign={campaign}
                onClick={() => handleCampaignClick(campaign)}
                isSelected={
                  selectedCampaign?.campaignData.id === campaign?.id && selectedCampaign?.campaignType === tab
                }
              />
            ))}
            {(isCampaignListLoading || isFetching) && (
              <LoadingWrapper>
                <ComponentLoading size={32} justifyContent="center" />
              </LoadingWrapper>
            )}
            <IntersectionTarget ref={intersectionTarget as RefObject<HTMLDivElement>} />
          </CampaignListSection>
        </Container>
      </Block>
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)<{ $expanded: boolean }>`
  width: ${({ $expanded }) => (!$expanded ? '142px' : '404px')};
  transition: all ${ANIMATION_DURATION} ease-in-out;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TitleSection = styled.div`
  width: 100%;
  height: ${TITLE_HEIGHT}px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: relative;

  > div:first-child {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 1 : 0)};
    transition: opacity ${ANIMATION_DURATION} ease-in-out;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const FoldButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid ${color.greye4};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? '16px' : '50%')};
  transform: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 'none' : 'translateX(50%)')};
  transition: all ${ANIMATION_DURATION} ease-in-out;

  &:hover {
    background-color: ${color.violet39};
    border-color: ${color.violet39};

    svg {
      path {
        stroke: white;
      }
    }
  }
`;

const Tabs = styled.div`
  width: 100%;
  height: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? `${TAB_HEIGHT}px` : '0px')};
  border-bottom: 1px solid ${color.greye4};
  gap: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  opacity: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 1 : 0)};
  overflow: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 'visible' : 'hidden')};
  transition: height ${ANIMATION_DURATION} ease-in-out, opacity ${ANIMATION_DURATION} ease-in-out,
    overflow ${ANIMATION_DURATION} ease-in-out;
`;

const Tab = styled.button`
  width: ${TAB_WIDTH}px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  transition: color ${ANIMATION_DURATION} ease-in-out;
`;

const TabIndicator = styled.div`
  position: absolute;
  bottom: -1px;
  left: 50%;
  width: ${TAB_WIDTH}px;
  height: 2px;
  background-color: ${color.violet39};
  transform: translateX(
    ${({ $activeTab }: { $activeTab: 'quest' | 'benefit' }) =>
      $activeTab === 'quest' ? `${-(TAB_WIDTH + 10)}px` : `${10}px`}
  );
  transition: transform ${ANIMATION_DURATION} ease-in-out;
`;

const SearchWrapper = styled.div`
  width: 100%;
  height: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? `${SEARCH_HEIGHT}px` : '0px')};
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 1 : 0)};
  overflow: ${({ $expanded }: { $expanded: boolean }) => ($expanded ? 'visible' : 'hidden')};
  transition: height ${ANIMATION_DURATION} ease-in-out, opacity ${ANIMATION_DURATION} ease-in-out,
    overflow ${ANIMATION_DURATION} ease-in-out;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 50px;
  padding: 14px;
  background-color: ${color.greyfa};
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  ::placeholder {
    color: ${color.greya1};
  }
`;

const CampaignListSection = styled.div`
  width: 100%;
  height: ${({ $expanded }: { $expanded: boolean }) =>
    `calc(100% - ${$expanded ? TITLE_HEIGHT + TAB_HEIGHT + SEARCH_HEIGHT : TITLE_HEIGHT}px)`};
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${color.greye4};
  transition: height ${ANIMATION_DURATION} ease-in-out;

  > * {
    flex-shrink: 0;
    min-height: fit-content;
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IntersectionTarget = styled.div`
  background-color: transparent;
  height: 10px;
  margin-top: -10px;
`;
