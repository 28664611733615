import styled from 'styled-components';
import { Message } from 'talkplus-sdk';

import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

import { color } from 'styles/assets';

interface Props {
  message: Message | undefined;
}

const DeepLinkMessage = ({ message }: Props) => {
  const campaignInfo = message?.data || {};

  const { campaignType, id, image, title } = campaignInfo;

  return (
    <Container>
      <Upper>
        <Icon type="jelly_colored" />
        <Gap side={5} />
        <StyledText fontSize="base" fontWeight="bold" text="New Campaign" fontColor="white" textAlign="center" />
        <Gap side={8} />
        <Icon type="jelly_colored" />
      </Upper>
      <LowerPart href={`/detail/${campaignType}/${id}`} target="_blank" style={{ width: '100%' }}>
        <CoverWrapper>{image ? <Cover src={image} /> : <></>}</CoverWrapper>
        <TextContainer>
          <StyledText fontSize="base" fontWeight="medium" text={title ? title : message?.text} numberOfLines={3} />
        </TextContainer>
      </LowerPart>
    </Container>
  );
};

export default DeepLinkMessage;

const Container = styled.div`
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${color.greye4};
  margin-bottom: 20px;
`;

const Upper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: ${color.violet39};
`;

const LowerPart = styled.a`
  width: 100%;
  padding: 20px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CoverWrapper = styled.div`
  width: 74px;
  height: 74px;
  border-radius: 4px;
  min-width: 74px;
`;

const Cover = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 20px;
`;
