import { t } from 'i18next';
import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import Tooltip from 'components/common/Tooltip';
import StyledText from 'components/common/text/StyledText';

import { ColorTypes, color } from 'styles/assets';

interface Props {
  styleType: 'primary' | 'disabled';
  hoverText?: string;
}

const fontcolorMap: { [key: string]: ColorTypes } = {
  primary: 'yellowff',
  disabled: 'greyd4',
};
const iconMap: { [key: string]: 'paid' | 'paid_off' } = {
  primary: 'paid',
  disabled: 'paid_off',
};

const PaidBadge = ({ styleType, hoverText }: Props) => {
  const badgeContent = <PaidBadgeContent styleType={styleType} />;

  return (
    <PaidBadgeContainer styleType={styleType}>
      {hoverText ? (
        <Tooltip
          backgroundColor="white"
          borderColor="greyd4"
          tooltipDirection="bottom"
          width="auto"
          padding="7px 18px"
          hoverElement={badgeContent}
        >
          <HoverTextWrapper>
            <Icon type={iconMap[styleType]} width={24} height={24} />
            <StyledText
              text={hoverText}
              fontWeight="bold"
              fontColor={fontcolorMap[styleType]}
              fontSize="sm"
              textAlign="center"
            />
          </HoverTextWrapper>
        </Tooltip>
      ) : (
        badgeContent
      )}
    </PaidBadgeContainer>
  );
};

const PaidBadgeContent = ({ styleType }: { styleType: 'primary' | 'disabled' }) => (
  <HoverBadgeWrapper>
    <Icon type={iconMap[styleType]} width={24} height={24} />
    <StyledText
      text={t('campaign.detail-badge-paid')}
      fontWeight="bold"
      fontSize="sm"
      fontColor={fontcolorMap[styleType]}
    />
  </HoverBadgeWrapper>
);

export default PaidBadge;

const PaidBadgeContainer = styled.div<{ styleType: string }>`
  display: flex;
  padding: 0 15px;
  width: 88px;
  height: 32px;
  border: 1px solid ${color.greyd4};
  border-radius: 52px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const HoverBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HoverTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;
