export const avatarTheme: {
  [key: string]: {
    side: number;
    badge?: {
      badgeSide: number;
      badgeXPosition: number;
      badgeYPosition: number;
    };
  };
} = {
  xxl: {
    side: 200,
  },
  xl: {
    side: 124,
  },
  l: {
    side: 74,
  },
  m: {
    side: 58,
    badge: { badgeSide: 18, badgeXPosition: 0, badgeYPosition: 0 },
  },
  s: {
    side: 40,
    badge: { badgeSide: 18, badgeXPosition: 0, badgeYPosition: 0 },
  },
  xs: {
    side: 32,
  },
};
