import { Campaign } from 'types/campaign/internal';

export const getCampaignStatus = (campaign: Campaign) => {
  const dateNow = Date.now();
  const dateTo = new Date(campaign?.date_to).getTime();
  const dateFrom = new Date(campaign?.date_from).getTime();

  if (!campaign?.is_display) {
    return 'Display off';
  } else {
    if (campaign.is_complete) {
      return 'Complete';
    } else {
      if (campaign?.is_active) {
        if (dateTo && dateFrom) {
          if (dateFrom > dateNow) {
            return 'Scheduled';
          } else {
            if (dateFrom < dateNow && dateNow < dateTo) {
              return 'Active';
            } else if (dateNow > dateTo) {
              return 'Closed';
            }
          }
        } else {
          return 'Closed';
        }
      } else {
        return 'Closed';
      }
    }
  }
};
