import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { useGetBulkMessage } from 'hooks/chat/bulkMessage/useBulkMessage';

import ChatBulkMessageDetailContainer from 'containers/chat/bulkMessage/detail/DetailContainer';

import NormalButton from 'components/common/button/NormalButton';
import PageLoading from 'components/common/loading/PageLoading';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import BulkMessageDetailHeaderDetailButtons from 'components/pages/chat/bulkMessage/headerButtons/BulkMessageDetailHeaderDetailButtons';

import { MessagingStatus } from 'types/bulkMessge';

const ChatBulkMessageDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isLoading, isError, error } = useGetBulkMessage(Number(id));

  if (isLoading) return <PageLoading />;

  if (isError || !data) return <div>{errToErrMsg(error?.response)}</div>;

  const { status } = data;
  const isEditable = status === MessagingStatus.SCHEDULED;

  const HeaderLeft = () => (
    <NormalButton
      onPress={() => navigate('/chat/bulk-message')}
      width="160px"
      styleType="ghost"
      text={t('chat.detail-header-btn-back')}
      sizeType="medium"
    />
  );

  return (
    <DashboardLayout
      headerLeft={<HeaderLeft />}
      headerRight={isEditable && <BulkMessageDetailHeaderDetailButtons bulkMessage={data} />}
    >
      <ChatBulkMessageDetailContainer data={data} />
    </DashboardLayout>
  );
};

export default ChatBulkMessageDetailPage;
