import { ReactNode } from 'react';

import styled from 'styled-components';

interface Props {
  children: ReactNode;
  marginBottom?: number;
}

const SelectorWrapper = ({ children, marginBottom = 20 }: Props) => {
  return (
    <SelectorWrapperContainer marginBottom={marginBottom} data-testid="select-wrapper">
      {children}
    </SelectorWrapperContainer>
  );
};

export default SelectorWrapper;

const SelectorWrapperContainer = styled.div<{ marginBottom: number }>`
  background-color: white;
  border-radius: 7px;
  margin-bottom: ${(props) => props.marginBottom}px;
`;
