import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from 'translate/i18next/i18next';

import { getLanguageCodeByName } from 'utils/campaign/getLanguageCodeByName';

import { useGetTermsDetailBySlug } from 'hooks/terms/queries';

import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

import { selectOption } from 'features/redux/selectors/options';

import { Translates } from 'types/terms';

interface Props {
  isUGC: boolean;
  campaign: 'quest' | 'benefit';
  disabled?: boolean;
}

const TermsAndConditionsText = ({ campaign, isUGC, disabled }: Props) => {
  const fontColor = disabled ? 'grey71' : 'grey71';
  const { language } = useSelector(selectOption);
  const getFetchSlug = (category: 'quest' | 'benefit', isUGC: boolean) => {
    if (category === 'quest') {
      return isUGC ? 'terms_and_conditions_ugc' : 'terms_and_conditions_quest';
    }

    return isUGC ? 'terms_and_conditions_ugc' : 'terms_and_conditions_benefit';
  };

  const slug = getFetchSlug(campaign, isUGC);
  const { data } = useGetTermsDetailBySlug({ slug });

  if (!data) return null;

  const { translates, description } = data;
  const filteredTextData = translates.find((data: Translates) => data.branch === getLanguageCodeByName(language));
  const descriptionText = filteredTextData ? filteredTextData.text : description;
  const descriptionTextArr = descriptionText
    .replaceAll('\r\n', '')
    .split('- ')
    .filter((text: string) => text !== '');

  return (
    <TermsAndConditionsTextContainer>
      <TitleWrapper>
        <StyledText text={`${t('guide.terms-title')}`} fontColor={fontColor} fontSize="sm" fontWeight="semiBold" />
      </TitleWrapper>
      <Gap side={10} />
      <ListTextContainer>
        {descriptionTextArr.map((text: string, index: number) => {
          return (
            <TermsAndConditionsDescTextContainer key={index}>
              <StyledText text={'•'} fontSize="xs" fontWeight="regular" fontColor={fontColor} />
              <StyledText text={`${text}`} fontSize="xs" fontWeight="regular" fontColor={fontColor} />
            </TermsAndConditionsDescTextContainer>
          );
        })}
      </ListTextContainer>
    </TermsAndConditionsTextContainer>
  );
};

export default TermsAndConditionsText;

const TitleWrapper = styled.div`
  margin-left: 10px;
`;

const TermsAndConditionsTextContainer = styled.div``;

const ListTextContainer = styled.div`
  row-gap: 4px;
`;

const TermsAndConditionsDescTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 4px;
`;
