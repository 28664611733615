import { ReactNode } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ListItemButton } from '@mui/material';
import styled from 'styled-components';

import ComponentLoading from 'components/common/loading/ComponentLoading';

interface Props {
  loadNext?: () => void;
  isLoadNextLoading?: boolean;
  children: ReactNode;
}

const SelectorDropDownScroll = ({ loadNext, isLoadNextLoading = false, children }: Props) => {
  return (
    <SelectorDropDownScrollContainer>
      {children}

      {loadNext && isLoadNextLoading ? (
        <LoadingWrapper>
          <ComponentLoading size={20} justifyContent="center" />
        </LoadingWrapper>
      ) : (
        <ListItemButton sx={{ justifyContent: 'center' }} onClick={loadNext}>
          <ExpandMoreIcon />
        </ListItemButton>
      )}
    </SelectorDropDownScrollContainer>
  );
};

export default SelectorDropDownScroll;

const SelectorDropDownScrollContainer = styled.div`
  position: relative;
  max-height: 220px;
  overflow: auto;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
