import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';
import { Column } from 'components/layout/common/InfoCard';

interface Props {
  isLoading?: boolean;
  msg?: string;
}

const PageLoading = ({ isLoading = true, msg }: Props) => {
  if (!isLoading) return null;

  return (
    <LoadingContainer aria-label="loading">
      <LoadingBackground />
      <Column gap="16px">
        <LoadingIndicatorRing>
          <LoadingIndicatorSingleRing />
          <LoadingIndicatorSingleRing />
          <LoadingIndicatorSingleRing />
          <LoadingIndicatorSingleRing />
        </LoadingIndicatorRing>
        {msg && (
          <TextBlock>
            <StyledText text={msg} fontColor="white" />
          </TextBlock>
        )}
      </Column>
    </LoadingContainer>
  );
};

export default PageLoading;

const LoadingContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1202;
`;
const LoadingBackground = styled.div`
  background-color: #000000;
  position: absolute;
  opacity: 0.6;
  width: 100%;
  height: 100%;
`;
const LoadingIndicatorRing = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;
const LoadingIndicatorSingleRing = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
  :nth-child(1) {
    animation-delay: -0.45s;
  }
  :nth-child(2) {
    animation-delay: -0.3s;
  }
  :nth-child(3) {
    animation-delay: -0.15s;
  }
  :nth-child(4) {
    animation-delay: -0s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const TextBlock = styled.div`
  position: relative;
`;
