declare const tag: unique symbol;

declare type InvariantProperty<Type> = (arg: Type) => Type;

declare type InvariantSignature<Type> = {
  readonly [tag]: InvariantProperty<Type>;
};

type InvariantOf<Type> = Type & InvariantSignature<Type>;

export const invariantOf = <Type>(value: Type): InvariantOf<Type> => {
  return value as InvariantOf<Type>;
};

export const getTypedKeys = <T>(arg: InvariantOf<T>): (keyof T)[] => {
  return Object.keys(arg) as (keyof T)[];
};
