import NormalButton from 'components/common/button/NormalButton';
import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

interface Props {
  opened: boolean;
  close: () => void;
  text: string;
  buttonText: string;
}

const ModalWithOneButton = ({ opened, close, text, buttonText }: Props) => {
  if (!opened) return <></>;

  return (
    <ModalForm backgroundOnPress={close} width="343px" padding="30px 20px">
      <StyledText fontSize="lg" fontWeight="bold" text={text} />
      <Gap side={20} />
      <NormalButton text={buttonText} sizeType="small" onPress={close} width="100%" />
    </ModalForm>
  );
};

export default ModalWithOneButton;
