import { useContext, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { Channel } from 'talkplus-sdk';
import { t } from 'translate/i18next/i18next';

import StyledText from 'components/common/text/StyledText';
import Block from 'components/layout/common/Block';
import Gap from 'components/layout/common/Gap';
import ChatStaffCampaignList from 'components/pages/chat/list/staff/ChatStaffCampaignList';
import ChatStaffChannelList from 'components/pages/chat/list/staff/ChatStaffChannelList';
import TalkPlusChatRoom from 'components/pages/chat/room/talkPlus/TalkPlusChatRoom';

import { AppContext } from 'features/redux/context';
import { selectAccount } from 'features/redux/selectors/accounts';

import { User } from 'types/account/internal';
import { Campaign } from 'types/campaign/internal';

const ChatStaffContainer = () => {
  const { talkPlus } = useContext(AppContext);

  const { userInfo } = useSelector(selectAccount);

  const [loggedInAccount, setLoggedInAccount] = useState<{
    id: string;
    username: string;
    profileImageUrl: string;
  } | null>(null);

  const [focusedChannel, setFocusedChannel] = useState<string | null>(null);

  const [selectedCampaign, setSelectedCampaign] = useState<{
    campaignType: 'quest' | 'benefit';
    campaignData: Campaign;
  } | null>(null);

  const handleChannelFocus = (channelId: Channel['id'] | null) => setFocusedChannel(channelId);

  const handleSelectCampaign = (campaignType: 'quest' | 'benefit', campaignData: Campaign) => {
    handleChannelFocus(null);
    loginTalkPlus(campaignData.user).then(() => {
      setSelectedCampaign({ campaignType, campaignData });
    });
  };

  const loginTalkPlus = async (user: User) => {
    await talkPlus.logout();

    const res = await talkPlus.loginAnonymous({
      userId: user.id.toString(),
      username: user.nickname,
      profileImageUrl: user.profile,
      data: {
        nuriUsername: user.username,
      },
    });

    setLoggedInAccount(res.user);
  };

  useEffect(() => {
    if (!talkPlus.isLoggedIn()) {
      loginTalkPlus(userInfo.user);
    }
  }, []);

  if (!loggedInAccount) return null;

  return (
    <>
      <Grid>
        <StyledText text={t('chat.staff-warning-title')} fontColor="reddf" fontWeight="bold" fontSize="xl" />
        <StyledText text={t('chat.staff-warning-desc1')} fontColor="reddf" fontWeight="bold" fontSize="base" />
        <StyledText text={t('chat.staff-warning-desc2')} fontColor="reddf" fontWeight="bold" fontSize="base" />
        <StyledText text={t('chat.staff-warning-desc3')} fontColor="reddf" fontWeight="bold" fontSize="base" />
        <StyledText text={t('chat.staff-warning-desc4')} fontColor="reddf" fontWeight="bold" fontSize="base" />
      </Grid>
      <Gap side={20} />
      <Grid
        container
        spacing={3}
        sx={{
          flexWrap: 'nowrap',
          overflowX: 'auto',
        }}
      >
        <ChatStaffCampaignList selectCampaign={handleSelectCampaign} selectedCampaign={selectedCampaign} />

        <ChatStaffChannelList
          key={selectedCampaign?.campaignData.id}
          focusChannel={handleChannelFocus}
          focusedChannel={focusedChannel}
          selectedCampaign={selectedCampaign}
        />

        {focusedChannel && (
          <Grid item sx={{ flex: '0 0 auto', width: { xs: '404px', md: '404px', lg: '404px' } }}>
            <Block height="630px" padding="0">
              <TalkPlusChatRoom channelId={focusedChannel} key={focusedChannel} focusChannel={handleChannelFocus} />
            </Block>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ChatStaffContainer;
