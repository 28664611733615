import { useState } from 'react';

import { useOverlay } from '@nurihaus/use-overlay';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { Channel, MessageTypeEnum } from 'talkplus-sdk';

import { chatKeys, useChat } from 'hooks/chat/queries';

import NormalButton from 'components/common/button/NormalButton';
import ModalForm from 'components/common/modal/ModalForm';
import ModalWithOneButton from 'components/common/modal/ModalWIthOneButton';
import StyledText from 'components/common/text/StyledText';
import { Column, Row } from 'components/layout/common/InfoCard';

import { color } from 'styles/assets';

interface Props {
  closeModal: () => void;
  channel: Channel;
}

const NewPinMsgModal = ({ closeModal, channel }: Props) => {
  const { useSendMessage, useGetNotice, useUpdateChannel, useGetChannel } = useChat();
  const { mutate: send } = useSendMessage();
  const { mutate: updateChannel } = useUpdateChannel();
  const { data: channelData } = useGetChannel({ channelId: channel.id });
  const { data } = useGetNotice({ channelId: channel.id });
  const [pinInput, setPinInput] = useState(data?.text ?? '');
  const successModalOverlay = useOverlay();
  const queryClient = useQueryClient();

  const sendPinMessage = () => {
    send(
      {
        channelId: channel.id,
        type: MessageTypeEnum['Custom'],
        text: pinInput,
      },
      {
        onSuccess(data) {
          updateChannel(
            {
              channelId: channel.id,
              name: channelData?.channel.name || '',
              imageUrl: channelData?.channel.imageUrl || '',
              data: {
                pinnedMsgId: data.message.id,
              },
            },
            {
              onSuccess() {
                queryClient.invalidateQueries(chatKeys.notice(channel.id));
                /**@todo 낙관적 업데이트로 메세지 렌더링 */
                queryClient.invalidateQueries(chatKeys.messages(channel.id));
                handleSuccessModal();
                closeModal();
              },
            }
          );
        },
      }
    );
  };

  const handleSuccessModal = () => {
    successModalOverlay.open(({ isOpen, close }) => (
      <ModalWithOneButton opened={isOpen} close={close} text="Notification Saved" buttonText="OK" />
    ));
  };

  return (
    <>
      <ModalForm width="544px" backgroundOnPress={closeModal}>
        <Column gap="20px" alignItems="flex-start">
          <StyledText fontSize="xl2" fontWeight="extraBold" text="Notification" />
          <InputWrapper>
            <PinInput
              value={pinInput}
              onChange={(e) => setPinInput(e.target.value)}
              maxLength={700}
              placeholder="Please write down the notification."
            />
            <TextCountWrapper>
              <StyledText fontSize="sm" fontWeight="semiBold" text={`${pinInput.length}/700`} fontColor="greya1" />
            </TextCountWrapper>
          </InputWrapper>
          <Row justifyContent="flex-end" gap="20px">
            <NormalButton styleType="ghost" sizeType="medium" text="Cancel" width="160px" onPress={closeModal} />
            <NormalButton text="Save" sizeType="medium" width="160px" onPress={sendPinMessage} />
          </Row>
        </Column>
      </ModalForm>
    </>
  );
};

export default NewPinMsgModal;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const PinInput = styled.textarea`
  width: 100%;
  height: 261px;
  border: 1px solid ${color.greyd4};
  border-radius: 4px;
  padding: 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  resize: none;
`;

const TextCountWrapper = styled.div`
  position: absolute;
  bottom: 14px;
  right: 14px;
`;
