import { ReactComponent as Apple } from 'assets/SVG/Social/AppleColored.svg';
import { ReactComponent as FB } from 'assets/SVG/Social/FacebookColor.svg';
import { ReactComponent as Google } from 'assets/SVG/Social/GoogleColor.svg';
import { ReactComponent as Instagram } from 'assets/SVG/Social/Instagram.svg';
import { ReactComponent as MainStar } from 'assets/SVG/Social/MainStar.svg';
import { ReactComponent as Tiktok } from 'assets/SVG/Social/TikTok.svg';
import { ReactComponent as Twitch } from 'assets/SVG/Social/Twitch.svg';
import { ReactComponent as Youtube } from 'assets/SVG/Social/YouTube.svg';
import styled from 'styled-components';

import { ColorTypes, color } from 'styles/assets';

interface props {
  type:
    | 'instagram'
    | 'instagramColored'
    | 'youtube'
    | 'youtubeColored'
    | 'twitch'
    | 'tiktok'
    | 'tiktokColored'
    | 'googleColored'
    | 'facebookColored'
    | 'appleColored'
    | 'MainStar';
  width?: number;
  height?: number;
  fill?: ColorTypes;
}

export default function SocialIcon({ type, width = 24, height = 24, fill = 'black' }: props) {
  switch (type) {
    case 'instagram':
      return (
        <ParentContainer width={width} height={height} fill={fill}>
          <Instagram width={width} height={height} title="instagram-icon" />
        </ParentContainer>
      );
    case 'instagramColored':
      return (
        <ImageContainer
          src={require('assets/SVG/Social/InstagramColored.png')}
          width={width}
          height={height}
          title="instagram-icon"
        />
      );
    case 'youtube':
      return (
        <ParentContainer width={width} height={height} fill={fill}>
          <Youtube width={width} height={height} title="youtube-icon" />
        </ParentContainer>
      );
    case 'youtubeColored':
      return (
        <ImageContainer
          src={require('assets/SVG/Social/YoutubeColored.png')}
          width={width}
          height={height}
          title="youtube-icon"
        />
      );
    case 'twitch':
      return <Twitch width={width} height={height} title="twitch-icon" />;
    case 'tiktok':
      return (
        <ParentContainer width={width} height={height} fill={fill}>
          <Tiktok width={width} height={height} fill={fill} title="tiktok-icon" />
        </ParentContainer>
      );
    case 'tiktokColored':
      return (
        <ImageContainer
          src={require('assets/SVG/Social/TiktokColored.png')}
          width={width}
          height={height}
          title="tiktok-icon"
        />
      );
    case 'googleColored':
      return <Google width={width} height={height} title="googleColored-icon" />;
    case 'facebookColored':
      return <FB width={width} height={height} title="facebookColored-icon" />;
    case 'appleColored':
      return <Apple width={width} height={height} title="appleColored-icon" />;
    case 'MainStar':
      return <MainStar width={width} height={height} title="mainStar-icon" />;
  }
}

const ParentContainer = styled.div<{ fill: ColorTypes; width: number; height: number }>`
  color: ${({ fill }) => (fill ? color[fill] : color.black)};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const ImageContainer = styled.img<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
