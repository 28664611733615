import { ReactComponent as DefaultAvatar } from 'assets/SVG/etc/DefaultAvatar.svg';
import styled from 'styled-components';

import { UserType } from 'utils/account/accessLevel';

import BadgeIcon from 'components/Icons/BadgeIcon';
import ImageLoader from 'components/common/ImageLoader';
import ComponentLoading from 'components/common/loading/ComponentLoading';

import { color } from 'styles/assets';
import { avatarTheme } from 'styles/assets/elements/AvatarTheme';

interface Props {
  avatarSize?: keyof typeof avatarTheme;
  src?: string;
  badgeType?: UserType;
}

const Avatar = ({ avatarSize = 'm', src = '', badgeType }: Props) => {
  const { side, badge } = avatarTheme[avatarSize];

  return (
    <AvatarContainer side={side}>
      {src ? (
        <ImageContainer side={side}>
          <ImageLoader
            src={src}
            alt="avatar"
            width="100%"
            height="100%"
            loadingComponent={<ComponentLoading size={side / 2} justifyContent="center" />}
          />
        </ImageContainer>
      ) : (
        <DefaultAvatar width={side} height={side} />
      )}
      {badge && badgeType && (
        <BadgeContainer
          badgeSide={badge.badgeSide}
          badgeXPosition={badge.badgeXPosition}
          badgeYPosition={badge.badgeYPosition}
        >
          <BadgeIcon userType={badgeType} width={badge.badgeSide} height={badge.badgeSide} />
        </BadgeContainer>
      )}
    </AvatarContainer>
  );
};

export default Avatar;

const AvatarContainer = styled.div<{ side: number }>`
  width: ${(props) => props.side}px;
  height: ${(props) => props.side}px;
  position: relative;
`;

const ImageContainer = styled.div<{ side: number }>`
  width: ${(props) => props.side}px;
  height: ${(props) => props.side}px;
  border-radius: ${(props) => props.side / 2}px;
  border-width: 1px;
  border-color: ${color.greye4};
  overflow: hidden;
`;

const BadgeContainer = styled.div<{ badgeSide: number; badgeXPosition: number; badgeYPosition: number }>`
  position: absolute;
  width: ${(props) => props.badgeSide}px;
  height: ${(props) => props.badgeSide}px;
  bottom: ${(props) => props.badgeYPosition}px;
  right: ${(props) => props.badgeXPosition}px;
`;
