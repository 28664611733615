export const formatUserFollowerCount = (followerCount: number, fixedNum?: number) => {
  const k = 1000;
  const m = 1000000;
  const b = 1000000000;

  if (followerCount < k) {
    return `${followerCount}`;
  } else if (followerCount < m) {
    return `${(followerCount / k).toFixed(fixedNum ? fixedNum : 0)}K`;
  } else if (followerCount < b) {
    return `${(followerCount / m).toFixed(fixedNum ? fixedNum : 0)}M`;
  } else {
    return `${(followerCount / b).toFixed(fixedNum ? fixedNum : 0)}B`;
  }
};
