import { useContext, useEffect, useState } from 'react';

import { ChannelStatusEnum, MessageDataTypeEnum } from 'constants/chatConstants';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Channel } from 'talkplus-sdk';

import { capitalizeFirstLetter } from 'utils/common/capitalizeFirstLetter';
import { getTimeFromNow } from 'utils/common/day';
import { getKeyByValue } from 'utils/common/getKeyByValue';

import { useChat } from 'hooks/chat/queries';
import { useQueryParams } from 'hooks/common/useQueryParams';

import Icon from 'components/Icons/Icon';
import Avatar from 'components/common/Avatar';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import { Row } from 'components/layout/common/InfoCard';

import { AppContext } from 'features/redux/context';
import { selectAddress } from 'features/redux/selectors/address';

import { color } from 'styles/assets';

import { ChannelListTabType } from 'types/chat/internal';

interface Props {
  id: string;
  handleClickChatSingList: () => void;
  isFocused: boolean;
  focussed: string | null;
  channel: Channel;
}

const TalkPlusSingleList = ({ id, handleClickChatSingList, isFocused, focussed, channel }: Props) => {
  const { talkPlus } = useContext(AppContext);
  const { country } = useSelector(selectAddress);
  const query = useQueryParams();
  const { useMarkAsRead } = useChat();
  const { mutate: markAsRead } = useMarkAsRead();
  const [unreadCount, setUnreadCount] = useState(channel?.unreadCount ?? 0);

  const branchCountry = country.find((c) => c.id === Number(channel?.data?.['campaignBranch']));
  const tab = query.get('tab') as ChannelListTabType;

  const getLastMessageDisplay = () => {
    switch (channel?.lastMessage?.data?.['type'] as MessageDataTypeEnum) {
      case '1': {
        return 'request accepted';
      }
      case '2': {
        return 'Collaboration closed';
      }
      case '3': {
        return 'Collaboration dropped';
      }
      case '6': {
        return channel?.lastMessage?.data?.['message'];
      }
      case '7': {
        return 'Started a Chat!';
      }
      case '9': {
        return 'New Campaign';
      }
      default: {
        return channel?.lastMessage?.text ?? '...';
      }
    }
  };

  const [lastMessage, setLastMessage] = useState(getLastMessageDisplay());

  const getStatusColor = (status: ChannelStatusEnum) => {
    switch (status) {
      case '1':
        return 'violet39';
      case '2':
        return 'grey71';
      case '3':
        return 'black';
      default:
        return 'black';
    }
  };

  useEffect(() => {
    setLastMessage(getLastMessageDisplay());
    if (!isFocused) {
      setUnreadCount(channel?.unreadCount ?? 0);
    }
  }, [channel]); //첫 진입시 lastmessage & unreadCount 설정

  useEffect(() => {
    const changeChannelSingleList = (e: any) => {
      if (e.type === 'message' && e.channel?.id === id && e.type !== 'memberLeft') {
        setLastMessage(e.channel?.lastMessage?.text);
        if (!isFocused) {
          setUnreadCount(e.channel?.unreadCount);
        }
      } else if (e.type === 'memberLeft' && e.channel?.id === id) {
        setLastMessage(e.channel?.lastMessage?.data?.['message']);
      }
    };

    talkPlus.on('event', changeChannelSingleList);
    window.addEventListener('chatMessageSent', (e) => {
      if ((e as CustomEvent).detail.channelId === id) setLastMessage((e as CustomEvent).detail.text);
    });

    return () => {
      talkPlus.off('event', changeChannelSingleList);
      window.removeEventListener('chatMessageSent', (e) => setLastMessage((e as CustomEvent).detail.text));
    };
  }, [focussed, id, isFocused, talkPlus]);

  const profileSrc =
    channel?.members?.find((member: any) => member.id !== (talkPlus as any).userId)?.profileImageUrl || '';

  const profileName =
    channel?.members?.find((member: any) => member.id !== (talkPlus as any).userId)?.username || 'Unknown';

  return (
    <Container
      onClick={() => {
        markAsRead(
          { channelId: channel?.id as string },
          {
            onSuccess() {
              setUnreadCount(0);
            },
          }
        );
        handleClickChatSingList();
      }}
    >
      {(tab === 'campaign' || tab === 'private') && <Avatar avatarSize="m" src={profileSrc} />}
      {tab === 'group' && <Avatar avatarSize="m" src={channel?.imageUrl} />}
      <TextSection>
        <Row>
          {tab === 'group' && (
            <ProjectWrapper>
              <IconWrapper>
                <Icon type="users" width={16} height={16} fill="white" />
              </IconWrapper>
              <StyledText fontSize="sm" fontWeight="semiBold" text={channel?.name} numberOfLines={1} />
            </ProjectWrapper>
          )}
          {tab === 'private' && (
            <StyledText
              fontSize="sm"
              fontWeight="semiBold"
              text={'Private Chat'}
              fontColor="green"
              whiteSpace="nowrap"
            />
          )}
          {channel?.category && tab === 'campaign' && (
            <StyledText
              fontSize="sm"
              fontWeight="semiBold"
              text={capitalizeFirstLetter(
                getKeyByValue(ChannelStatusEnum, channel?.data?.['campaignStatus'] as ChannelStatusEnum)
              )}
              fontColor={getStatusColor(channel?.category as ChannelStatusEnum)}
              whiteSpace="nowrap"
            />
          )}
          <StyledText
            fontSize="sm"
            fontWeight="medium"
            text={getTimeFromNow(channel?.lastMessage ? channel?.lastMessage.createdAt : channel?.createdAt)}
            fontColor="greya1"
            whiteSpace="nowrap"
          />
        </Row>
        {(tab === 'campaign' || tab === 'private') && (
          <StyledText fontSize="base" fontWeight="bold" text={profileName} numberOfLines={1} wordBreak="break-word" />
        )}
        <StyledText fontSize="base" fontWeight="regular" text={lastMessage} numberOfLines={1} wordBreak="break-word" />
        <Gap side={6} />
        {tab === 'campaign' && (
          <StyledText
            fontSize="sm"
            fontWeight="medium"
            text={`${branchCountry?.emoji} ${branchCountry?.name}`}
            numberOfLines={1}
            wordBreak="break-word"
          />
        )}
        {unreadCount > 0 ? (
          <UnreadWrapper>
            <StyledText
              fontSize="xs"
              fontWeight="medium"
              text={unreadCount > 99 ? '+99' : unreadCount.toString()}
              fontColor="white"
            />
          </UnreadWrapper>
        ) : null}
      </TextSection>
    </Container>
  );
};

export default TalkPlusSingleList;

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.2s ease-in-out;
  padding: 14px 20px;
  background-color: white;
  border-bottom: 1px solid ${color.greye4};
  gap: 12px;
  :hover {
    background-color: ${color.greyf4};
  }
  min-height: 118px;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
`;

const ProjectWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
`;

const IconWrapper = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${color.violet39};
  margin-right: 5px;
`;

const UnreadWrapper = styled.div`
  padding: 2px 7px;
  border-radius: 40px;
  background-color: ${color.reddf};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 25px;
`;
