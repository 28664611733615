import { GridColumns } from '@mui/x-data-grid';
import { invisibleOption } from 'constants/common/dataOptions';
import { t } from 'i18next';

import { formatDateAndTimeWithAMPM } from 'utils/common/dateFormat';

import { bulkMessageTargetOptions } from 'hooks/chat/bulkMessage/useBulkMessageModal';

import { Messaging, MessagingUserStatus } from 'types/bulkMessge';

interface Props {
  bulkMessage: Messaging;
}

export const bulkMessageUserExportColumns: ({ bulkMessage }: Props) => GridColumns<any> = ({ bulkMessage }) => [
  {
    field: 'id',
    headerName: t('chat.bulkMessage-detail-user-list-no'),
    ...invisibleOption,
    valueGetter: (params) => params.row.index,
  },
  {
    field: 'username',
    headerName: t('chat.bulkMessage-detail-user-list-ID'),
    ...invisibleOption,
    valueGetter: (params) => params.row.username,
  },
  {
    field: 'nickname',
    headerName: t('chat.bulkMessage-detail-user-list-nickname'),
    ...invisibleOption,
    valueGetter: (params) => params.row.nickname,
  },
  {
    field: 'email',
    headerName: t('chat.bulkMessage-detail-user-list-email'),
    ...invisibleOption,
    valueGetter: (params) => params.row.email,
  },
  {
    field: 'bulkMessageID',
    headerName: t('chat.bulkMessage-detail-user-list-bulkID'),
    ...invisibleOption,
    valueGetter: () => bulkMessage.id,
  },
  {
    field: 'campaignTitle',
    headerName: t('chat.bulkMessage-detail-user-list-campaignTitle'),
    ...invisibleOption,
    valueGetter: () => bulkMessage.content_object.title,
  },
  {
    field: 'status',
    headerName: t('chat.bulkMessage-detail-user-list-status'),
    ...invisibleOption,
    valueGetter: () => {
      const options = bulkMessageTargetOptions();
      const option = options.filter((option) => option.value === MessagingUserStatus[bulkMessage.user_status])?.[0];

      return option.label;
    },
  },
  {
    field: 'send',
    headerName: t('chat.bulkMessage-detail-user-list-send'),
    ...invisibleOption,
    valueGetter: (params) => {
      const isFail = !params.row?.is_success;
      const haveRetry = false;
      if (isFail) {
        if (haveRetry) return `Fail (Retry at ${formatDateAndTimeWithAMPM(new Date().toISOString())})`;

        return 'Fail';
      }

      return 'Success';
    },
  },
  {
    field: 'sendTime',
    headerName: t('chat.bulkMessage-detail-user-list-sendTime'),
    ...invisibleOption,
    valueGetter: () => formatDateAndTimeWithAMPM(bulkMessage.schedule),
  },
];
