import { t } from 'i18next';
import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import Tooltip from 'components/common/Tooltip';
import StyledText from 'components/common/text/StyledText';

import { color } from 'styles/assets';

interface Props {
  type: 'total' | 'success' | 'fail';
  count: number;
}

const SendStatusLabel = ({ type, count }: Props) => {
  const theme = {
    total: { icon: 'user_add', text: '| ' + t('chat.bulkMessage-detail-label-user-total') },
    success: { icon: 'user_check', text: '| ' + t('chat.bulkMessage-detail-label-user-success') },
    fail: { icon: 'user_remove', text: '| ' + t('chat.bulkMessage-detail-label-user-fail') },
  } as const;

  return (
    <Container>
      <IconWrapper>
        <Icon type={theme[type].icon} fill="white" />
      </IconWrapper>
      <Data>
        <Title>
          <StyledText fontSize="base" fontWeight="medium" text={theme[type].text} />
          {type === 'fail' && (
            <FailNoticeWrapper>
              <Tooltip
                width="280px"
                tooltipDirection="bottom"
                hoverElement={<Icon type="info_circle" fill="violet39" />}
                padding="11px"
              >
                <StyledText
                  text={t('chat.bulkMessage-list-title-fail-notice')}
                  fontSize="sm"
                  fontWeight="regular"
                  fontColor="white"
                  textAlign="center"
                />
              </Tooltip>
            </FailNoticeWrapper>
          )}
        </Title>
        <StyledText fontSize="xl2" fontWeight="bold" text={String(count)} />
      </Data>
    </Container>
  );
};

export default SendStatusLabel;

const Container = styled.div`
  padding: 20px;
  width: 253px;
  height: 96px;
  background-color: ${color.greyfa};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const IconWrapper = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: ${color.violet4c};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 24px;
`;

const FailNoticeWrapper = styled.div`
  position: relative;
`;
