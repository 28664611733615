import { ReactNode, createContext, useContext, useState } from 'react';

interface CampaignListContextType {
  expanded: boolean;
  toggleExpanded: () => void;
  closeExpanded: () => void;
  tab: 'quest' | 'benefit';
  handleTab: (tab: 'quest' | 'benefit') => void;
}

export const CampaignListContext = createContext<CampaignListContextType | null>(null);

export const CampaignListProvider = ({ children }: { children: ReactNode }) => {
  const [expanded, setExpanded] = useState(true);
  const [tab, setTab] = useState<'quest' | 'benefit'>('quest');

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const closeExpanded = () => setExpanded(false);

  const handleTab = (tab: 'quest' | 'benefit') => {
    setTab(tab);
  };

  return (
    <CampaignListContext.Provider value={{ expanded, toggleExpanded, closeExpanded, tab, handleTab }}>
      {children}
    </CampaignListContext.Provider>
  );
};

export const useCampaignListContext = () => {
  const context = useContext(CampaignListContext);
  if (!context) {
    throw new Error('useCampaignListContext must be used within CampaignListProvider');
  }

  return context;
};
