import { t } from 'i18next';
import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';

import { color } from 'styles/assets';

import { Campaign } from 'types/campaign/internal';

interface Props {
  campaignInfo: Campaign['campaign_info'];
  reward?: {
    point: number;
    reward_price?: number;
    currency?: {
      id: number;
      name: string;
      currency_symbol: string;
    };
  };
}

interface SingleContentProps {
  contents?: string;
  title: string;
  iconType: any;
}

const DetailedContents = ({ campaignInfo, reward }: Props) => {
  const { creator_criteria, content_type, product_detail, mission, mission_guide, brand_info, additional } =
    campaignInfo || {};

  const contents: SingleContentProps[] = [
    {
      title: t('campaign.detail-appView-criteria'),
      contents: creator_criteria,
      iconType: 'name_card',
    },
    {
      title: t('campaign.detail-appView-reward'),
      contents: reward
        ? `${reward.point} Jelly${
            reward.reward_price ? `\n${reward.reward_price.toLocaleString()} ${reward.currency?.name} Paid` : ''
          }`
        : '',
      iconType: 'benefit',
    },
    {
      title: t('campaign.detail-appView-type'),
      contents: content_type,
      iconType: 'create',
    },
    {
      title: t('campaign.detail-appView-detail'),
      contents: product_detail,
      iconType: 'note',
    },
    {
      title: t('campaign.detail-appView-mission'),
      contents: mission,
      iconType: 'check_circle',
    },
    {
      title: t('campaign.detail-appView-guide'),
      contents: mission_guide,
      iconType: 'guide',
    },
    {
      title: t('campaign.detail-appView-additional'),
      contents: additional,
      iconType: 'additional',
    },
  ];

  return (
    <Container>
      {brand_info && (
        <ContentBlock>
          <Header>
            <Icon type="info_circle" />
            <StyledText fontSize="sm" fontWeight="semiBold" text={t('campaign.detail-appView-brandInfo')} />
          </Header>
          <Contents>
            <StyledText fontSize="sm" fontWeight="regular" text={brand_info || ''} />
          </Contents>
        </ContentBlock>
      )}
      {contents.map((content) => (
        <SingleContent key={content.title} {...content} />
      ))}
    </Container>
  );
};

export default DetailedContents;

const SingleContent = ({ contents, title, iconType }: SingleContentProps) => {
  if (!contents) return null;

  return (
    <ContentBlock>
      <Header>
        <Icon type={iconType} />
        <StyledText fontSize="sm" fontWeight="semiBold" text={title} />
      </Header>
      <Contents>
        <StyledText fontSize="sm" fontWeight="regular" text={contents} />
      </Contents>
    </ContentBlock>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ContentBlock = styled.div`
  border: 1px solid ${color.greye4};
  border-radius: 6px;
`;

const Header = styled.div<{ noBack?: boolean }>`
  width: 100%;
  padding: 14px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  border-bottom-width: 1px;
  border-bottom-color: ${color.greye4};
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  background-color: ${({ noBack }) => (noBack ? 'transparent' : color.greyfa)};
`;

const Contents = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
