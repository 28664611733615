import { ReactNode } from 'react';

import styled from 'styled-components';

import { color } from 'styles/assets';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  onClick: () => void;
}

const SelectorBtn = ({ isOpen, children, onClick }: Props) => {
  return (
    <SelectorBtnContainer data-testid="selector-button" isOpen={isOpen} onClick={onClick}>
      {children}
    </SelectorBtnContainer>
  );
};

export default SelectorBtn;

const SelectorBtnContainer = styled.button<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  height: 50px;
  width: 100%;
  border: 1.5px solid ${(props) => (props.isOpen ? color.violet39 : color.greyd4)};
  border-radius: 6px;
  background-color: $primaryWhite;

  :hover {
    border: 1.5px solid ${color.violet39};
  }
`;
