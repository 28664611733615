import { useEffect } from 'react';

import { useOverlay } from '@nurihaus/use-overlay';
import { t } from 'i18next';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { useCancelBulkMessage, useGetBulkMessage } from 'hooks/chat/bulkMessage/useBulkMessage';

import Icon from 'components/Icons/Icon';
import NormalButton from 'components/common/button/NormalButton';
import PageLoading from 'components/common/loading/PageLoading';
import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import { Column } from 'components/layout/common/InfoCard';

import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

import { color } from 'styles/assets';

interface Props {
  isOpen: boolean;
  close: () => void;
  id: number;
}

const BulkMessageCancelModal = ({ close, id }: Props) => {
  const overlay = useOverlay();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data } = useGetBulkMessage(Number(id));

  const onSuccess = () => {
    queryClient.invalidateQueries(['bulkMessage', id]);
    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: 'Success',
        severity: 'success',
      })
    );
    close();
  };
  const onError = (err: any) => {
    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: errToErrMsg(err?.response),
        severity: 'error',
      })
    );
  };
  const { mutate, isLoading } = useCancelBulkMessage({
    id,
    onSuccess,
    onError,
  });

  const onConfirm = () => {
    if (!data) return;

    mutate({
      object_id: data.object_id,
      content_type: data.content_type,
      content: data.message,
    });
  };

  useEffect(() => {
    if (isLoading) {
      overlay.open(() => <PageLoading />);

      return () => overlay.close();
    }
  }, [isLoading]);

  return (
    <ModalForm width="370px" backgroundOnPress={close}>
      <Column gap="20px">
        <Column gap="14px">
          <IconWrapper>
            <Icon type="warning_triangle" width={24} height={24} fill="violet39" />
          </IconWrapper>
          <Column>
            <StyledText fontSize="base" fontWeight="semiBold" text={t('chat.bulkMessage-cancel-modal-title')} />
            <StyledText fontSize="base" fontWeight="regular" text={t('chat.bulkMessage-cancel-modal-subtitle')} />
          </Column>
        </Column>
        <ButtonRow>
          <NormalButton sizeType="regular" width="148px" text="Cancel" styleType="ghost" onPress={close} />
          <NormalButton sizeType="regular" width="148px" text="Continue" onPress={onConfirm} />
        </ButtonRow>
      </Column>
    </ModalForm>
  );
};

export default BulkMessageCancelModal;

const IconWrapper = styled.div`
  width: 46px;
  height: 46px;
  background-color: ${color.violete9};
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
