export const fixedWidthOption = (width: number) => ({ width, minWidth: width, maxWidth: width });

export const fitContentOption = (minWidth: number) => ({ minWidth });

export const noCustomOption = { sortable: false, filterable: false };

export const invisibleOption = {
  width: 0,
  minWidth: 0,
  maxWidth: 0,
  sortable: false,
  filterable: false,
  renderHeader: () => <></>,
  renderCell: () => <></>,
};
