import { Dispatch, KeyboardEvent, SetStateAction, SyntheticEvent } from 'react';

import { InputBase, ThemeProvider, createTheme } from '@mui/material';
import { TabsConstantsType } from 'constants/tabsConstants';
import styled from 'styled-components';

import { getTabArrayByType } from 'utils/common/getTabArrayByType';

import ListTab from 'components/pages/campaign/list/ListTab';

import { color } from 'styles/assets';

interface SearchProps {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  searchFunction?: () => void;
}

interface Props {
  type: TabsConstantsType;
  tab: string;
  search?: SearchProps;
  handleChange: (e: SyntheticEvent, v: string) => void;
}

const DEFAULT_SEARCH_FUNCTION = () => {
  console.log('Search function is missing..');
};
export const tabTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: `'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
        'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol', sans-serif`,
          color: 'red',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '9px 55px',
          textTransform: 'none',
          transition: `all 0.3s ease-in-out`,
          fontWeight: 500,

          '&:hover': {
            backgroundColor: color.greyf4,
          },
          '&.Mui-selected': {
            color: 'rgba(0, 0, 0, 0.8)',
            backgroundColor: color.greyf4,
            fontWeight: 700,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: { overflow: 'auto' },
        indicator: {
          display: 'none',
        },
      },
    },
  },
});

const ListTabCollect = ({ tab, type, search, handleChange }: Props) => {
  const { searchText, setSearchText = () => {}, searchFunction = DEFAULT_SEARCH_FUNCTION } = search ?? {};
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      searchFunction();
    }
  };

  return (
    <ThemeProvider theme={tabTheme}>
      <ListTabCollectContainer>
        <ListTab tab={tab} handleChange={handleChange} tabArr={getTabArrayByType(type)} />
        {search ? (
          <ListSearchContainer>
            <InputBase
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </ListSearchContainer>
        ) : null}
      </ListTabCollectContainer>
    </ThemeProvider>
  );
};

export default ListTabCollect;

const ListTabCollectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  justify-content: space-between;
`;

const ListSearchContainer = styled.div`
  padding: 14px;
  display: flex;
  align-items: center;
  width: 358px;
  height: 50px;
  border-radius: 4px;
  background-color: ${color.greyf4};

  input,
  input::placeholder {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: ${color.greya1};
  }
  input {
    width: 100%;
    margin-left: 1px;
    color: black;
  }
`;
