import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import Tooltip from 'components/common/Tooltip';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

import { ColorTypes, color } from 'styles/assets';

interface Props {
  badgeText: string;
  hoverText?: string;
  styleType: 'primary' | 'disabled';
}

const colorMap: Record<string, ColorTypes> = {
  primary: 'black',
  disabled: 'greyd4',
};

const getIconType = (styleType: 'primary' | 'disabled') => {
  return styleType === 'primary' ? 'jelly_colored' : 'jelly_disabled';
};

const PointBadge = ({ badgeText, hoverText, styleType }: Props) => {
  const badgeContent = <PointBadgeContent styleType={styleType} badgeText={badgeText} />;

  return (
    <PointBadgeContainer styleType={styleType}>
      {hoverText ? (
        <Tooltip
          backgroundColor="white"
          borderColor="greyd4"
          tooltipDirection="bottom"
          width="auto"
          padding="7px 18px"
          hoverElement={badgeContent}
        >
          <HoverTextWrapper>
            <Icon type="jelly_colored" />
            <StyledText width="40px" text={hoverText} fontWeight="bold" fontColor="violet4c" fontSize="sm" />
          </HoverTextWrapper>
        </Tooltip>
      ) : (
        badgeContent
      )}
    </PointBadgeContainer>
  );
};

const PointBadgeContent = ({ styleType, badgeText }: { styleType: 'primary' | 'disabled'; badgeText: string }) => (
  <HoverBadgeWrapper>
    <Icon type={getIconType(styleType)} />
    <Gap side={2} />
    <StyledText text={badgeText} fontWeight="bold" fontSize="sm" fontColor={colorMap[styleType]} />
  </HoverBadgeWrapper>
);

export default PointBadge;

const PointBadgeContainer = styled.div<{ styleType: 'primary' | 'disabled' }>`
  position: relative;
  width: 88px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 52px;
  border: 1px solid ${color.greyd4};
`;

const HoverBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HoverTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;
