import { createSlice } from '@reduxjs/toolkit';

interface SnackbarType {
  snackbarOpen: boolean;
  snackbarMessage: string;
  severity: SnackbarSeverity;
}

const initialState: SnackbarType = {
  snackbarOpen: false,
  snackbarMessage: '',
  severity: 'info',
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    SetSnackbar: (state, action) => {
      return {
        ...state,
        snackbarOpen: action.payload.snackbarOpen,
        snackbarMessage: action.payload.snackbarMessage,
        severity: action.payload.severity,
      };
    },
  },
});

export const { SetSnackbar } = snackbarSlice.actions;

export type SnackbarSeverity = 'error' | 'warning' | 'info' | 'success';

export default snackbarSlice.reducer;
