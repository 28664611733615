import { Grid } from '@mui/material';
import { t } from 'i18next';
import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';
import BulkMessageDetailNotSentCard from 'components/pages/chat/bulkMessage/NotSentCard';

interface Props {
  data: any;
}

const ChatBulkMessageEditContainer = ({ data }: Props) => {
  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Title>
            <StyledText fontSize="xl2" fontWeight="bold" text={t('chat.bulkMessage-list-title')} />
            <Notice>
              <StyledText
                fontSize="base"
                fontWeight="medium"
                text={t('chat.bulkMessage-detail-notice')}
                fontColor="grey27"
              />
            </Notice>
          </Title>
        </Grid>
        <BulkMessageDetailNotSentCard bulkMessage={data} editable />
      </Grid>
    </Container>
  );
};

export default ChatBulkMessageEditContainer;

const Container = styled.div`
  padding: 24px;
`;

const Title = styled.div`
  position: relative;
  padding-bottom: 30px;
`;

const Notice = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;
