import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';

import { ColorTypes, color } from 'styles/assets';

interface Props {
  text?: string;
  width?: number;
  height?: number;
  borderWidth?: number;
  borderColor: ColorTypes;
  fontColor: ColorTypes;
}

const StatusBadge = ({ text, width = 180, height = 47, borderWidth = 1, borderColor, fontColor }: Props) => {
  return (
    <Container width={width} height={height} borderWidth={borderWidth} borderColor={borderColor}>
      <StyledText text={text} fontSize="sm" fontWeight="bold" fontColor={fontColor} />
    </Container>
  );
};

export default StatusBadge;

const Container = styled.div<{ width: number; height: number; borderWidth: number; borderColor: ColorTypes }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-width: ${({ borderWidth }) => borderWidth}px;
  border-style: solid;
  border-color: ${({ borderColor }) => color[borderColor]};
`;
