import { Dispatch, SetStateAction, useState } from 'react';

import { InputBase, ListItemButton } from '@mui/material';
import styled from 'styled-components';

import { useInfiniteCampaignListData } from 'hooks/campaign/queries/list';

import MenuIcon from 'components/Icons/MenuIcon';
import SelectorDropDownScroll from 'components/common/selector/SelectorDropDownScroll';
import StyledText from 'components/common/text/StyledText';

import { color } from 'styles/assets';

interface Props {
  dropDownOpen: boolean;
  deepLinkInfo: { type: 'earn' | 'spend' | ''; label: '' | 'Quest' | 'Benefit' };
  setDeepLinkInfo: Dispatch<SetStateAction<any>>;
  step: 1 | 2;
  setStep: Dispatch<SetStateAction<1 | 2>>;
}

const CampaignLinkDropDown = ({ dropDownOpen, deepLinkInfo, setDeepLinkInfo, step, setStep }: Props) => {
  const { type } = deepLinkInfo;
  const [query, setQuery] = useState('');
  const campaignData = [
    { value: 'earn', label: 'Quest' },
    { value: 'spend', label: 'Benefit' },
  ];

  const {
    data: infiniteData,
    isCampaignListLoading,
    fetchNextPage,
    refetch,
    isRefetching,
  } = useInfiniteCampaignListData(type as 'earn' | 'spend', { query });

  const getSearchData = () => {
    return refetch();
  };
  const handleSearchOnChange = (e: any) => {
    return setQuery(e.target.value);
  };
  const handleEventsListOnClick = (data: any) => {
    setDeepLinkInfo({ ...deepLinkInfo, type: data.value, label: data.label });
    setStep(2);
  };
  const handleCampaignListOnClick = (campaign: any) => {
    return setDeepLinkInfo({ ...deepLinkInfo, campaign });
  };
  const handleBackBtn = () => {
    setDeepLinkInfo({ ...deepLinkInfo, type: '', label: '' });
    setStep(1);
  };

  if (!dropDownOpen) return null;

  return step === 1 ? (
    <Step1Wrapper data-testid="container-step1">
      {campaignData.map((data, idx) => {
        return (
          <ListItemButton
            data-testid="campaign-type-item"
            onClick={() => handleEventsListOnClick(data)}
            key={idx}
            sx={{ height: '59px' }}
          >
            <StyledText text={data.label} />
          </ListItemButton>
        );
      })}
    </Step1Wrapper>
  ) : (
    <Step2Wrapper data-testid="container-step2">
      <BackBtn onClick={handleBackBtn}>
        <MenuIcon type="chevron_left" />
        <StyledText text="Back" fontSize="sm" />
      </BackBtn>
      <SearchBarWrapper>
        <InputBase
          sx={{
            flex: 1,
            width: '100%',
            height: '44px',
            backgroundColor: `${color.greyfa}`,
            padding: '14px',
          }}
          placeholder="Search"
          onKeyUp={getSearchData}
          onChange={(e) => handleSearchOnChange(e)}
        />
      </SearchBarWrapper>

      <SelectorDropDownScroll loadNext={fetchNextPage}>
        {!isCampaignListLoading &&
          !isRefetching &&
          infiniteData?.pages?.map((pageData: any) => {
            return pageData?.results?.map((data: any, idx: number) => {
              return (
                <ListItemButton
                  data-testid="campaign-item"
                  onClick={() => handleCampaignListOnClick(data)}
                  key={idx}
                  sx={{ height: '59px' }}
                >
                  <ThumbnailImg src={data.image} />
                  <StyledText text={data.title} />
                </ListItemButton>
              );
            });
          })}
      </SelectorDropDownScroll>
    </Step2Wrapper>
  );
};

export default CampaignLinkDropDown;

const Step1Wrapper = styled.div`
  position: absolute;
  width: 484px;
  background-color: ${color.white};
  z-index: 1001;
`;

const Step2Wrapper = styled.div`
  position: absolute;
  width: 484px;
  background-color: ${color.white};
  z-index: 1001;
`;

const BackBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 14px;
`;

const SearchBarWrapper = styled.div`
  padding: 0 14px;
  margin-bottom: 14px;
`;

const ThumbnailImg = styled.img`
  width: 40px;
  height: 40px;
  aspect-ratio: 1;
  object-fit: cover;
  background-color: ${color.greye4};
  margin-right: 8px;
`;
