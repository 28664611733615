import { Fragment, useState } from 'react';

import { Grid, ThemeProvider } from '@mui/material';
import { t } from 'i18next';
import styled from 'styled-components';

import BulkMessageDetailSentCardMessageDetailData from 'containers/chat/bulkMessage/detail/BulkMessageDetailSentCardMessageDetailData';
import BulkMessageDetailSentCardMessageInfoData from 'containers/chat/bulkMessage/detail/MessageInfoData';

import Block from 'components/layout/common/Block';
import ListTab from 'components/pages/campaign/list/ListTab';
import { tabTheme } from 'components/pages/campaign/list/ListTabCollect';
import MessageDetailDataDownloadBTN from 'components/pages/chat/bulkMessage/sentCard/MessageDetailDataDownloadBTN';

import { color } from 'styles/assets';

import { Messaging } from 'types/bulkMessge';

interface Props {
  bulkMessage: Messaging;
}

const BulkMessageDetailSentCard = ({ bulkMessage }: Props) => {
  const tabOption = [
    { value: 'info', label: t('chat.bulkMessage-detail-sent-tab-info') },
    { value: 'data', label: t('chat.bulkMessage-detail-sent-tab-detail') },
  ];
  const [tab, setTab] = useState('info');

  return (
    <Grid item xs={12}>
      <Block padding="0" overflowY="visible">
        <Inner>
          <TabWrapper>
            <ThemeProvider theme={tabTheme}>
              <ListTab tab={tab} handleChange={(e, newValue) => setTab(newValue)} tabArr={tabOption} />
            </ThemeProvider>
          </TabWrapper>
          {tab === 'data' ? (
            <Fragment>
              <BulkMessageDetailSentCardMessageDetailData bulkMessage={bulkMessage} />
              <DownloadBtn>
                <MessageDetailDataDownloadBTN bulkMessage={bulkMessage} />
              </DownloadBtn>
            </Fragment>
          ) : (
            <BulkMessageDetailSentCardMessageInfoData bulkMessage={bulkMessage} />
          )}
        </Inner>
      </Block>
    </Grid>
  );
};

export default BulkMessageDetailSentCard;

const TabWrapper = styled.div`
  width: 100%;
  padding: 15px 20px;
  border-bottom: 1px solid ${color.greye4};
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const DownloadBtn = styled.div`
  position: absolute;
  right: 0;
  top: -70px;
`;
