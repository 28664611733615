import {
  DataGrid,
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  GridToolbarExport,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  useGridApiContext,
} from '@mui/x-data-grid';
import styled from 'styled-components';

import { bulkMessageUserExportColumns } from 'utils/bulkmessage/BulkMessageUserColumnsData';

import NormalButton from 'components/common/button/NormalButton';

import { Messaging } from 'types/bulkMessge';

interface Props {
  bulkMessage: Messaging;
}

const MessageDetailDataDownloadBTN = ({ bulkMessage }: Props) => {
  const columns = bulkMessageUserExportColumns({ bulkMessage });
  const { success_users, fail_users } = bulkMessage;
  const totalUsers = [
    ...fail_users.map((user: any) => ({ ...user, is_success: false })),
    ...success_users.map((user: any) => ({ ...user, is_success: true })),
  ];
  const totalUsersWithIndex = totalUsers.map((user, index) => ({ ...user, index: index + 1 }));

  const downloadCSV = async () => {
    const save = async () => {
      if (!document) return;

      const exportBTN = (await document.querySelector('[aria-label="Export"]')) as HTMLElement;
      if (!exportBTN) return;

      await exportBTN.click();
      const BTNList = (await document.querySelector('[role="menuitem"]')) as HTMLElement;
      if (!BTNList) return;

      await BTNList.click();
    };
    save();
  };

  const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    const handleExport = (option: GridCsvExportOptions) => apiRef.current.exportDataAsCsv(option);

    const getRowsFromCurrentPage = ({ apiRef }: GridCsvGetRowsToExportParams) =>
      gridPaginatedVisibleSortedGridRowIdsSelector(apiRef.current.state);

    const exportAction = async () => {
      await handleExport({ getRowsToExport: getRowsFromCurrentPage });
    };

    return (
      <GridToolbarExport csvOptions={{ utf8WithBom: true }} style={{ display: 'none' }}>
        <button onClick={exportAction} className="export-csv-btn-all" id="export-csv-btn" style={{ display: 'none' }} />
      </GridToolbarExport>
    );
  };

  return (
    <>
      <NormalButton text="Export to CSV" onPress={downloadCSV} sizeType="medium" width="150px" styleType="ghost" />
      <ViewerContainer>
        <DataGrid
          columns={columns}
          rows={totalUsersWithIndex || []}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </ViewerContainer>
    </>
  );
};

export default MessageDetailDataDownloadBTN;

const ViewerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
`;
