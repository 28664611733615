import { useNavigate } from 'react-router-dom';

import { useCampaignDetailData } from 'hooks/campaign/queries/detail';

import ViewCardModal from 'containers/campaign/detail/ViewCardModal';

import PageLoading from 'components/common/loading/PageLoading';
import ModalWithOneButton from 'components/common/modal/ModalWIthOneButton';

interface Props {
  type: 'quest' | 'benefit';
  id: number;
  opened: boolean;
  close: () => void;
}

const CampaignDetailModal = ({ type, id, opened, close }: Props) => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useCampaignDetailData({ campaign: type, detailId: Number(id) });

  if (isLoading) return <PageLoading />;

  if (isError) return <ModalWithOneButton opened={opened} close={close} buttonText="OK" text="error occured" />;

  return (
    <ViewCardModal
      detailData={data}
      opened={opened}
      close={close}
      navigate={navigate}
      params={{ campaign: type, type: 'manual' }}
    />
  );
};

export default CampaignDetailModal;
