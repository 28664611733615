import { ComponentPropsWithoutRef } from 'react';

import { TextField, ThemeProvider, createTheme } from '@mui/material';
import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import { Row } from 'components/layout/common/InfoCard';

import { color } from 'styles/assets';

interface Props extends Omit<ComponentPropsWithoutRef<'input'>, 'color' | 'size'> {
  dataTestid?: string;
  value?: string | number;
  error?: boolean;
  textAlign?: 'end' | 'center' | 'start';
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  helperText?: string;
  width?: string;
  maxLength?: number;
  label?: string;
  required?: boolean;
  maxLengthHidden?: boolean;
}

const TextInput = ({
  dataTestid,
  value,
  error = false,
  textAlign = 'start',
  startAdornment,
  endAdornment,
  helperText = '',
  width,
  maxLength,
  label,
  required = false,
  maxLengthHidden = false,
  ...restProps
}: Props) => {
  const TextInputTheme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: width || 'initial',
            flexGrow: width ? 'initial' : 1,
            flexShrink: width ? 'initial' : 1,
            '& .Mui-disabled': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: color.black,
            margin: '4px 0 0 0',
            fontSize: '14px',
            lineHeight: '18px',
            fontFamily:
              "'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif",
            fontWeight: 500,
            '&.Mui-error': {
              color: color.reddf,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              fontSize: '14px',
              lineHeight: '18px',
              fontFamily:
                "'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif",
              fontWeight: 500,
              textAlign: textAlign,

              '&::placeholder': {
                opacity: 1,
                color: color.greya1,
                fontSize: '14px',
                lineHeight: '18px',
                fontWeight: 500,
              },
            },
            '&.Mui-disabled .MuiInputBase-input': {
              cursor: 'not-allowed',
              backgroundColor: color.greyf4,
              WebkitTextFillColor: color.black,
              '&::placeholder': {
                WebkitTextFillColor: color.greya1,
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: 50,
            backgroundColor: color.white,

            '.MuiOutlinedInput-notchedOutline': {
              borderColor: color.greyd4,
              borderWidth: 1.5,
              borderRadius: 4,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: color.violet4c,
              borderWidth: 1.5,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: color.reddf,
              borderWidth: 1.5,
            },
            ':hover .MuiOutlinedInput-notchedOutline': {
              borderColor: color.violet4c,
              borderWidth: 1.5,
            },
          },
        },
      },
    },
  });
  const inputValueLength = String(value)?.length;

  return (
    <Wrapper>
      <ThemeProvider theme={TextInputTheme}>
        <ContentsTitleContainer>
          {label && (
            <>
              <Row justifyContent="flex-start">
                <StyledText fontSize="base" fontWeight="bold" text={label} />
                {required && <StyledText fontSize="base" fontWeight="bold" text="*" fontColor="reddf" />}
              </Row>
              <Gap side={6} />
            </>
          )}

          {maxLength && !maxLengthHidden && (
            <InputFooterWrapper>
              <StyledText
                text={`(${inputValueLength}/${maxLength})`}
                fontColor={inputValueLength >= maxLength ? 'reddf' : 'greya1'}
              />
            </InputFooterWrapper>
          )}
        </ContentsTitleContainer>
        <TextField
          data-testid={dataTestid}
          value={value}
          helperText={helperText}
          error={error}
          InputProps={{
            startAdornment: startAdornment,
            endAdornment: endAdornment,
          }}
          inputProps={{ maxLength }}
          {...restProps}
        />
      </ThemeProvider>
    </Wrapper>
  );
};
export default TextInput;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentsTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const InputFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
