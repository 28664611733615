import { ChangeEvent, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Channel } from 'talkplus-sdk';

import { uploadImage } from 'api/lounge';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { useChat } from 'hooks/chat/queries';

import MenuIcon from 'components/Icons/MenuIcon';
import PageLoading from 'components/common/loading/PageLoading';
import { Row } from 'components/layout/common/InfoCard';
import ChannelInfoInput from 'components/pages/chat/groupChat/talkplus/create/ChannelInfoInput';

import { selectAccount } from 'features/redux/selectors/accounts';
import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

import { color } from 'styles/assets';

interface Props {
  closeModal: () => void;
  channel: Channel;
}

const EditGroupChannelModal = ({ closeModal, channel }: Props) => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const { access_token } = account.userInfo;
  const { useUpdateChannel } = useChat();
  const { mutate: update } = useUpdateChannel();

  const [basicInfo, setBasicInfo] = useState<{ name: string; coverUrl: string }>({
    name: channel.name,
    coverUrl: channel.imageUrl,
  });

  const EditGroupChannel = async () => {
    update(
      {
        channelId: channel.id,
        name: basicInfo.name,
        imageUrl: basicInfo.coverUrl,
      },
      {
        onSuccess() {
          closeModal();
        },
      }
    );
  };

  const infoHandler =
    (key: 'name' | 'coverUrl' | 'welcome') => (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      switch (key) {
        case 'name':
          setBasicInfo({ ...basicInfo, name: e.target.value });
          break;
        case 'coverUrl':
          if ('files' in e.target) {
            if (!e.target.files) return;

            const form = new FormData();
            form.append('file', e.target.files[0]);
            uploadImage(access_token, form)
              .then((data) => {
                setBasicInfo({ ...basicInfo, coverUrl: data.url });
              })
              .catch((err) => {
                dispatch(
                  SetSnackbar({
                    snackbarOpen: true,
                    snackbarMessage: String(errToErrMsg(err.response)),
                    severity: 'error',
                  })
                );
              });
            e.target.value = '';
          }

          break;
      }
    };

  return (
    <Container>
      <PageLoading isLoading={false} />
      <Background onClick={closeModal} />
      <ModalContainer>
        <Row>
          <button onClick={closeModal}>
            <MenuIcon type="multiply" />
          </button>
        </Row>
        <ContentWrapper>
          <ChannelInfoInput
            basicInfo={basicInfo}
            infoHandler={infoHandler}
            welcomeMsg=""
            welcomeDisable={true}
            next={EditGroupChannel}
            edit={true}
          />
        </ContentWrapper>
      </ModalContainer>
    </Container>
  );
};

export default EditGroupChannelModal;

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1202;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${color.black};
  opacity: 0.3;
`;

const ModalContainer = styled.div`
  background-color: ${color.greyf4};
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 60%;
  min-width: 900px;
  overflow: scroll;
`;

const ContentWrapper = styled.div`
  width: 754px;
  margin: 26px 0 200px 0;
`;
