import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';

import { ColorTypes, color } from 'styles/assets';

interface Props {
  styleType: 'primary' | 'disabled';
}

const fontcolorMap: { [key: string]: ColorTypes } = {
  primary: 'black',
  disabled: 'greyd4',
};
const iconMap: { [key: string]: 'ugc' | 'ugc_disabled' } = {
  primary: 'ugc',
  disabled: 'ugc_disabled',
};

const UGCBadge = ({ styleType }: Props) => {
  return (
    <Container data-testid="badge-ugc-container" styleType={styleType}>
      <Icon type={iconMap[styleType]} />
      <StyledText text="UGC" fontColor={fontcolorMap[styleType]} fontWeight="bold" fontSize="sm" />
    </Container>
  );
};
export default UGCBadge;

const Container = styled.div<{ styleType: string }>`
  display: flex;
  padding: 0 15px;
  width: 88px;
  height: 32px;
  border-radius: 52px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border: 1px solid ${color.greyd4};
`;
