import { lazy } from 'react';

export const LoginPage = lazy(() => import('pages/account/LoginPage'));
export const MailAuthPage = lazy(() => import('pages/account/MailAuthPage'));

export const BannerListPage = lazy(() => import('pages/banner/BannerListPage'));
export const BannerDetailPage = lazy(() => import('pages/banner/BannerDetailPage'));
export const NewBannerPage = lazy(() => import('pages/banner/BannerNewPage'));
export const EditBannerPage = lazy(() => import('pages/banner/BannerEditPage'));

export const ChatPage = lazy(() => import('pages/chat/ChatPage'));
export const ChatStaffPage = lazy(() => import('pages/chat/ChatStaffPage'));

export const CampaignListPage = lazy(() => import('pages/campaign/CampaignListPage'));
export const CampaignDetailPage = lazy(() => import('pages/campaign/CampaignDetailPage'));
export const CampaignCreatePage = lazy(() => import('pages/campaign/CampaignCreatePage'));
export const CampaignEditPage = lazy(() => import('pages/campaign/CampaignEditPage'));
export const CampaignGuidePage = lazy(() => import('pages/campaign/CampaignGuidePage'));

export const DashboardMainPage = lazy(() => import('pages/dashboard/DashboardPage'));

export const SettingsMainPage = lazy(() => import('pages/settings/SettingsMainPage'));

export const UsersMainPage = lazy(() => import('pages/users/UsersListPage'));
export const UserDetailPage = lazy(() => import('pages/users/UserDetailPage'));
export const UserChargeHistoryPage = lazy(() => import('pages/users/UserChargeHistoryPage'));
export const UserSpendHistoryPage = lazy(() => import('pages/users/JellySpendHistoryPage'));
export const EditUserPage = lazy(() => import('pages/users/UserEditPage'));
export const NewUserPage = lazy(() => import('pages/users/UserNewPage'));

export const ClientListPage = lazy(() => import('pages/client/ClientListPage'));
export const ClientDetailPage = lazy(() => import('pages/client/ClientDetailPage'));
export const EditClientPage = lazy(() => import('pages/client/ClientEditPage'));
export const NewClientPage = lazy(() => import('pages/client/ClientNewPage'));
export const ClientChargeHistoryPage = lazy(() => import('pages/client/ClientChargeHistoryPage'));
export const ClientSpendHistoryPage = lazy(() => import('pages/client/ClientSpendHistoryPage'));
export const ClientOrderHistoryPage = lazy(() => import('pages/client/ClientOrderHistoryPage'));

export const MyJellyPage = lazy(() => import('pages/myJelly/MyJellyPage'));
export const JellyChargeHistoryPage = lazy(() => import('pages/myJelly/JellyChargeHistoryPage'));
export const JellySpendHistoryPage = lazy(() => import('pages/myJelly/JellySpendHistoryPage'));
export const PackageSettingPage = lazy(() => import('pages/myJelly/PackageSettingPage'));
export const OrderHistoryPage = lazy(() => import('pages/myJelly/OrderHistoryPage'));
export const AdminOrderHistoryPage = lazy(() => import('pages/myJelly/AdminOrderHistoryPage'));

export const PayoutRequestListPage = lazy(() => import('pages/payout/PayoutRequestListPage'));
export const PayoutRequestDetailPage = lazy(() => import('pages/payout/PayoutRequestDetailPage'));

export const NoticeListPage = lazy(() => import('pages/notice/NoticeListPage'));
export const NoticeDetailPage = lazy(() => import('pages/notice/NoticeDetailPage'));
export const EditNoticePage = lazy(() => import('pages/notice/NoticeEditPage'));
export const NewNoticePage = lazy(() => import('pages/notice/NoticeNewPage'));

export const AnalyticsPage = lazy(() => import('pages/analytics/AnalyticsPage'));

export const NotificationsListPage = lazy(() => import('pages/notifications/NotificationListPage'));
export const NotificationsCreatePage = lazy(() => import('pages/notifications/NotificationCreatePage'));
export const NotificationsDetailPage = lazy(() => import('pages/notifications/NotificationDetailPage'));
export const NotificationsEditPage = lazy(() => import('pages/notifications/NotificationEditPage'));
