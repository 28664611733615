import { Fragment, useEffect, useState } from 'react';

import { useOverlay } from '@nurihaus/use-overlay';
import { MessageDataTypeEnum } from 'constants/chatConstants';
import styled from 'styled-components';
import { Channel, MessageTypeEnum } from 'talkplus-sdk';

import { useChat } from 'hooks/chat/queries';

import MenuIcon from 'components/Icons/MenuIcon';
import NormalButton from 'components/common/button/NormalButton';
import ModalForm from 'components/common/modal/ModalForm';
import ModalWithOneButton from 'components/common/modal/ModalWIthOneButton';
import SelectorBody from 'components/common/selector/SelectorBody';
import SelectorBtn from 'components/common/selector/SelectorBtn';
import SelectorWrapper from 'components/common/selector/SelectorWrapper';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import CampaignLinkDropDown from 'components/pages/chat/groupChat/sendbird/pinned/CamapginLinkDropDown';

import { color } from 'styles/assets';

interface Props {
  opened: boolean;
  close: () => void;
  channel: Channel;
}

const CampaignLinkPinMsgModal = ({ opened, close, channel }: Props) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const { useSendMessage } = useChat();
  const { mutate: sendDeeplinkMessage } = useSendMessage();
  const [step, setStep] = useState<1 | 2>(1);
  const [deepLinkInfo, setDeepLinkInfo] = useState<{
    type: 'earn' | 'spend' | '';
    label: 'Quest' | 'Benefit' | '';
    campaign: { id?: number; image?: string; title?: string; point?: number };
  }>({
    type: '',
    label: '',
    campaign: {},
  });

  const successModalOverlay = useOverlay();

  const handleSelectorClick = () => {
    return setDropDownOpen((prev) => !prev);
  };
  const handleCancelOnClick = () => {
    setDropDownOpen(false);
    close();
  };
  const handleDeleteOnClick = () => {
    setDeepLinkInfo({ ...deepLinkInfo, campaign: {} });
  };
  const handleSaveBtnClick = () => {
    sendDeeplinkMessage(
      {
        channelId: channel.id,
        type: MessageTypeEnum['Text'],
        text: 'New Campaign!',
        data: {
          type: MessageDataTypeEnum.DEEPLINK,
          campaignType: deepLinkInfo.label.toLowerCase(),
          id: deepLinkInfo.campaign.id?.toString() as string,
          image: deepLinkInfo.campaign.image as string,
          title: deepLinkInfo.campaign.title as string,
        },
      },
      {
        onSuccess() {
          close();
          successModalOverlay.open(({ isOpen, close }) => (
            <ModalWithOneButton opened={isOpen} text="Campaign Added" close={close} buttonText="OK" />
          ));
        },
      }
    );
  };

  useEffect(() => {
    setDropDownOpen(false);
  }, [opened]);

  if (!opened) return null;

  return (
    <ModalForm backgroundOnPress={close} overflow="visible">
      <ModalInner>
        <StyledText text="Campaign" fontSize="xl2" fontWeight="extraBold" />
        <Gap side={20} />
        {deepLinkInfo?.campaign?.id ? (
          <SelectedItemContainer>
            <CloseBtn onClick={handleDeleteOnClick}>
              <MenuIcon type="multiply" fill="white" />
            </CloseBtn>
            <ThumbnailImg src={deepLinkInfo.campaign?.image} alt="thumbnail-image" />
            <StyledText text={deepLinkInfo.campaign.title} />
          </SelectedItemContainer>
        ) : (
          <SelectorContainer>
            <SelectorWrapper>
              <SelectorBody>
                <SelectorBtn isOpen={dropDownOpen} onClick={handleSelectorClick}>
                  {deepLinkInfo.type !== '' ? (
                    <StyledText text={deepLinkInfo?.label} fontColor="greya1" />
                  ) : (
                    <StyledText text="Select a source" fontColor="greya1" />
                  )}
                  <MenuIcon type="chevron_bottom" />
                </SelectorBtn>
              </SelectorBody>
            </SelectorWrapper>
            {dropDownOpen && (
              <Fragment>
                <Gap side={25} />
                <CampaignLinkDropDown
                  dropDownOpen={dropDownOpen}
                  deepLinkInfo={deepLinkInfo}
                  setDeepLinkInfo={setDeepLinkInfo}
                  step={step}
                  setStep={setStep}
                />
              </Fragment>
            )}
          </SelectorContainer>
        )}

        <ModalBtnWrapper>
          <NormalButton width="160px" text="Cancel" styleType="ghost" sizeType="medium" onPress={handleCancelOnClick} />
          <Gap side={20} />
          <NormalButton
            width="160px"
            text="Save"
            sizeType="medium"
            onPress={handleSaveBtnClick}
            disabled={deepLinkInfo.type === '' || !deepLinkInfo.campaign?.id}
          />
        </ModalBtnWrapper>
      </ModalInner>
    </ModalForm>
  );
};

export default CampaignLinkPinMsgModal;

const ModalInner = styled.div`
  width: 484px;
  height: 190px;
  max-width: 484px;
  max-height: 190px;
  z-index: 999;
  position: relative; // 추가
`;

const ModalBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SelectorContainer = styled.div``;

const SelectedItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1.5px solid ${color.greyd4};
  padding: 3.5px;
  margin-bottom: 40px;
`;

const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  position: absolute;
  top: -14px;
  right: -14px;
  background-color: ${color.violet39};
`;

const ThumbnailImg = styled.img`
  width: 40px;
  height: 40px;
  aspect-ratio: 1;
  object-fit: cover;
  background-color: ${color.greye4};
  margin-right: 8px;
`;
