import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
    from{
        transform: rotate(0deg);
    }

    to{
        transform: rotate(360deg);
    }

`;

const Spinner = styled.div<{ side?: string }>`
  height: ${({ side }) => (side ? side : '30px')};
  width: ${({ side }) => (side ? side : '30px')};
  border: 1.5px solid white;
  border-radius: 50%;
  border-top: none;
  border-right: none;
  margin: 16px auto;
  animation: ${rotation} 1s linear infinite;
`;

export { Spinner };
