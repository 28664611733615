import styled from 'styled-components';

import { useChat } from 'hooks/chat/queries';

import NormalButton from 'components/common/button/NormalButton';
import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import { Column, Row } from 'components/layout/common/InfoCard';

interface Props {
  channelId: string;
  closeModal: () => void;
}

const PinMsgViewerModal = ({ channelId, closeModal }: Props) => {
  const { useGetNotice } = useChat();
  const { data } = useGetNotice({ channelId });
  const pinnedMsg = data?.text;

  if (!pinnedMsg) return <></>;

  return (
    <ModalForm width="544px" backgroundOnPress={closeModal}>
      <Column gap="20px" alignItems="flex-start">
        <StyledText fontSize="xl2" fontWeight="extraBold" text="Notification" />
        <PinInput>{pinnedMsg}</PinInput>
        <Row justifyContent="flex-end" gap="20px">
          <NormalButton sizeType="medium" text="Close" width="160px" onPress={closeModal} />
        </Row>
      </Column>
    </ModalForm>
  );
};

export default PinMsgViewerModal;

const PinInput = styled.pre`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  white-space: pre-wrap;
  word-break: break-word;
`;
