import { t } from 'i18next';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useChat } from 'hooks/chat/queries';
import { useRouter } from 'hooks/common/useRouter';

import Icon from 'components/Icons/Icon';
import NormalButton from 'components/common/button/NormalButton';
import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import { Column } from 'components/layout/common/InfoCard';

import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

import { color } from 'styles/assets';

interface ConfirmModalProps {
  isOpen: boolean;
  close: () => void;
  channelId: string;
  focusChannel: (channelId: string | null) => void;
}

const ConfirmModal = ({ close, channelId, focusChannel }: ConfirmModalProps) => {
  const dispatch = useDispatch();

  const { useFreezeChannel } = useChat();
  const { mutate: freezeChannel } = useFreezeChannel();
  const route = useRouter();
  const queryClient = useQueryClient();

  const closeChat = () => {
    freezeChannel(
      {
        channelId,
      },
      {
        onSuccess() {
          dispatch(
            SetSnackbar({
              snackbarOpen: true,
              snackbarMessage: 'Successfully closed!',
              severity: 'success',
            })
          );
          queryClient.invalidateQueries('channels').then(() => {
            focusChannel(null);
            close();
            route.push('/chat?type=0&tab=private&search=');
          });
        },
      }
    );
  };

  return (
    <ModalForm width="540px" padding="30px" backgroundOnPress={close}>
      <Column gap="20px">
        <Column gap="14px">
          <IconWrapper>
            <Icon type="warning_triangle" width={24} height={24} fill="violet39" />
          </IconWrapper>
          <StyledText fontSize="xl2" fontWeight="bold" text={t('chat.private-close-modal-title')} />
          <StyledText
            fontSize="base"
            textAlign="center"
            fontWeight="medium"
            text={t('chat.private-close-modal-description')}
          />
        </Column>
        <ButtonRow>
          <NormalButton
            sizeType="large"
            width="235px"
            text={t('chat.private-close-modal-btn-cancel')}
            styleType="ghost"
            onPress={close}
          />
          <NormalButton
            sizeType="large"
            width="235px"
            text={t('chat.private-close-modal-btn-confirm')}
            onPress={closeChat}
          />
        </ButtonRow>
      </Column>
    </ModalForm>
  );
};

export default ConfirmModal;

const IconWrapper = styled.div`
  width: 46px;
  height: 46px;
  background-color: ${color.violete9};
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
