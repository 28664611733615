import { Alert, Snackbar, Stack } from '@mui/material';
import styled from 'styled-components';

import Icon from 'components/Icons/Icon';

import { SnackbarSeverity } from 'features/redux/slices/snackbarSlice';

import { ColorTypes, color } from 'styles/assets';

import StyledText from './text/StyledText';

interface Props {
  open: boolean;
  severity: SnackbarSeverity;
  message: string;
  handleClose: () => void;
}

const SnackbarCollection = ({ open, severity, message, handleClose }: Props) => {
  const isValidSeverity = ['success', 'error', 'warning', 'info'].includes(severity);

  return (
    <Stack sx={{ width: '100%' }}>
      {isValidSeverity && (
        <Snackbar
          style={{ left: '50%', transform: 'translateX(-50%)' }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            icon={<AlertIcon severity={severity} />}
            severity={severity}
            sx={{
              backgroundColor: color[AlertTheme[severity].backgroundColor],
              borderRadius: '6px',
              alignItems: 'center',
              gap: '8px',
              padding: '12px 14px',
              width: '343px',
            }}
          >
            <StyledText text={message} fontColor={AlertTheme[severity].fontColor} fontSize="sm" fontWeight="regular" />
          </Alert>
        </Snackbar>
      )}
    </Stack>
  );
};

export default SnackbarCollection;

const AlertIcon = ({ severity }: { severity: SnackbarSeverity }) => {
  switch (severity) {
    case 'warning':
      return (
        <IconWrapper back="yellowff">
          <Icon type="warning_triangle" fill="white" />
        </IconWrapper>
      );
    case 'success':
      return (
        <IconWrapper back="white">
          <Icon type="check_circle" fill="violet39" />
        </IconWrapper>
      );
    case 'error':
      return (
        <IconWrapper back="reddf">
          <Icon type="info_circle" fill="white" />
        </IconWrapper>
      );
    case 'info':
      return (
        <IconWrapper back="violete9">
          <Icon type="bulb" fill="violet39" />
        </IconWrapper>
      );
  }
};

const AlertTheme: {
  [key in SnackbarSeverity]: {
    backgroundColor: ColorTypes;
    fontColor?: ColorTypes;
  };
} = {
  success: {
    backgroundColor: 'violet39',
    fontColor: 'white',
  },
  error: {
    backgroundColor: 'white',
    fontColor: 'yellowff',
  },
  warning: {
    backgroundColor: 'white',
    fontColor: 'reddf',
  },
  info: {
    backgroundColor: 'white',
    fontColor: 'violet39',
  },
};

const IconWrapper = styled.div<{ back: ColorTypes }>`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ back }) => color[back]};
  display: flex;
  justify-content: center;
  align-items: center;
`;
