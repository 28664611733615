import { ThemeProvider, createTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { Dayjs } from 'dayjs';

import { color } from 'styles/assets';

interface Props {
  disabled?: boolean;
  type: string;
  dateOnChange?: (e: Dayjs | any, type: string) => void;
  value?: Date;
  readOnly?: boolean;
  label?: string;
  name?: string;
}

const Calendar = ({
  disabled,
  type,
  dateOnChange,
  value,
  readOnly = false,
  label = 'Select date/time',
  name,
}: Props) => {
  const CalenderTheme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: '100%',
            zIndex: 0,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            paddingLeft: 10,
            paddingRight: 10,
            color: color.greya1,
            backgroundColor: 'white',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: readOnly ? 'white' : disabled ? color.greyf4 : 'white',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: disabled ? 'transparent' : color.greya1,
          },
        },
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={CalenderTheme}>
        <DateTimePicker
          disabled={disabled}
          label={label}
          value={value}
          onChange={(e: any) => dateOnChange && dateOnChange(e, type)}
          renderInput={(params) => <TextField {...params} name={name} />}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default Calendar;
