import styled from 'styled-components';

import { Column } from 'components/layout/common/InfoCard';
import SendStatusLabel from 'components/pages/chat/bulkMessage/SentStatusLabel';
import BulkMessageCampaignInfo from 'components/pages/chat/bulkMessage/info/BulkMessageCampaignInfo';
import BulkMessageInfo from 'components/pages/chat/bulkMessage/info/BulkMessageInfo';

import { Messaging } from 'types/bulkMessge';

interface Props {
  bulkMessage: Messaging;
}

const BulkMessageDetailSentCardMessageInfoData = ({ bulkMessage }: Props) => {
  const { total, success, fail } = bulkMessage;

  return (
    <Content>
      <SendStatusGroup>
        <SendStatusLabel type="total" count={total} />
        <SendStatusLabel type="success" count={success} />
        <SendStatusLabel type="fail" count={fail} />
      </SendStatusGroup>
      <Column gap="30px">
        <BulkMessageCampaignInfo bulkMessage={bulkMessage} />
        <BulkMessageInfo bulkMessage={bulkMessage} />
      </Column>
    </Content>
  );
};

export default BulkMessageDetailSentCardMessageInfoData;

const Content = styled.div`
  padding: 40px 20px 77px 20px;
  display: flex;
  flex-direction: column;
`;

const SendStatusGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 40px;
`;
