import axios from 'axios';

import { SlackMessageInfo } from 'types/common';

const MemberId: Record<string, string> = {
  annie: 'U05UYTN056H',
  beany: 'U07GUF1LLSD',
  benson: 'U0759EXACRK',
  donny: 'U07HWNB2HL5',
  ellie: 'U068JF6M4FK',
  ellya: 'U06MP307UNB',
  evelyn: 'U066UEKC6BY',
  francesca: 'U05UNDETENT',
  ginny: 'U06CMSNL6N8',
  haeri: 'U07QCLHUT3M',
  jade: 'U0725GAL74M',
  jake: 'U016P1CB5T9',
  jenya: 'U0815HU1UR',
  jun: 'U07P3MEPN4B',
  lania: 'U07GJ9T9TRS',
  lia: 'U06EM9M6C90',
  lily: 'U07CS317UHX',
  lisa: 'U05UWG2TFEF',
  lizzy: 'U01AGFYBDUN',
  nave: 'U07G4VDCCBA',
  noah: 'U05UM9NL2PR',
  paul: 'U07R1H533LG',
  sally: 'U07RGPV40RJ',
  taylor: 'U05UM9NT6RM',
  yujin: 'U076VEKEK63',
  zhansaya: 'U05V2284AD9',
};

export const sendSlackMessage = async (info: SlackMessageInfo) => {
  const { reportContent, errorType, errorMessage, timestamp, errorPath, mentionIds, additionalInfo } = info;

  const getAdditionalInfo = () => {
    switch (additionalInfo?.type) {
      case 'ApiError':
        return {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*Method* : \`${additionalInfo.method}\`\n\n*URL* : \`${additionalInfo.url}\`\n\n*Status* : \`${additionalInfo.status}\``,
            },
          ],
        };
      case 'RuntimeError':
        return {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*Type* : \`${additionalInfo.runtimeType}\``,
            },
          ],
        };
      case 'TimeoutError':
        return {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*Duration* : \`${additionalInfo.duration}\``,
            },
          ],
        };
      default:
        return null; // 기본값
    }
  };

  return await axios({
    method: 'post',
    url: process.env.REACT_APP_SLACK_WEBHOOK_URL ?? '',
    data: {
      username: 'error-bot',
      blocks: [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: '🚨 어드민 에러 발생 🚨',
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: reportContent,
          },
        },
        {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*Category* : \`${errorType}\`\n\n*Message* : \`${errorMessage}\`\n\n*Timestamp* : \`${timestamp}\`\n\n*Error Path* : \`${
                errorPath.split('>')[0]
              }\``,
            },
          ],
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `cc. ${mentionIds
              .split(',')
              .map((member) => `<@${MemberId[member.trim().toLowerCase()]}>`)
              .join(' ')}`,
          },
        },
        {
          type: 'divider',
        },
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: 'ℹ️ 추가 정보 ℹ️',
          },
        },
        ...(getAdditionalInfo() ? [getAdditionalInfo()] : []),
        {
          type: 'actions',
          elements: [
            {
              type: 'button',
              text: {
                type: 'plain_text',
                text: 'Go to Sentry',
              },
              url: 'https://nuri-haus.sentry.io/issues/?pㅓroject=4508476649701376&referrer=sidebar&statsPeriod=14d',
              style: 'primary',
            },
          ],
        },
      ],
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
