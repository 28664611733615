import { Grid } from '@mui/material';
import { t } from 'i18next';
import styled from 'styled-components';

import BulkMessageDetailSentCard from 'containers/chat/bulkMessage/detail/BulkMessageDetailSentCard';

import StyledText from 'components/common/text/StyledText';
import BulkMessageDetailNotSentCard from 'components/pages/chat/bulkMessage/NotSentCard';

import { Messaging, MessagingStatus } from 'types/bulkMessge';

interface Props {
  data: Messaging;
}

const ChatBulkMessageDetailContainer = ({ data }: Props) => {
  const { status } = data;
  const isSent = status === MessagingStatus.SENT;

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Title>
            <StyledText fontSize="xl2" fontWeight="bold" text={t('chat.bulkMessage-list-title')} />
            {!isSent && (
              <Notice>
                <StyledText
                  fontSize="base"
                  fontWeight="medium"
                  text={t('chat.bulkMessage-detail-notice')}
                  fontColor="grey27"
                />
              </Notice>
            )}
          </Title>
        </Grid>
        {isSent ? (
          <BulkMessageDetailSentCard bulkMessage={data} />
        ) : (
          <BulkMessageDetailNotSentCard bulkMessage={data} />
        )}
      </Grid>
    </Container>
  );
};

export default ChatBulkMessageDetailContainer;

const Container = styled.div`
  padding: 24px;
`;

const Title = styled.div`
  position: relative;
  padding-bottom: 30px;
`;

const Notice = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;
