import { Method } from 'axios';
import styled from 'styled-components';

import { useSlackMessage } from 'hooks/common/queries';

import Icon from 'components/Icons/Icon';
import NormalButton from 'components/common/button/NormalButton';
import { ApiError, RuntimeError, TimeoutError } from 'components/common/errorBoundary/errors';
import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import { Row } from 'components/layout/common/InfoCard';

import { color } from 'styles/assets';

import { SlackMessageInfo } from 'types/common';

interface Props {
  error: {
    message: string;
    info: ApiError | RuntimeError | TimeoutError | null;
  };
  close: () => void;
}

const ReportModal = ({ error, close }: Props) => {
  const { mutate: sendSlackMessage, isLoading } = useSlackMessage();

  const getAdditionalInfo = (
    error: ApiError | RuntimeError | TimeoutError | null
  ): SlackMessageInfo['additionalInfo'] | undefined => {
    if (error instanceof ApiError) {
      return {
        type: 'ApiError',
        method: error.getMethod() as Method,
        url: error.getRequestData().url ?? '',
        status: error.getStatusCode() as number,
      };
    } else if (error instanceof RuntimeError) {
      return {
        type: 'RuntimeError',
        runtimeType: error.getErrorType(),
      };
    } else if (error instanceof TimeoutError) {
      return {
        type: 'TimeoutError',
        duration: error.getTimeoutDuration() ?? 0,
      };
    }

    return undefined;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        const formData = new FormData(e.target as HTMLFormElement);
        const reportContent = (formData.get('reportContent') ?? '내용이 없습니다.') as string;
        const reporter = (formData.get('reporter') ?? '') as string;

        sendSlackMessage(
          {
            reportContent,
            mentionIds: reporter,
            errorType: error.info !== null ? error.info.getErrorName() : 'Unknown Error',
            errorMessage: error.message,
            timestamp: new Date().toISOString(),
            errorPath: error.info?.getComponentStack() ?? '',
            additionalInfo: getAdditionalInfo(error.info),
          },
          {
            onSuccess: () => {
              close();
            },
          }
        );
      }}
    >
      <ModalForm width="544px" padding="30px" backgroundOnPress={close}>
        <ReportModalContainer>
          <Row justifyContent="flex-start" gap="10px">
            <StyledText text="에러사항을 입력해주세요" fontWeight="bold" fontSize="xl2" />
            <Icon type="question_circle" />
          </Row>
          <ReportInputField placeholder="에러사항을 입력해주세요" name="reportContent" />
          <StyledText text="보고자" fontWeight="bold" fontSize="xl2" />
          <ReportInputField
            placeholder="보고자의 Slack 이름을 입력해주세요"
            style={{ height: '30px', display: 'flex', alignItems: 'center' }}
            as="input"
            name="reporter"
          />
          <ModalButtonWrapper>
            <NormalButton text="닫기" width="180px" styleType="ghost" sizeType="medium" onPress={close} />
            <NormalButton text="보내기" sizeType="medium" type="submit" isLoading={isLoading} />
          </ModalButtonWrapper>
        </ReportModalContainer>
      </ModalForm>
    </form>
  );
};

export default ReportModal;

const ReportModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
`;

const ReportInputField = styled.textarea`
  height: 361px;
  width: 100%;
  border: 1.5px solid ${color.greyd4};
  border-radius: 4px;
  padding: 14px;

  &::placeholder {
    color: ${color.greyd4};
  }
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;
