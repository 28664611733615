import { Fragment } from 'react';

import { t } from 'i18next';
import { useSelector } from 'react-redux';

import TextInput from 'components/common/input/customMuiInput/TextInput';

import { selectAddress } from 'features/redux/selectors/address';

import { Messaging, MessagingStatus } from 'types/bulkMessge';

interface Props {
  bulkMessage: Messaging;
}

const BulkMessageCampaignInfo = ({ bulkMessage }: Props) => {
  const { content_type, object_id, status, content_object } = bulkMessage;
  const { title, branch } = content_object;
  const statusType = MessagingStatus[status] as keyof typeof MessagingStatus;
  const { country } = useSelector(selectAddress);
  const c = country?.find((c) => c.id === branch);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <Fragment>
      <TextInput label={t('chat.bulkMessage-detail-branch')} disabled value={c?.name} />
      <TextInput
        label={t('chat.bulkMessage-detail-campaignType')}
        disabled
        value={content_type === 74 ? 'Quest' : content_type === 85 ? 'Benefit' : '-'}
      />
      <TextInput label={t('chat.bulkMessage-detail-campaignID')} disabled value={object_id} />
      <TextInput label={t('chat.bulkMessage-detail-campaignTitle')} disabled value={title} />
      <TextInput label={t('chat.bulkMessage-detail-status')} disabled value={capitalizeFirstLetter(statusType)} />
    </Fragment>
  );
};

export default BulkMessageCampaignInfo;
