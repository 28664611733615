import styled from 'styled-components';
import { Message } from 'talkplus-sdk';

import { useChat } from 'hooks/chat/queries';

import StyledText from 'components/common/text/StyledText';

import { color } from 'styles/assets';

interface Props {
  message: Message;
}

const WelcomeMessage = ({ message }: Props) => {
  const { useGetChannel } = useChat();
  const { data } = useGetChannel({ channelId: message?.channelId });

  return (
    <Container>
      <Upper>
        <StyledText fontSize="base" fontWeight="bold" text={data?.channel.name} fontColor="white" textAlign="center" />
      </Upper>
      <Lower>
        <StyledText fontSize="base" fontWeight="medium" text={message?.text} textAlign="center" />
      </Lower>
    </Container>
  );
};

export default WelcomeMessage;

const Container = styled.div`
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${color.greye4};
  margin-bottom: 20px;
`;

const Upper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: ${color.violet39};
`;

const Lower = styled.div`
  padding: 20px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
