import { ChannelStatusKeys, ChannelStatusValues } from 'types/chat/internal';

//sendbird 제거하면 제거 될 상수
export const chatStatusMessages = {
  APPLY: 'new apply',
  DENIED: 'request denied',
  ACCEPTED: 'request accepted',
  COMPLETED: 'Collaboration closed.',
  DROPPED: 'Collaboration dropped.',
  CANCELED: 'Apply canceled.',
  PRIVATE_STARTED: 'Started a Chat!',
  PRIVATE_END: 'The Chat has Closed.',
} as const;

//sendbird 제거하면 제거 될 상수
export const channelStatusKeys: Record<ChannelStatusKeys, ChannelStatusValues> = {
  APPLY: 'apply',
  DENIED: 'denied',
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  DROPPED: 'dropped',
  PROJECT: 'project',
  PRIVATE: 'private',
};

//talkplus 전용
export enum ChannelCategoryEnum {
  QUEST = '1',
  BENEFIT = '2',
  PRIVATE = '3',
  GROUP = '4',
}

export enum MessageDataTypeEnum {
  ACCEPTED = '1',
  COMPLETED = '2',
  DROPPED = '3',
  GROUP_START = '4',
  GROUP_JOIN = '5',
  GROUP_END = '6',
  PRIVATE_START = '7',
  PRIVATE_END = '8',
  DEEPLINK = '9',
}

export enum ChannelStatusEnum {
  ACCEPTED = '1',
  DROPPED = '2',
  COMPLETED = '3',
}
