import dayjs from 'dayjs';

export const getDate = (date?: string | number | Date | dayjs.Dayjs | null, format?: string) => {
  return dayjs(date).format(format ? format : 'MM/DD/YYYY');
};

export const getDateTime = (date?: string | number | Date | dayjs.Dayjs | null, format?: string) => {
  return dayjs(date).format(format ? format : 'MM/DD/YYYY hh:mmA');
};

export const getTime = (date?: string | number | Date | dayjs.Dayjs | null) => {
  return dayjs(date).format('hh:mm A');
};

export const getTimeFromNow = (date?: string | number | Date | dayjs.Dayjs | null) => {
  return dayjs(date).fromNow();
};

export const getDateTimeKr = (date?: string | number | Date | dayjs.Dayjs | null) => {
  return dayjs(date).format('YYYY년 MM월 DD일 HH:mm:ss');
};

export const getDatePoint = (date?: string | number | Date | dayjs.Dayjs | null) => {
  return dayjs(date).format('YYYY.MM.DD');
};

export const getDateTimePoint = (date?: string | number | Date | dayjs.Dayjs | null) => {
  return dayjs(date).format('YYYY.MM.DD HH:mm');
};
