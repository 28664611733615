import { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Message } from 'talkplus-sdk';

import { chatKeys, useChat } from 'hooks/chat/queries';

import Icon from 'components/Icons/Icon';
import Avatar from 'components/common/Avatar';
import StyledText from 'components/common/text/StyledText';

import { AppContext } from 'features/redux/context';
import { selectAccount } from 'features/redux/selectors/accounts';

import { color } from 'styles/assets';

interface Props {
  message: Message;
  dateDisplay: boolean;
  timeDisplay: boolean;
  profileDisplay: boolean;
}

const UserMessage = (props: Props) => {
  const { message, timeDisplay, profileDisplay } = props;
  const { talkPlus } = useContext(AppContext);
  const { useGetChannel, useMarkAsRead } = useChat();
  const { mutate: readMessage } = useMarkAsRead();
  const { data } = useGetChannel({ channelId: message.channelId });
  const account = useSelector(selectAccount);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const isOwnerOfGroupChat = message.username === data?.channel.ownerId && data?.channel.category === '5';
  const isMyMessage = message.userId === account.userInfo.user.id.toString();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!data?.channel) return;

    setUnreadCount(talkPlus.getMessageUnreadCount({ channel: data?.channel, message }));

    const handleEvent = (e: any) => {
      // 새 메시지 전송 시에는 messageId가 일치할 때만 업데이트
      if (e.type === 'message') {
        readMessage(
          {
            channelId: message.channelId,
          },
          {
            onSuccess() {
              queryClient
                .invalidateQueries(chatKeys.messages(message.channelId), {
                  exact: true, // 정확한 쿼리 키만 무효화
                  refetchActive: true, // 자동 리페치 방지
                })
                .then(() => {
                  setUnreadCount(talkPlus.getMessageUnreadCount({ channel: e.channel, message }));
                });
            },
          }
        );
      }
      // 채널 상태 변경 시에는 모든 메시지의 unreadCount 업데이트
      else if (e.type === 'channelChanged' && e.channel.id === message.channelId) {
        setUnreadCount(talkPlus.getMessageUnreadCount({ channel: e.channel, message }));
      }
    };

    talkPlus.on('event', handleEvent);

    return () => {
      talkPlus.off('event', handleEvent); // 이벤트 리스너 제거
    };
  }, []);

  useEffect(() => {
    if (!data?.channel) return;

    setUnreadCount(talkPlus.getMessageUnreadCount({ channel: data.channel, message }));
  }, [data, message, talkPlus]);

  return (
    <Container
      data-testid="user-message-container"
      isMyMessage={isMyMessage}
      profileDisplay={profileDisplay}
      timeDisplay={timeDisplay}
    >
      {!isMyMessage && (
        <ImageWrapper data-testid="profile-image">
          {profileDisplay ? (
            <Avatar
              avatarSize="s"
              src={data?.channel?.members?.find((member) => member.id === message.userId)?.profileImageUrl}
            />
          ) : null}
        </ImageWrapper>
      )}
      <MessageAndNick>
        {!isMyMessage && profileDisplay ? (
          <NickWrapper>
            {isOwnerOfGroupChat ? (
              <Circle data-testid="host-sign">
                <Icon type="star" fill="white" width={12} height={12} />
              </Circle>
            ) : null}
            <StyledText fontSize="sm" fontWeight="medium" text={message.username} numberOfLines={1} />
          </NickWrapper>
        ) : null}
        <ExceptProfile isMyMessage={isMyMessage}>
          {message.fileUrl ? (
            <ImageMessageWrapper isMyMessage={isMyMessage}>
              <ImageMessage src={message.fileUrl} />
            </ImageMessageWrapper>
          ) : (
            <MessageContainer isMyMessage={isMyMessage}>
              <MessageText>{message.text}</MessageText>
            </MessageContainer>
          )}
          <DateAndStatus isMyMessage={isMyMessage}>
            {Number(unreadCount) > 0 ? (
              <StyledText fontSize="xs" fontWeight="medium" text={String(unreadCount)} fontColor="violet4c" />
            ) : null}
            {timeDisplay && (
              <StyledText
                text={dayjs(message.createdAt).format('h:mm A')}
                fontSize="xs"
                fontWeight="regular"
                fontColor="greya1"
                numberOfLines={1}
              />
            )}
          </DateAndStatus>
        </ExceptProfile>
      </MessageAndNick>
    </Container>
  );
};

export default UserMessage;

const Container = styled.div<{ isMyMessage: boolean; profileDisplay: boolean; timeDisplay: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isMyMessage ? 'flex-end' : 'flex-start')};
  margin-bottom: ${(props) => (props.timeDisplay ? '20px' : '6px')};
  margin-top: ${(props) => (props.profileDisplay ? '12px' : '0')};
`;

const ImageWrapper = styled.div`
  width: 40px;
  aspect-ratio: 1;
  margin-right: 6px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

const MessageContainer = styled.div<{ isMyMessage: boolean }>`
  max-width: calc(100% - 100px);
  padding: 11px 14px;
  border-radius: 20px;
  background-color: ${(props) => (props.isMyMessage ? color.greyf4 : color.violetf4)};
  margin: ${(props) => (props.isMyMessage ? '0 0 0 4px' : '0 4px 0 0')};
`;

const ImageMessageWrapper = styled.div<{ isMyMessage: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 11px;
  background-color: ${(props) => (props.isMyMessage ? color.greyf4 : color.violetf4)};
`;

const ImageMessage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
`;

const MessageText = styled.pre`
  width: 100%;
  word-break: break-word;
  white-space: pre-wrap;
`;

const ExceptProfile = styled.div<{ isMyMessage: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMyMessage ? 'row-reverse' : 'row')};
  align-items: flex-end;
`;

const DateAndStatus = styled.div<{ isMyMessage: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMyMessage ? 'flex-end' : 'flex-start')};
`;

const MessageAndNick = styled.div`
  width: calc(100% - 46px);
`;

const NickWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`;

const Circle = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${color.violet39};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;
