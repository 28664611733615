import { t } from 'i18next';
import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

import { color } from 'styles/assets';

const PrivateStartedMsg = () => {
  return (
    <Container>
      <Upper>
        <Icon type="jelly_colored" />
        <Gap side={5} />
        <StyledText
          fontSize="base"
          fontWeight="bold"
          text={t('chat.msg-notice-privateStarted-title')}
          fontColor="white"
          textAlign="center"
        />
        <Gap side={8} />
        <Icon type="jelly_colored" />
      </Upper>
      <Lower>
        <StyledText
          fontSize="base"
          fontWeight="medium"
          text={t('chat.msg-notice-privateStarted-description1')}
          textAlign="center"
        />
        <StyledText
          fontSize="base"
          fontWeight="medium"
          text={t('chat.msg-notice-privateStarted-description2')}
          textAlign="center"
        />
      </Lower>
    </Container>
  );
};

export default PrivateStartedMsg;

const Container = styled.div`
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${color.greye4};
  margin-bottom: 20px;
`;

const Upper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: ${color.violet39};
`;

const Lower = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
