import styled from 'styled-components';
import { Message } from 'talkplus-sdk';

import StyledText from 'components/common/text/StyledText';

interface Props {
  message: Message | undefined;
}
const EndMessage = ({ message }: Props) => {
  return (
    <Wrapper>
      <StyledText
        fontSize="sm"
        fontWeight="semiBold"
        text={message?.data?.['message']}
        textAlign="center"
        fontColor="grey71"
      />
    </Wrapper>
  );
};

export default EndMessage;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
