import ChatStaffContainer from 'containers/chat/ChatStaffContainer';

import DashboardLayout from 'components/layout/dashboard/DashboardLayout';

const ChatStaffPage = () => {
  return (
    <DashboardLayout headerTitle="Chat Staff">
      <ChatStaffContainer />
    </DashboardLayout>
  );
};

export default ChatStaffPage;
