/* eslint-disable @typescript-eslint/no-unused-vars */
import { GroupChannel, GroupChannelUpdateParams, SendbirdGroupChat } from '@sendbird/chat/groupChannel';
import { UserMessageCreateParams } from '@sendbird/chat/message';
import { channelStatusKeys, chatStatusMessages } from 'constants/chatConstants';

import { acceptApply, completeExchangeApi, refundDueToDeclining, updateChatId } from 'api/chat/back';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { ConnectSendbirdAndNuriUser } from 'components/pages/chat/groupChat/sendbird/create/SelectMember';

import {
  AcceptApplyRequestPayload,
  CompleteApplyPayload,
  DenyApplyRequestPayload,
  UpdateSendbirdProfilePayload,
} from 'types/chart/remote';

export async function acceptApplyRequest({
  access_token,
  sendbird,
  channel,
  errHappenAction,
  refresh,
}: AcceptApplyRequestPayload) {
  const channelData = await JSON.parse(channel.data as string);
  const { connectChatAndArticleId, id, stateId, category } = await channelData;
  await acceptApply(access_token, id, stateId, category)
    .then(() => {
      updateChatId(access_token, { status: 2 }, connectChatAndArticleId)
        .then(async () => {
          const params: GroupChannelUpdateParams = {};
          params.customType = await channelStatusKeys.ACCEPTED;

          await channel
            .updateChannel({ customType: channelStatusKeys.ACCEPTED })
            .then((channel) => {
              sendbirdAdminMsg(sendbird, channel, chatStatusMessages.ACCEPTED, refresh);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err: any) => console.log(err.response));
    })
    .catch((err) => errHappenAction(errToErrMsg(err.response)));
}

export async function denyApplyRequest({
  access_token,
  sendbird,
  channel,
  errHappenAction,
  refresh,
}: DenyApplyRequestPayload) {
  const channelData = JSON.parse(channel.data as string);
  const { connectChatAndArticleId, id, stateId, category } = channelData;
  await refundDueToDeclining(access_token, id, stateId, category)
    .then((res) => {
      updateChatId(access_token, { status: 0 }, connectChatAndArticleId)
        .then(async () => {
          const params: GroupChannelUpdateParams = {};
          params.customType = await channelStatusKeys.DENIED;

          await channel
            .updateChannel(params)
            .then((channel) => {
              sendbirdAdminMsg(sendbird, channel, chatStatusMessages.DENIED, refresh);
            })
            .catch((err) => {
              console.log(err);
              throw err;
            });
        })
        .catch((err: any) => console.log(err.response));
    })
    .catch((err) => errHappenAction(errToErrMsg(err.response)));
}

export async function completeApply({ access_token, sendbird, channel, dispatch, refresh }: CompleteApplyPayload) {
  const channelData = JSON.parse(channel.data as string);
  const { connectChatAndArticleId, id, stateId, category } = channelData;

  const confirmList = 'confirmList' in channelData ? channelData.confirmList : [];
  const myId = sendbird?.currentUser?.userId;
  const didIConfirmed = confirmList.includes(myId);

  if (didIConfirmed) return;

  const newConfirmList = [...confirmList, myId];
  const newData = { ...channelData, confirmList: newConfirmList };

  const bothConfirm = newConfirmList.length === 2;

  const params: GroupChannelUpdateParams = {};
  params.data = JSON.stringify(newData);

  try {
    switch (category) {
      case 'spend':
      case 'earn': {
        params.customType = channelStatusKeys.COMPLETED;

        await completeExchangeApi(access_token, id, stateId, dispatch, category).then(() => {
          updateChatId(access_token, { status: 5 }, connectChatAndArticleId);
          channel.updateChannel(params).then(() => {
            sendbirdAdminMsg(sendbird, channel, chatStatusMessages.COMPLETED, refresh);
          });
        });
        break;
      }
      case 'jelly': {
        if (bothConfirm) params.customType = channelStatusKeys.COMPLETED;

        await completeExchangeApi(access_token, id, stateId, dispatch, category).then(() => {
          updateChatId(access_token, { status: bothConfirm ? 5 : 4 }, connectChatAndArticleId);
          channel.updateChannel(params).then(() => {
            if (bothConfirm) sendbirdAdminMsg(sendbird, channel, chatStatusMessages.COMPLETED, refresh);
          });
        });

        break;
      }
      default:
        return;
    }

    return;
  } catch (err) {
    console.log(err);
  }
}

export async function sendbirdAdminMsg(sendbird: SendbirdGroupChat, channel: GroupChannel, msg: string, refresh?: any) {
  const params: UserMessageCreateParams = {
    message: msg,
    customType: 'notice',
  };
  channel
    .sendUserMessage(params)
    .onPending((message: any) => {})
    .onSucceeded((message: any) => {
      channel.refresh();
      if (refresh) refresh();
    })
    .onFailed((message) => console.log(message));
}

export async function sendbirdWelcomeMsg(sendbird: SendbirdGroupChat, channel: GroupChannel, msg: string) {
  const params: UserMessageCreateParams = {
    message: msg,
    customType: 'welcome',
  };
  channel
    .sendUserMessage(params)
    .onPending((message: any) => {})
    .onSucceeded((message: any) => {
      channel.refresh();
    })
    .onFailed((message) => console.log(message));
}

export async function sendbirdJoinMessage(
  sendbird: SendbirdGroupChat,
  channel: GroupChannel,
  users: ConnectSendbirdAndNuriUser[]
) {
  const params: UserMessageCreateParams = {
    message: `${users.map((el) => el.nickname || el.userId).join(', ')} joined this chatroom.`,
    customType: 'join',
  };
  const pendingMessage = channel
    .sendUserMessage(params)
    .onPending((message: any) => {})
    .onSucceeded((message: any) => {})
    .onFailed((message) => console.log(message));
}

export async function sendbirdExitChannel(
  sendbird: SendbirdGroupChat,
  channel: GroupChannel,
  user: ConnectSendbirdAndNuriUser
) {
  const params: UserMessageCreateParams = {
    message: `${user.nickname || user.userId} left this chatroom.`,
    customType: 'join',
  };
  channel.sendUserMessage(params);
}

export function sendPinnedMessage(channel: GroupChannel, message: string) {
  const params: UserMessageCreateParams = {
    message,
    isPinnedMessage: true,
  };

  return channel.sendUserMessage(params);
}

export function sendDeepLinkMessage(channel: GroupChannel, data: string) {
  const params: UserMessageCreateParams = {
    message: 'New Campaign!',
    customType: 'deepLink',
    isPinnedMessage: true,
    data,
  };

  return channel.sendUserMessage(params);
}

export async function unPinAllMessage(channel: GroupChannel, messageIds: number[]) {
  await messageIds.map(async (el) => await channel.unpinMessage(el));
}

export const connectSendbird = async ({
  sendbird,
  refresh,
  dispatch,
  username,
}: {
  sendbird: SendbirdGroupChat;
  username: string;
  refresh?: () => void;
  dispatch?: any;
}) => {
  if (sendbird.currentUser || !username) return;

  if (username === 'NuriLounge') {
    return await sendbird
      .connect(username, process.env.REACT_APP_SENDBIRD_NURILOUNGE_TOKEN)
      .then((user) => {
        if (refresh) refresh();

        return user;
      })
      .catch((err) => {
        if (dispatch) dispatch({ type: 'error', payload: 'Chat connection failed.' });
      });
  } else if (username === 'nurihaus_admin') {
    return await sendbird
      .connect(username, process.env.REACT_APP_SENDBIRD_NURIHAUSADMIN_TOKEN)
      .then((user) => {
        if (refresh) refresh();

        return user;
      })
      .catch((err) => {
        if (dispatch) dispatch({ type: 'error', payload: 'Chat connection failed.' });
      });
  } else {
    return await sendbird
      .connect(username)
      .then((user) => {
        if (refresh) refresh();

        return user;
      })
      .catch((err) => {
        if (dispatch) dispatch({ type: 'error', payload: 'Chat connection failed.' });
      });
  }
};

export const updateSendbirdProfile = async ({ sendbird, nickname, profile }: UpdateSendbirdProfilePayload) => {
  await sendbird.updateCurrentUserInfo({ nickname, profileUrl: profile }).then((user) => {});
};
