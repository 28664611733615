import Slider from 'react-slick';
import styled from 'styled-components';

interface Props {
  images: (string | null)[];
}

const ImageSlider = ({ images }: Props) => {
  const settings = {
    rows: 1,
    infinite: false,
    draggable: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
  };

  return (
    <Slider {...settings}>
      {images
        .filter((data: any) => data !== null)
        .map((image: any, idx: number) => {
          return <SingleImg data-testid="slider-image" key={idx} src={image} alt="" />;
        })}
    </Slider>
  );
};

export default ImageSlider;

const SingleImg = styled.img`
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
`;
