import { ChangeEvent } from 'react';

import styled from 'styled-components';
import { t } from 'translate/i18next/i18next';

import StyledText from 'components/common/text/StyledText';

import { color } from 'styles/assets';

interface Props {
  inputHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ImageLabel = ({ inputHandler }: Props) => {
  return (
    <InputLabel>
      <StyledText fontSize="base" fontWeight="semiBold" text={t('banner.edit-info-image-btn')} fontColor="white" />
      <InvisibleInput
        data-testid="image-invisible-input"
        type="file"
        style={{ display: 'none' }}
        accept="image/png, image/jpeg"
        onChange={inputHandler}
        name="image"
      />
    </InputLabel>
  );
};

export default ImageLabel;

const InputLabel = styled.label`
  width: 170px;
  height: 48px;
  border-radius: 6px;
  background-color: ${color.violet39};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer;
    background-color: ${color.violet4c};
  }
`;

const InvisibleInput = styled.input``;
