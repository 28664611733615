import { useRef, useState } from 'react';

import styled from 'styled-components';
import { MessageTypeEnum } from 'talkplus-sdk';

import { useChat } from 'hooks/chat/queries';

import Icon from 'components/Icons/Icon';
import MenuIcon from 'components/Icons/MenuIcon';

import { color } from 'styles/assets';

const ChatInput = ({ channelId }: { channelId: string }) => {
  const { useSendMessage, useGetChannel } = useChat();
  const { data } = useGetChannel({ channelId });
  const { mutate: send } = useSendMessage();
  const [inputText, setInputText] = useState('');
  const TextAreaInput = useRef<HTMLTextAreaElement>(null);

  const isTextFieldDisabled = data?.channel.isFrozen || false;

  const getPlaceholderText = () => {
    const channelStatus = data?.channel.isFrozen;
    switch (channelStatus) {
      case false: {
        return 'Shift+Enter to add a new line.';
      }
      case true: {
        return 'Conversation closed.';
      }

      default: {
        return 'Shift+Enter to add a new line.';
      }
    }
  };

  return (
    <ChatInputWrapper data-testid="chat-input-section">
      <FileInputWrapper>
        <MenuIcon type="plus" />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            if (!e.target.files) return;

            send({
              channelId,
              type: MessageTypeEnum['Text'],
              text: 'File message sent.',
              file: e.target.files[0],
            });
          }}
          disabled={isTextFieldDisabled}
        />
      </FileInputWrapper>
      <TextInputWrapper>
        <ChatTextInput
          ref={TextAreaInput}
          rows={1}
          onKeyDown={(e) => {
            if (e.nativeEvent.isComposing) {
              return;
            }

            if (e.key === 'Enter' && e.shiftKey) {
              return;
            } else if (e.key === 'Enter') {
              e.preventDefault();
              send(
                {
                  channelId,
                  type: MessageTypeEnum['Text'],

                  text: inputText,
                },
                {
                  onSettled() {
                    if (!TextAreaInput.current) return;

                    TextAreaInput.current.value = '';
                  },
                }
              );
            }
          }}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
          maxLength={1000}
          disabled={isTextFieldDisabled}
          placeholder={getPlaceholderText()}
        />
        <SendButton
          onClick={() => {
            send(
              {
                channelId,
                type: MessageTypeEnum['Text'],
                text: inputText,
              },
              {
                onSettled() {
                  if (!TextAreaInput.current) return;

                  TextAreaInput.current.value = '';
                },
              }
            );
          }}
          disabled={isTextFieldDisabled}
        >
          <Icon type="send" fill={isTextFieldDisabled ? 'greya1' : 'violet39'} />
        </SendButton>
      </TextInputWrapper>
    </ChatInputWrapper>
  );
};

export default ChatInput;

const ChatInputWrapper = styled.div`
  width: 100%;
  padding: 11px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-top: 1px solid ${color.greye4};
`;

const FileInputWrapper = styled.label`
  cursor: pointer;
  display: inline-block;
  :disabled {
    cursor: not-allowed;
  }
  input {
    display: none;
  }
`;

const TextInputWrapper = styled.div`
  min-height: 50px;
  width: calc(100% - (24px + 16px));
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${color.greyfa};
  border-radius: 4px;
`;

const ChatTextInput = styled.textarea`
  width: 100%;
  height: auto;
  padding: 14px calc(13px + 24px + 13px) 14px 12px;
  resize: none;
`;

const SendButton = styled.button<{ disabled: boolean }>`
  position: absolute;
  right: calc(13px);

  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;
