import { CssBaseline, ThemeProvider as OriginalThemeProvider, createTheme } from '@mui/material';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import GlobalStyle from 'styles/assets/GlobalStyle';

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const THEME = createTheme({
    typography: {
      fontFamily: "'Pretendard', sans-serif",
      allVariants: {
        fontFamily: "'Pretendard', sans-serif",
      },
    },
  });

  return (
    <OriginalThemeProvider theme={THEME}>
      <GlobalStyle />
      <CssBaseline />
      {children}
    </OriginalThemeProvider>
  );
};
