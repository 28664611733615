import { useEffect } from 'react';

import { useOverlay } from '@nurihaus/use-overlay';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { useEditBulkMessage } from 'hooks/chat/bulkMessage/useBulkMessage';

import NormalButton from 'components/common/button/NormalButton';
import PageLoading from 'components/common/loading/PageLoading';
import BulkMessageCancelModal from 'components/pages/chat/bulkMessage/headerButtons/BulkMessageCancelModal';

import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

import { Messaging } from 'types/bulkMessge';

interface Props {
  bulkMessage: Messaging;
}

const BulkMessageDetailHeaderEditButtons = ({ bulkMessage }: Props) => {
  const overlay = useOverlay();
  const { id, object_id, content_type } = bulkMessage;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const onSuccess = (res: Messaging) => {
    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: 'Success',
        severity: 'success',
      })
    );
    queryClient.setQueryData(['bulkMessage', id], {
      ...bulkMessage,
      message: res.message,
      send_type: res.send_type,
      schedule: res.schedule,
      user_status: res.user_status,
      status: res.status,
    });
    navigate(`/chat/bulk-message/${id}`);
  };

  const onError = (err: any) => {
    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: errToErrMsg(err?.response),
        severity: 'error',
      })
    );
  };

  const { mutate, isLoading } = useEditBulkMessage({
    id,
    onSuccess,
    onError,
  });

  const OpenCancelModal = () => {
    overlay.open(({ isOpen, close }) => <BulkMessageCancelModal isOpen={isOpen} close={close} id={id} />);
  };

  const edit = () => {
    const targetOptions = Array.from(document.getElementsByName('target')) as unknown as HTMLInputElement[];
    const target = targetOptions.find((el) => el.checked)?.value;
    const content = (document.getElementsByName('content')[0] as HTMLInputElement).value;
    const sendTimeOptions = Array.from(document.getElementsByName('sendTime')) as unknown as HTMLInputElement[];
    const sendTime = sendTimeOptions.find((el) => el.checked)?.value as string;
    const scheduleDate = (document.getElementsByName('schedule-time')[0] as HTMLInputElement).value;
    const schedule = new Date(dayjs(scheduleDate).format('YYYY-MM-DDTHH:mm:ss'));
    mutate({
      sendTime,
      content,
      target,
      schedule,
      object_id,
      content_type,
    });
  };

  useEffect(() => {
    if (isLoading) {
      overlay.open(() => <PageLoading />);

      return () => overlay.close();
    }
  }, [isLoading]);

  return (
    <ButtonGroup>
      <NormalButton
        width="190px"
        styleType="ghost"
        sizeType="medium"
        text={t('chat.bulkMessage-detail-btn-cancel')}
        onPress={OpenCancelModal}
      />
      <NormalButton width="190px" sizeType="medium" text={t('chat.bulkMessage-detail-btn-save')} onPress={edit} />
    </ButtonGroup>
  );
};

export default BulkMessageDetailHeaderEditButtons;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
