import { ChangeEvent } from 'react';

import { useEncodeFileToBase64WithName } from '@nurihaus/react';
import styled from 'styled-components';

import ImageLabel from 'components/common/button/ImageLabel';

import { color } from 'styles/assets';

interface Props {
  img: string | File | null;
  imgHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  height?: string;
}

const ImageInput = ({ img, imgHandler, width = '230px', height = '230px' }: Props) => {
  const { src: imgSrc } = useEncodeFileToBase64WithName(img);

  return (
    <Container width={width} height={height}>
      {img ? (
        <ImageWrapper>
          <Image src={imgSrc} data-testid="preview-img" />
          <input
            accept="image/*"
            type="file"
            style={{ display: 'none' }}
            onChange={imgHandler}
            data-testid="image-invisible-input"
          />
        </ImageWrapper>
      ) : (
        <ImageLabel inputHandler={imgHandler} />
      )}
    </Container>
  );
};

export default ImageInput;

const Container = styled.div<{ width: string; height: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${color.greyf4};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.label`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  :hover {
    opacity: 0.95;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
`;
