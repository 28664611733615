import styled from 'styled-components';

import { calculateJellyTax } from 'utils/campaign/calculateJellyTax';
import { addCommaToNumber } from 'utils/common/addCommaToNumber';
import { formatDateToDDMMYYYY } from 'utils/common/dateFormat';
import { getCampaignStatus } from 'utils/common/getCampaignStatus';

import Icon from 'components/Icons/Icon';
import SocialIcon from 'components/Icons/SocialIcon';
import SectionLine from 'components/common/SectionLine';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import CopyrightsText from 'components/pages/campaign/CopyrightsText';
import TermsAndConditionsText from 'components/pages/campaign/TermsAndConditionsText';
import DetailedContents from 'components/pages/campaign/detail/viewCardModal/DetailedContents';
import CampaignCardTimeLine from 'components/pages/campaign/detail/viewCardModal/TimeLine';

import { color } from 'styles/assets';

import { Campaign, CampaignRouteParams, CampaignStatus } from 'types/campaign/internal';

interface Props {
  detailData: Campaign;
  params: CampaignRouteParams;
}

const DetailsSection = ({ detailData, params }: Props) => {
  const { campaign } = params;
  const isExpired = getCampaignStatus(detailData) !== 'Active';
  const { is_ugc = false } = detailData;

  return (
    <DescriptionSection>
      <Description detailData={detailData} isExpired={isExpired} campaign={campaign} />
      <Wrapper>
        <Gap side={20} />
        <CopyrightsText isUGC={is_ugc} />
        <TermsAndConditionsText campaign={campaign as 'quest' | 'benefit'} isUGC={is_ugc} />
        <Gap side={56} />
        <Footer detailData={detailData} />
      </Wrapper>
    </DescriptionSection>
  );
};

const Description = ({
  detailData,
  isExpired,
  campaign,
}: {
  detailData: Campaign;
  isExpired: boolean;
  campaign?: 'quest' | 'benefit';
}) => {
  const {
    contents,
    date_from,
    date_to,
    point,
    apply_count,
    max_count,
    social_media_platforms,
    selection_start_at,
    selection_end_at,
    estimated_receipt_start_at,
    estimated_receipt_end_at,
    result_deadline,
  } = detailData;
  const isBenefit = campaign === 'benefit';
  const campaignStatus = getCampaignStatus(detailData);

  const timeLineData = {
    date_from,
    date_to,
    selection_start_at,
    selection_end_at,
    estimated_receipt_start_at,
    estimated_receipt_end_at,
    result_deadline,
  };

  return (
    <DescriptionContainer data-testid="description">
      <Wrapper>
        <CampaignCardTimeLine isExpired={isExpired} timeLineData={timeLineData} />
        {isBenefit && <DetailTax tax={calculateJellyTax(point)} isExpired={isExpired} />}
        <Gap side={10} />
        {!isBenefit && (
          <DetailSocialAndApply
            apply_count={apply_count || 0}
            max_count={max_count}
            campaignStatus={campaignStatus}
            platforms={social_media_platforms}
          />
        )}
      </Wrapper>
      <SectionLine height="6px" backgroundColor={color.greyf4} marginBottom="30px" marginTop="30px" />
      <Wrapper>
        {contents ? (
          <StyledText text={contents} fontWeight="regular" />
        ) : (
          <DetailedContents
            campaignInfo={detailData.campaign_info}
            reward={
              campaign === 'quest'
                ? {
                    point: detailData.point,
                    reward_price: detailData.reward_price,
                    currency: (detailData as any)?.currency,
                  }
                : undefined
            }
          />
        )}
      </Wrapper>
    </DescriptionContainer>
  );
};

const DetailSocialAndApply = ({
  apply_count,
  max_count,
  campaignStatus,
  platforms = '',
}: {
  apply_count: number;
  max_count: number;
  campaignStatus: CampaignStatus;
  platforms: string;
}) => {
  const appliedStatusText = `${addCommaToNumber(apply_count)} / ${max_count}`;
  const isActive = campaignStatus === 'Active';
  const textAndIconColor = isActive ? 'black' : 'greyd4';

  const platformList = ['tiktok', 'instagram', 'youtube'] as const;
  type SocialPlatform = typeof platformList[number];

  const socialPlatforms: SocialPlatform[] = platforms
    .split(',')
    .filter((platform): platform is SocialPlatform => platformList.includes(platform as SocialPlatform));

  const sortedPlatforms = socialPlatforms.sort((a, b) => {
    return platformList.indexOf(a) - platformList.indexOf(b);
  });

  return (
    <SocialAndApplyContainer>
      <ItemWrapper>
        <TitleTextWrapper>
          <Icon type={isActive ? 'global' : 'global_disabled'} />
          <Gap side={5} />
          <StyledText text="Social Media" fontSize="sm" fontColor={textAndIconColor} />
        </TitleTextWrapper>
        {socialPlatforms.length === 0 ? (
          <StyledText text="-" fontColor={textAndIconColor} />
        ) : (
          <IconWrapper>
            {sortedPlatforms.map((platform: 'tiktok' | 'instagram' | 'youtube', index: number) => {
              return <SocialIcon key={index} type={platform} width={22} height={22} fill={textAndIconColor} />;
            })}
          </IconWrapper>
        )}
      </ItemWrapper>
      <Gap side={14} />
      <ItemWrapper>
        <TitleTextWrapper>
          <Icon type="users" fill={textAndIconColor} />
          <Gap side={5} />
          <StyledText text="Apply" fontSize="sm" fontColor={textAndIconColor} />
        </TitleTextWrapper>
        {max_count === 99999 ? (
          <Icon type="infinite" fill="greya1" width={20} height={20} />
        ) : (
          <StyledText text={appliedStatusText} fontSize="sm" fontColor={textAndIconColor} />
        )}
      </ItemWrapper>
    </SocialAndApplyContainer>
  );
};

const DetailTax = ({ tax, isExpired }: { tax: number; isExpired: boolean }) => {
  const textColor = isExpired ? 'greyd4' : 'black';

  return (
    <TaxContainer>
      <TaxTitleContainer>
        {isExpired ? <Icon type="jelly_disabled" /> : <Icon type="jelly_colored" />}
        <Gap side={5} />
        <StyledText text="Jelly Tax" fontSize="sm" fontColor={textColor} />
        <Gap side={4} />
      </TaxTitleContainer>
      <StyledText text={String(tax)} fontSize="sm" fontColor={textColor} />
    </TaxContainer>
  );
};

const Footer = ({ detailData }: { detailData: Campaign }) => {
  return (
    <FooterWrapper>
      <StyledText text={formatDateToDDMMYYYY(detailData.created)} fontSize="xs" fontColor="greya1" />

      <FooterInfoWrapper>
        <FooterInfoInner>
          <Icon type="eye" width={18} height={18} fill="greya1" />
          <Gap side={4} />
          <StyledText text={String(detailData.view_count)} fontSize="xs" fontWeight="regular" fontColor="greya1" />
        </FooterInfoInner>
        <Gap side={10} />
        <FooterInfoInner>
          <Icon type="heart" width={18} height={18} fill="greya1" />
          <Gap side={4} />
          <StyledText text={String(detailData.book_count)} fontSize="xs" fontWeight="regular" fontColor="greya1" />
        </FooterInfoInner>
      </FooterInfoWrapper>
    </FooterWrapper>
  );
};

export default DetailsSection;

const DescriptionSection = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
`;

const Wrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

const DescriptionContainer = styled.div`
  color: black;
  white-space: pre-wrap;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SocialAndApplyContainer = styled.div`
  width: 100%;
  height: 94px;
  border-radius: 6px;
  padding: 16px 20px;
  background-color: ${color.greyf4};
`;

const ItemWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const TitleTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const TaxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${color.greyd4};
  border-radius: 6px;
  padding: 16px 20px;
  margin-top: 10px;
`;

const TaxTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FooterInfoWrapper = styled.div`
  display: flex;
`;

const FooterInfoInner = styled.div`
  display: flex;
  align-items: center;
`;
