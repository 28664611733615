import { t } from 'i18next';
import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';

const EmptyRow = () => {
  return (
    <Container>
      <StyledText text={t('users.table-empty')} textAlign="center" fontColor="grey71" />
    </Container>
  );
};

export default EmptyRow;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
`;
