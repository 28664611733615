import { useState } from 'react';

import { t } from 'i18next';

type TargetType = 'ACCEPTED' | 'SUBMITTED' | 'NOT_SUBMITTED';
type SendTimeType = 'send-immediately' | 'send-schedule';

export interface BulkMessageModalDataType {
  target: TargetType;
  sendTime: SendTimeType;
  content: string;
  schedule: Date | null;
}

interface Props {
  initialData?: BulkMessageModalDataType;
}

export const useBulkMessageModal = ({ initialData }: Props) => {
  const [target, setTarget] = useState<TargetType>(initialData?.target ?? 'ACCEPTED');
  const [content, setContent] = useState<string>(initialData?.content ?? '');
  const [sendTime, setSendTime] = useState<SendTimeType>(initialData?.sendTime ?? 'send-immediately');
  const [schedule, setSchedule] = useState<Date>(initialData?.schedule ?? new Date());

  const submitDisabled = !content;

  const handleInput = (type: 'target' | 'content' | 'sendTime' | 'schedule', value: string) => {
    switch (type) {
      case 'target':
        setTarget(value as TargetType);
        break;
      case 'content':
        if (value.length > 500) setContent(value.slice(0, 500));
        else setContent(value);

        break;
      case 'sendTime':
        setSendTime(value as SendTimeType);
        break;
      case 'schedule':
        setSchedule(new Date(value));
        break;
    }
  };

  const getData = () => {
    const data = {
      target,
      content,
      sendTime,
      schedule: sendTime === 'send-schedule' ? schedule : null,
    };

    return data;
  };

  return {
    state: {
      target,
      content,
      sendTime,
      schedule,
    },
    handlers: {
      handleInput,
      getData,
    },
    disabled: {
      submitDisabled,
    },
  };
};

export const bulkMessageTargetOptions = () => [
  {
    value: 'ACCEPTED',
    label: t('campaign.bulkMessage-modal-target-option-all'),
  },
  {
    value: 'SUBMITTED',
    label: t('campaign.bulkMessage-modal-target-option-notSubmitted'),
  },
  {
    value: 'NOT_SUBMITTED',
    label: t('campaign.bulkMessage-modal-target-option-submitted'),
  },
];

export const bulkMessageSendTimeOptions = () => [
  {
    value: 'send-immediately',
    label: t('campaign.bulkMessage-modal-time-option-immediately'),
  },
  {
    value: 'send-schedule',
    label: t('campaign.bulkMessage-modal-time-option-schedule'),
  },
];
