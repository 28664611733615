import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { getTermsDetailBySlug } from 'api/terms';

import { selectAccount } from 'features/redux/selectors/accounts';

export const useGetTermsDetailBySlug = ({ slug }: { slug: string }) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const getDataFn = () => {
    return getTermsDetailBySlug(access_token, slug);
  };

  return useQuery(['textData', { slug }], getDataFn, {
    staleTime: 30 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    keepPreviousData: true,
  });
};
