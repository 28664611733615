import { CSSProperties, useState } from 'react';

import styled from 'styled-components';

interface Props {
  src: string;
  alt: string;
  loadingComponent: JSX.Element;
  className?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  aspectRatio?: number;
  style?: CSSProperties;
}

const ImageLoader = ({
  src,
  alt,
  loadingComponent,
  width = '40px',
  height = '40px',
  borderRadius = '4px',
  aspectRatio = 1,
  className = '',
  style,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };
  const commonContainerStyle = {
    width,
    height,
    borderRadius,
    aspectRatio,
    ...style,
  };

  const imageStyle = {
    ...commonContainerStyle,
    display: isLoading ? 'none' : 'block',
    objectFit: 'cover',
  };

  return (
    <ImageLoaderContainer>
      {isLoading && <LoadingContainer style={commonContainerStyle}>{loadingComponent}</LoadingContainer>}
      <StyledImage
        className={className}
        src={src}
        alt={alt}
        onLoad={handleLoad}
        onError={handleLoad}
        style={imageStyle as CSSProperties}
      />
    </ImageLoaderContainer>
  );
};

export default ImageLoader;

const ImageLoaderContainer = styled.div``;

const LoadingContainer = styled.div``;

const StyledImage = styled.img``;
