import { useOverlay } from '@nurihaus/use-overlay';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import NormalButton from 'components/common/button/NormalButton';

import BulkMessageCancelModal from './BulkMessageCancelModal';

interface Props {
  bulkMessage: any;
  edit?: boolean;
}

const BulkMessageDetailHeaderDetailButtons = ({ bulkMessage }: Props) => {
  const overlay = useOverlay();
  const { id } = bulkMessage;
  const navigate = useNavigate();

  const moveToEditPage = () => {
    navigate(`/chat/bulk-message/${id}/edit`);
  };

  const OpenCancelModal = () => {
    overlay.open(({ isOpen, close }) => <BulkMessageCancelModal isOpen={isOpen} close={close} id={id} />);
  };

  return (
    <ButtonGroup>
      <NormalButton
        width="190px"
        styleType="ghost"
        sizeType="medium"
        text={t('chat.bulkMessage-detail-btn-cancel')}
        onPress={OpenCancelModal}
      />
      <NormalButton
        width="190px"
        sizeType="medium"
        text={t('chat.bulkMessage-detail-btn-edit')}
        onPress={moveToEditPage}
      />
    </ButtonGroup>
  );
};

export default BulkMessageDetailHeaderDetailButtons;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
