import { Campaign } from 'types/campaign/internal';

export const getImageList = (detailData: Campaign) => {
  return [
    detailData.image,
    detailData.image2,
    detailData.image3,
    detailData.image4,
    detailData.image5,
    detailData.image6,
    detailData.image7,
    detailData.image8,
    detailData.image9,
    detailData.image10,
  ].filter(Boolean);
};
