import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';

import { color } from 'styles/assets';

interface Props {
  text: string;
}

const Dropped = ({ text }: Props) => {
  return (
    <Container>
      <StyledText fontSize="base" fontWeight="bold" text={text} textAlign="center" />
    </Container>
  );
};

export default Dropped;

const Container = styled.div`
  width: 100%;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: ${color.greyf4};
  margin-bottom: 20px;
`;
