import { useLayoutEffect, useRef, useState } from 'react';

import { useOverlay } from '@nurihaus/use-overlay';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import NormalButton from 'components/common/button/NormalButton';
import ReportModal from 'components/common/errorBoundary/ReportModal';
import { ApiError, NetworkError, RuntimeError, TimeoutError } from 'components/common/errorBoundary/errors';
import StyledText from 'components/common/text/StyledText';

import { color } from 'styles/assets';

interface Props {
  error: {
    message: string;
    info: ApiError | RuntimeError | TimeoutError | NetworkError | null;
  };
  onError?: () => void;
  style?: React.CSSProperties;
}

const Fallback = ({ onError, error, style }: Props) => {
  const [displayDirection, setDisplayDirection] = useState<'vertical' | 'horizontal'>('horizontal');
  const fallbackRef = useRef<HTMLDivElement>(null);
  const [parentHeight, setParentHeight] = useState(0);
  const reportOverlay = useOverlay();
  const queryClient = useQueryClient();

  const handleReport = () => {
    reportOverlay.open(({ close }) => (
      <ReportModal
        error={
          error as {
            message: string;
            info: Exclude<typeof error.info, NetworkError>;
          }
        }
        close={close}
      />
    ));
  };

  useLayoutEffect(() => {
    const updateDisplayDirection = () => {
      const currentParentWidth = fallbackRef.current?.parentElement?.offsetWidth ?? 0;
      const currentParentHeight = fallbackRef.current?.parentElement?.offsetHeight ?? 0;

      setParentHeight(currentParentHeight);

      setDisplayDirection(
        currentParentWidth > 768 ? (currentParentHeight > 178 ? 'vertical' : 'horizontal') : 'vertical'
      );
    };

    updateDisplayDirection();
    window.addEventListener('resize', updateDisplayDirection);

    return () => {
      window.removeEventListener('resize', updateDisplayDirection);
    };
  }, []);

  return (
    <Container ref={fallbackRef} displayDirection={displayDirection} height={parentHeight} style={style}>
      <IconWrapper>
        <Icon type="block" />
      </IconWrapper>
      <StyledText text={error.message ?? '데이터를 불러올 수 없습니다.'} fontWeight="bold" fontSize="lg" />
      <ButtonWrapper>
        {error.info instanceof NetworkError ? (
          <NormalButton
            text="에러보고"
            sizeType="medium"
            width="150px"
            styleType="ghost"
            onPress={() => window.location.reload()}
          />
        ) : (
          <NormalButton text="에러보고" sizeType="medium" width="150px" styleType="ghost" onPress={handleReport} />
        )}
        <NormalButton
          text="다시시도"
          sizeType="medium"
          width="150px"
          onPress={() => {
            if (onError) {
              onError();
            } else {
              queryClient.clear();
              window.location.reload();
            }
          }}
        />
      </ButtonWrapper>
    </Container>
  );
};

export default Fallback;

const Container = styled.div<{ displayDirection: 'vertical' | 'horizontal'; height: number }>`
  display: flex;
  flex-direction: ${({ displayDirection }) => (displayDirection === 'vertical' ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  gap: 14px;
  width: 100%;
  height: ${({ height }) => height}px;
  background-color: ${color.white};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.violete9};
  border-radius: 100%;
  width: 46px;
  height: 46px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
