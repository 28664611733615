import { ReactNode } from 'react';

import styled from 'styled-components';

interface Props {
  children: ReactNode;
  zIndex?: number;
}

const SelectorBody = ({ zIndex = 2, children }: Props) => {
  return (
    <SelectorBodyContainer zIndex={zIndex} data-testid="select-body">
      {children}
    </SelectorBodyContainer>
  );
};

export default SelectorBody;

const SelectorBodyContainer = styled.div<{ zIndex: number }>`
  z-index: ${(props) => props.zIndex};
  position: relative;
`;
