interface Props {
  height: string;
  backgroundColor: string;
  marginTop: string;
  marginBottom: string;
}

const SectionLine = (props: Props) => {
  const { height, marginTop, marginBottom, backgroundColor } = props;

  return (
    <div
      data-testid="section-line"
      style={{
        minHeight: height,
        width: '100%',
        marginTop,
        marginBottom,
        backgroundColor,
      }}
    />
  );
};

export default SectionLine;
