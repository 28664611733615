import { useMemo } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { getCountryList } from 'api/account/Address';
import { sendSlackMessage } from 'api/common';

import { selectAccessToken } from 'features/redux/selectors/accounts';

import { Country } from 'types/account/internal';
import { SlackMessageInfo } from 'types/common';

const queryKeys = {
  countries: () => ['countries'],
  countryById: (id: number | null) => ['countries', id],
};

export const useCountry = ({ id }: { id?: number | null }) => {
  const dispatch = useDispatch();
  const access_token = useSelector(selectAccessToken);

  const { data, isLoading, refetch } = useQuery<Country[]>(
    queryKeys.countries(),
    () => getCountryList(dispatch, access_token),
    {
      enabled: !!access_token,
      staleTime: 1000 * 60 * 60 * 12,
      refetchInterval: 1000 * 60 * 60 * 12,
      refetchOnWindowFocus: false,
    }
  );

  // useMemo로 국가 필터링 로직을 처리
  const filteredCountry = useMemo(() => {
    if (data && id) {
      return data.find((c) => c.id === id) || null;
    }

    return null;
  }, [data, id]);

  return { refetch, data, filteredCountry, isLoading };
};

export const useSlackMessage = () => {
  return useMutation((info: SlackMessageInfo) => sendSlackMessage(info));
};
