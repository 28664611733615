import { getTypedKeys, invariantOf } from 'utils/common/getTypedKeys';

export const getKeyByValue = <T extends Record<string, unknown>>(
  obj: T,
  value: T[keyof T],
  defaultValue?: keyof T
): keyof T => {
  const result = getTypedKeys(invariantOf(obj)).find((key) => obj[key] === value);
  if (!result) {
    return defaultValue || (Object.keys(obj)[0] as keyof T); // 첫 번째 키의 값을 반환
  }

  return result;
};
